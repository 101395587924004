<template>
	<div class="scrollbar-content" :class="{'default-show-bar': show_bar}">
		<slot></slot>
	</div>
</template>

<script>
export default {
    name: 'scrollbar-content',

    props: {
        show_bar: {
            default: false,
            type: Boolean,
        },
    },

    methods: {
    },

    mounted() {
    },
};
</script>

<style lang="scss">
    .scrollbar-content {
        // overflow: hidden;
        overflow: auto;
        height: 100%;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            border: 2px solid transparent;
            background-clip: content-box;
            background-color: transparent;
            transition: .2s background-color ease;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: var(--black-50);
        }

        &.default-show-bar {
            &::-webkit-scrollbar-thumb {
                background-color: var(--black-20);
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: var(--black-50);
            }
        }
    }
</style>