

<template>
    <div v-if="is_open" class="ui-side-modal" :class="[{ 'open': is_show }, 'layer-' + layer]">
        <Scrollbar class="ui-modal-side--container" id="modal-body">
            <slot />
        </Scrollbar>
        <div class="bg" @click="close"></div>
    </div>
</template>
<script>
import Scrollbar from '@/components/ui/Scrollbar.vue';

export default {
    name: 'ui-side-modal',

    data() {
        return {
            is_open: false,
            is_show: false,
            layer: 1,
            is_opening: false,
        }
    },

    components: {
        Scrollbar,
    },

    methods: {
        open() {
            if (!this.is_opening) {
                this.is_opening = true;
                setTimeout(() => {
                    this.is_opening = false;
                }, 1000);
                
                this.is_open = true;
                this.layer = document.querySelectorAll('.ui-side-modal').length + 1;
                setTimeout(() => {
                    this.is_show = true;
                    document.getElementById('modal-body').addEventListener('scroll', this.handleScroll);
                }, 1);
                this.$emit('open');
            }

        },

        close() {
            if (!this.is_opening) {
                this.is_opening = true;
                setTimeout(() => {
                    this.is_opening = false;
                }, 1000);
                this.is_show = false;
                setTimeout(() => {
                    this.is_open = false;
                    const b = document.getElementById('modal-body');
                    if (b) b.removeEventListener('scroll', this.handleScroll);
                }, 751);
                this.$emit('close');
            }
        },

        handleScroll() {
            let modal_body = document.getElementById('modal-body') ? document.getElementById('modal-body').scrollTop : 0;
            let body = modal_body + 700;
            let scroll = '';
            let type = '';

            if (document.getElementById('scroll-content-left')) {
                type = 'left';
                scroll = document.getElementById('scroll-content-left').offsetHeight;
            }
            else if (document.getElementById('scroll-content-right')) {
                type = 'right';
                scroll = document.getElementById('scroll-content-right').offsetHeight;
            }

            // console.log(body)
            // console.log(scroll)

            if (body > scroll) {
                if (type === 'left')
                    this.$emit('creator-next-page-left');
                else if (type === 'right')
                    this.$emit('creator-next-page-right');
                // console.log('more')
            }
        },

        // handleScroll() {
        //     let top = document.getElementById('scroll').scrollTop;
        //     let height = document.getElementById('scroll').offsetHeight;

        //     console.log(top)
        //     console.log(height)

        //     if (top > height * 1.3) {
        //         console.log('more-left')
        //         this.$emit('modal-next-page-left');
        //     }
        //     else if (top > height - 500) {
        //         console.log('more-right')
        //         this.$emit('modal-next-page-right');
        //     }
        // },
    },

    // mounted() {

    // },

    // destroyed () {

    // },
}
</script>
<style lang="scss">
.ui-side-modal {
    transition: transform .75s ease-in-out;
    transform: translate(100%, 0%);
    z-index: 999;

    &.layer-2 {
        z-index: 1001;
    }

    &.layer-1>.ui-modal-side--container {
        left: 15%;
        width: calc(100% - 15%);
        min-width: 600px;
    }

    &.layer-2>.ui-modal-side--container {
        left: 20%;
        width: calc(100% - 20%);
        min-width: 560px;
    }

    .ui-modal-side--container {
        height: 100%;
        background: white;
        box-shadow: -6px 0px 8px rgba(0, 0, 0, 0.1);
        padding: 36px 26px;
        z-index: 2;
        position: absolute;

        header {
            display: flex;

            .title {
                //font: var(--font-demi-20);
                font-family: var(--font-med);
                font-weight: normal;
                font-size: 24px;
                color: var(--black-90);
                flex: 1;

                span {
                    display: flex;
                    align-items: center;
                }

                svg {
                    margin-left: 11px;
                    width: 18px;
                    height: 18px;
                }
            }

            .icon-box {
                display: flex;

                .icon {
                    display: flex;
                    margin-left: 20px;
                    align-items: center;
                    cursor: pointer;
                    color: var(--black-75);

                    &.i-1>svg {
                        width: 18px;
                        height: 18px;
                        margin-left: 9px;
                        fill: var(--black-75);
                    }

                    &.download-btn {
                        display: flex;
                        align-items: center;
                        cursor: not-allowed;

                        &>svg {
                            width: 18px;
                            height: 18px;
                            fill: var(--black-75);
                            margin-right: 6px;
                        }
                    }

                    &.i-2>svg {
                        width: 18px;
                        height: 18px;
                        fill: var(--black-75);
                    }

                    &.i-3>svg {
                        width: 14px;
                        height: 14px;
                        fill: var(--black-75);
                    }

                    &:hover {
                        color: var(--dark-blue);

                        svg {
                            fill: var(--dark-blue);
                        }
                    }
                }
            }
        }
    }

    .bg {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        // background: black;
    }

    &.open {
        transform: translate(0%, 0%);
    }

    // &.open {
    //     &.layer-1 {
    //         animation: slideIn 1s forwards;
    //         -webkit-animation: slideIn 1s forwards;
    //     }

    //     &.layer-2 {
    //         animation: slideIn2 1s forwards;
    //         -webkit-animation: slideIn2 1s forwards;
    //     }
    // }

    // &.close {
    //     &.layer-1 {
    //         animation: slideOut 1s forwards;
    //         -webkit-animation: slideOut 1s forwards;
    //     }

    //     &.layer-2 {
    //         animation: slideOut2 1s forwards;
    //         -webkit-animation: slideOut2 1s forwards;
    //     }
    // }
}

//layer 1
// @-webkit-keyframes slideIn {
//     0% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
//     100% {
//         -webkit-transform: translateX(420px);
//         transform: translateX(420px);
//     }
// }
// @keyframes slideIn {
//     0% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
//     100% {
//         -webkit-transform: translateX(420px);
//         transform: translateX(420px);
//     }
// }
// @-webkit-keyframes slideOut {
//     0% {
//         -webkit-transform: translateX(420px);
//         transform: translateX(420px);
//     }
//     100% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
// }
// @keyframes slideOut {
//     0% {
//         -webkit-transform: translateX(420px);
//         transform: translateX(420px);
//     }
//     100% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
// }

// //layer 2
// @-webkit-keyframes slideIn2 {
//     0% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
//     100% {
//         -webkit-transform: translateX(100px);
//         transform: translateX(100px);
//     }
// }
// @keyframes slideIn2 {
//     0% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
//     100% {
//         -webkit-transform: translateX(100px);
//         transform: translateX(100px);
//     }
// }
// @-webkit-keyframes slideOut2 {
//     0% {
//         -webkit-transform: translateX(100px);
//         transform: translateX(100px);
//     }
//     100% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
// }
// @keyframes slideOut2 {
//     0% {
//         -webkit-transform: translateX(100px);
//         transform: translateX(100px);
//     }
//     100% {
//         -webkit-transform: translateX(100vw);
//         transform: translateX(100vw);
//     }
// }
</style>