<template>
    <Loading class="static" v-if="is_loading" />
    <div class="ui-import" v-else>
        <div class="desc">Please paste a valid link from this creator's {{ which_handle }} if it was not automatically
            imported from your tracked #hashtags and @mentions</div>

        <input :class="['input', (not_valid && url) && 'err-input']" type="text" name="url" v-model="url"
            :placeholder="type === 1 ? 'http://www.tiktok.com/v/...' : 'http://www.instagram.com/...'" @keyup.enter="checkUrl" @blur="checkUrl" @input="checkUrl"/>
        <p v-if="valid_text && not_valid" class="error-text" v-html="valid_text"></p>

        <div class="import-btns">
            <Button type="button" color="dark-blue" size="40" :class="{ 'disabled': not_valid }"
                @click="callImportApi">Import</Button>
        </div>
    </div>

    <Modal :is_show="is_show_remove_modal" class="alert-modal" @click-bg="is_show_remove_modal = false">
        <div class="box">
            <header class="delete-header">Removing this tag?</header>
            <p>Removing the this keyword will stop listing from creator's profile. Are you sure?</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click="remove(false)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click="remove('remove')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>

</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import Button from './ui/Button.vue';
import Modal from './ui/Modal.vue';

export default {
    name: 'Hashtag',

    components: {
        Loading,
        Button,
        Modal,
    },

    data() {
        return {
            is_loading: false,
            url: '',
            valid_text: '',
            not_valid: true,
            is_updateing: false,
            is_show_remove_modal: false,

        }
    },

    props: {
        which_handle: {
            type: String,
            default: 'TikTok',
        },

        anchor_creator_id: {
            type: String,
            default: '',
        },
        type:{
            type: Number,
            default: 1,
        }

    },

    watch:{
        type(val){
            if(val){
                this.checkUrl();
            }
        }
    },

    methods: {
        checkUrl(v) {
            if (!this.url)
                return

            this.getAllUrl();
            // switch (this.which_handle) {
            //     case 'TikTok':
            //         this.getTiktokeUrl();
            //         break;
            //     case 'Instagram':
            //         this.getInstagramUrl();
            //         break;
            // }

        },

        getAllUrl(){
            const reg = /(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/;
            const match = this.url.match(reg);
            const isok = (this.url.indexOf('.instagram.com/p/') > -1 || this.url.indexOf('.instagram.com/reel/') > -1 || this.url.indexOf('.instagram.com/stories/') > -1 || this.url.indexOf('.instagram.com/tv/') > -1 || match !== null) || this.url.includes('tiktok.com/');

            if (this.url.indexOf('.instagram.com/reel/') > -1) this.url = this.url.replace('.instagram.com/reel/', '.instagram.com/p/')
            else if (this.url.indexOf('.instagram.com/tv/') > -1) this.url = this.url.replace('.instagram.com/tv/', '.instagram.com/p/')
            else if (match !== null && match.length === 2) this.url = 'https://www.instagram.com/p/' + match[1] + '/';

            if (!this.validURL(this.url) || !isok) {
                this.valid_text = 'Please add a valid link';
                this.not_valid = true;
            } else {
                this.not_valid = false;
                this.valid_text = '';
            }
        },

        getInstagramUrl() {
            const reg = /(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/;
            const match = this.url.match(reg);
            const isok = (this.url.indexOf('.instagram.com/p/') > -1 || this.url.indexOf('.instagram.com/reel/') > -1 || this.url.indexOf('.instagram.com/stories/') > -1  || this.url.indexOf('.instagram.com/tv/') > -1 || match !== null);
            
            if (this.url.indexOf('.instagram.com/reel/') > -1) this.url = this.url.replace('.instagram.com/reel/', '.instagram.com/p/')
            else if (this.url.indexOf('.instagram.com/tv/') > -1) this.url = this.url.replace('.instagram.com/tv/', '.instagram.com/p/')
            else if (match !== null && match.length === 2) this.url = 'https://www.instagram.com/p/' + match[1] + '/';

            if (!this.validURL(this.url) || !isok) {
                this.valid_text = 'This type of post is not currently supported.';//'This link is not in the proper format.';
                this.not_valid = true;
            } else {
                this.not_valid = false;
                this.valid_text = '';
            }
        },

        getTiktokeUrl() {
            if (!this.validURL(this.url) || this.url === '' || !this.url.includes('tiktok.com/')) {
                this.not_valid = true;
                this.valid_text = 'This type of post is not currently supported.';
            } else {
                this.not_valid = false;
                this.valid_text = '';
            }

        },

        callImportApi() {
            let params_data = {
                anchor_creator_id: this.anchor_creator_id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                media_url: this.url
            }

            this.$ajax('/creator/create-social-post-from-url', {
                method: 'post',
                data: params_data,
            }).then(res => {
                this.$notify(`<svg><use xlink:href="#yes--sprite"></use></svg> Post imported `);
                this.$store.state.brand_posts_key += 1;
                this.$emit('refrash')
            }).catch(error => {
                console.log(error)
                this.not_valid = true;
                this.valid_text = error && error.response && error.response.data && error.response.data.data && error.response.data.data.message ? error.response.data.data.message : '';
            });

        },

        validURL(url) {

            try {
                new URL(url);
            } catch (e) {
                return false;
            }

            return true;
        },
    },

    mounted() {

    },
}
</script>
<style lang="scss">
.ui-import {
    position: relative;
    padding: 24px 16px;

    .desc {
        color: var(--black-75);
        font-size: 12px;
        margin-bottom: 8px;
    }


    input {
        width: 100%;
        padding: 9px 12px;
        // border-radius: 4px;
        font-size: 14px;
        color: var(--black-90);
        outline: none;
        height: 40px;
        border: 1px solid var(--black-20);

        &.err-input {
            border: 1px solid var(--red);
        }

        &:focus {
            border: 1px solid var(--dark-blue);
        }
    }


    .error-text {
        color: var(--red) !important;
        font-size: 12px;
    }

    .import-btns {
        margin: 24px 0 0;

        .ui-button {
            width: 100%;
            border-radius: 8px;
        }
    }

}
</style>