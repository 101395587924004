<template>
    <div class="ui-search-input">
        <div class="input" :class="{'disabled': disabled}">
            <label for="input">
                <input 
                    @input="$emit('update', search_query)" 
                    @keyup.enter="onenter($event)"
                    v-model="search_query" 
                    name="search-input" 
                    type="text" 
                    :placeholder="placeholder_text"
                    
                    :disabled="disabled"
                >
                <i v-if="search_query !== ''" @click="clear" class="clear"><SvgIcon name="close-circle-2" /></i>
                <i @click="onenter" class="search"><SvgIcon name="search" /></i>
            </label>
        </div>
    </div>
    <Modal 
    :is_show="is_show_alert_modal" 
    class="alert-modal" 
    @click-bg="is_show_alert_modal = false">
        <div class="box">
            <header class="delete-header">Please enter a keyword</header>
            <p>Keyword cannot be empty.</p>

            <div class="btns">
                <Button type="button" color="dark-blue" size="32" @click.native="is_show_alert_modal = false">OK</Button>
            </div>
        </div>
    </Modal>
</template>

<script>
import '@/assets/svg/search.svg?sprite';
import Modal from '@/components/ui/Modal.vue';
import Button from '@/components/ui/Button.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'ui-search-input',

    data() {
        return {
            is_show_alert_modal: false,
            search_query: '',
        }
    },

    components: {
        Modal,
        Button,
        SvgIcon,
    },

    props: {
        placeholder_text: {
            default: 'Search by creator name or social handle',
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        clear() {
            this.search_query = '';
            this.$emit('onenter', this.search_query);
        },

        onenter($event) {
            if (this.disabled) return;
            else this.$emit('onenter', this.search_query);
            // else if (this.search_query !== '') {
            //     this.$emit('onenter', this.search_query);
            // } else {
            //     this.is_show_alert_modal = true;
            // }
            
            // setTimeout(() => {
            //     $event.target.blur();
            // }, 10);
        },
    },
}

</script>

<style lang="scss">
    .ui-search-input {
        .input {
            width: 100%;
            max-width: 480px;
            position: relative;

            &.disabled {
                cursor: not-allowed;

                input {
                    cursor: not-allowed;

                    &::placeholder {
                        color: var(--black-40);
                    }
                }

                i > svg {
                    fill: var(--black-40);
                }

                label > i {
                    cursor: not-allowed;
                }
            }
        }


        label {
            display: block;
            position: relative;
        }

        label > i {
            height: 16px;
            width: 16px;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -8px;
            cursor: pointer;

            &.clear {
                right: 30px;
                margin-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 80%;
                    width: 80%;
                    display: block;
                    fill: var(--black-75);
                }
            }

            &.search {
                right: 10px;

                svg {
                    height: 100%;
                    width: 100%;
                    display: block;
                    fill: var(--black-75);
                }
            }
        }

        input {
            width: 100%;
            height: 32px;
            border: 1px solid var(--black-10);
            background-color: var(--black-10);
            border-radius: 6px;
            padding: 0 54px 0 12px;
            color: var(--black-90);

            &::placeholder {
                color: var(--black-60);
            }

            &:focus {
                border-color: var(--black-50);
                background-color: white;
                outline: none;

                & + i > svg {
                    fill: var(--black-90);
                }
            }
        }
    }

</style>