<template>
    <Loading class="static" v-if="is_loading" />
    <div class="ui-hashtag" v-else>
        <Loading class="absolute" v-if="is_updateing" />
        <div class="tag-list" :class="{ 'hide': only_tag && index === 0 }" v-for="(tag, index) in tags" :key="index">
            <div class="left-side">
                <h2 v-html="tag.name"></h2>
                <p class="desc" v-html="tag.desc"></p>
            </div>

            <div class="hashtags">
                <div class="list" :class="'col-' + tag.hashtags.length" v-for="(list, listIndex) in tag.hashtags"
                    :key="listIndex">
                    <div class="label">
                        <h3>
                            <span>{{ list.label }}</span>
                            <span v-if="list.max">{{ list.value.length }}/{{ list.max }}</span>
                        </h3>
                        <!-- && !hide_edit -->
                        <span class="btn" @click="list.is_edit = !list.is_edit"
                            v-if="list.is_edit"><svg-icon name="yes-2" /> Save</span>
                    </div>

                    <div class="socials-box" v-for="(social , social_index) in list.socials" :key="social_index">
                        <div v-if="!list.is_edit">
                            <div class="input">
                                <label v-if="social.svg || social.tit ">
                                    <svg-icon v-if="social.svg" :name="social.svg"/>
                                    <div v-else>{{ social.tit }}</div>
                                </label>
                                <label class="label-2" v-if="social.svg && social.tit">{{ social.tit }}</label>

                                <input v-model="social.new_tag_text" :name="'input-tag-' + index" :id="'input-tag-' + index"
                                    type="text" :placeholder="social.placeholder" @keyup.enter="add(list.id, social_index)"
                                    @input="enter(social)" :disabled="hide_edit"/>

                                <Button class="add-btn" size="32" color="dark-blue" :border_r="'8'" @click="add(list.id, social_index)"
                                    :is_disabled="hide_edit" ><svg-icon name="lock" v-if="hide_edit"/>Add</Button>
                            </div>
                            <p v-if="social.error" class="error-text" v-html="social.error_text"></p>
                        </div>

                        <ul>
                            <div class="ul-box">
                                <li v-for="(item, tagIndex) in social.value" :key="tagIndex">
                                    <Button size="24" color="black-10"
                                        :border_r="'4'">
                                        <svg-icon class="social-icon-label" v-if="social.svg" :name="social.svg"/>
                                        {{ list.label === '#Hashtag' ? '#': list.label === '@Mentions'? '@': ''}} {{item.text}} 
                                        <i v-if="list.is_edit" @click="remove(index, listIndex, social_index, tagIndex)"><svg-icon name="remove" /></i>
                                    </Button>
                                </li>
                                <!-- && !hide_edit  -->
                                <i @click="list.is_edit = !list.is_edit; social.error_text = ''; social.error = false;"
                                    v-if="!list.is_edit && social.value.length > 0"><svg-icon name="pen" /></i>
                            </div>

                            <!-- <li v-if="!is_loading && !list.value.length">
                                <p>{{ list.no_data }}</p>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :is_show="is_show_remove_modal" class="alert-modal" @click-bg="is_show_remove_modal = false">
        <div class="box">
            <header class="delete-header">{{ remove_text.title }}</header>
            <p v-if="remove_text.need_svg">This will stop tracking content on <svg-icon class="remove-svg-icon" :name="remove_text.need_svg"/> {{ remove_text.content }} creator profiles.</p>
            <p v-else >{{ remove_text.content }}</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click="remove(false)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click="remove('remove')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>

</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import Button from './ui/Button.vue';
import Modal from './ui/Modal.vue';
import SvgIcon from './ui/SvgIcon.vue';

export default {
    name: 'Hashtag',

    components: {
        Loading,
        Button,
        Modal,
        SvgIcon,
    },

    data() {
        return {
            is_loading: true,
            is_updateing: false,
            is_show_remove_modal: false,
            remove_item: {
                tag: 0,
                list: 0,
                social:0,
                index: 0,
            },
            tags: [
                {
                    name: 'Tracked #Hashtags and @Mentions',
                    desc: `Any content from storefront creators which include these @mentions and #hashtags will automatically appear in your Content Dashboard and on the creator's Content page.  The creator must connect their socials for the content to appear.`,
                    st: `Tracked #Hashtags and @Mentions`,
                    placeholder: 'Enter #hashtag or @mention to track',
                    label: 'Add @mention or #hashtag',
                    key: 'hashtag',
                    new_tag: {
                        id: 0,
                        text: '',
                    },

                    hashtags: [
                        {
                            id: 1,
                            label: '#Hashtag',
                            tit: '#',
                            is_edit: false,
                            socials:[
                                {   
                                    svg: null,
                                    tit:'#',
                                    max: false,
                                    value: [],
                                    placeholder: 'Enter new #hashtag to track',
                                    no_data: 'Added Hashtag keywords will show up here.',
                                    new_tag_text: '',
                                    error: false,
                                    error_text: '',
                                },

                            ],
                        },
                        {
                            id: 2,
                            label: '@Mentions',
                            tit: '@',
                            is_edit: false,
                            socials:[
                                {   
                                    svg: 'tiktok',
                                    tit:'@',
                                    max: false,
                                    value: [],
                                    placeholder: 'Enter new @mention to track',
                                    no_data: 'Added @mentions keywords will show up here.',
                                    new_tag_text: '',
                                    error: false,
                                    error_text: '',
                                },
                                {
                                    svg: 'instagram',
                                    tit:'@',
                                    max: false,
                                    value: [],
                                    placeholder: 'Enter new @mention to track',
                                    no_data: 'Added @mentions keywords will show up here.',
                                    new_tag_text: '',
                                    error: false,
                                    error_text: '',
                                },
                            ],
                        },
                    ],

                },
                {
                    name: 'Content Tags',
                    desc: `Content tags can be used to organize and filter creator content.  Please do not include any special characters besides '-' and '_'. (Max: 10 content tags.)`,
                    placeholder: 'Enter a new content tag (examples: Paid, Organic, Holiday, Gifting)',
                    label: 'Add new tag',
                    key: 'tags',
                    new_tag: {
                        id: 0,
                        text: '',
                    },
                    error: false,
                    error_text: '',
                    hashtags: [
                        {
                            id: 3,
                            label: 'Content Tags',
                            is_edit: false,
                            socials:[
                                {   
                                    svg: null,
                                    tit:null,
                                    max: 10,
                                    value: [],
                                    placeholder: 'Enter a new content tag (examples: Paid, Organic, Holiday, Gifting)',
                                    no_data: 'Added Tags keywords will show up here.',
                                    new_tag_text: '',
                                    error: false,
                                    error_text: '',
                                },

                            ],
                        },
                    ],
                },
            ],
        }
    },

    props: {
        only_tag: {
            type: Boolean,
            default: false,
        },

    },

    computed: {
        hide_edit() {
            return !this.$store.state.user_data.contract_status;
        },

        remove_text() {
            let keyword = '';
            let content = '';
            let tit = '';
            let need_svg= false;
            let text = this.tags[this.remove_item.tag].hashtags[this.remove_item.list].socials[this.remove_item.social].value[this.remove_item.index].text;
            if (this.remove_item.tag === 0) {
                if (this.remove_item.list === 0) {
                    keyword = 'keyword';
                    tit = '#';
                    content = `This will stop tracking content on ${tit}${text} creator profiles.`
                } else {
                    keyword = 'mentions';
                    tit = '@';
                    need_svg=this.tags[this.remove_item.tag].hashtags[this.remove_item.list].socials[this.remove_item.social].svg;
                    content = `${tit}${text}`
                }

            } else {
                keyword = 'tag';
                content = `This will remove tags from ${tit}${text} content items.`
            }

            return {
                title: `Remove ${keyword}?`,
                content: content,
                need_svg: need_svg,
            }
        }
    },

    methods: {
        validateAddTag(tag, id) {
            let regex = /^[a-zA-Z0-9_-]+$/;
            let noNonEnglish = /^[\x00-\x7F]+$/;

            //id1=> #Hashtag 2=>@Mentions 3=>Content Tags
            //id2 can use any word now
            console.log('tag', tag)
            console.log('id', id)
            if (id === 1 ) {
                regex = /^[a-zA-Z0-9_\-\.]+$/;///^[@#][a-zA-Z0-9_-]+$/;
            }else if(id === 2 ){
                regex=null;
            }

            if (!tag.new_tag_text) {
                tag.error = true;
                tag.error_text = 'This field cannot be empty';
                return;
            }

            if (id !== 2 && !noNonEnglish.test(tag.new_tag_text)  ) {
                tag.error = true;
                tag.error_text = 'Entries may only include English characters.';
                return;
            }

            if (id !== 2 && !regex.test(tag.new_tag_text) ) {
                tag.error = true;
                if (id === 1 || id === 2) {
                    tag.error_text = `Entries may not include any spaces or special characters (@mentions may include '.' and '_').`;
                } else {
                    tag.error_text = `Entries may not include any spaces or special characters except '-' and '_'. `
                }
                return;
            }

            let tempText = tag.new_tag_text.replace('#', '').replace('@', '');

            if (tag.value.find(item => item.text.toLocaleLowerCase() === tempText.toLocaleLowerCase())) {
                let ti = id === 1 ? 'hashtag' : id === 2 ? 'mention' : 'tag';
                tag.error = true;
                tag.error_text = `This ${ti} already exists.`;
                return;
            } else if (tag.max && tag.value.length >= tag.max) {
                tag.error = true;
                tag.error_text = 'Limit reached. Remove tags to add new ones.';
                return;
            } else {
                tag.new_tag_text = tag.new_tag_text.replace(/[@#]/g, '');
                tag.value.push({
                    id: 0,
                    text: tag.new_tag_text
                });
                this.update();
                tag.error = false;
                tag.new_tag_text = '';
            }
        },

        add(id,key) {
            if (id === 1) {
                this.validateAddTag(this.tags[0].hashtags[0].socials[key], id, key);
            } else if (id === 2) {
                this.validateAddTag(this.tags[0].hashtags[1].socials[key], id, key);
            } else {
                this.validateAddTag(this.tags[1].hashtags[0].socials[key], id, key);
            }

        },

        enter(tag) {
            tag.error = false;
            tag.error_text = '';
        },

        update() {
            //this.is_updateing = true;
            const hashtags = [];
            const igMention=[];
            const tkMention=[];
            const tags = [];
            this.tags.forEach((tag, index) => {
                tag.hashtags.forEach((list, list_index) => {
                    list.socials.forEach((social, social_index) => {
                        social.value.forEach((item, item_index) => {
                            if (index === 0 && list_index === 0) {
                                hashtags.push(item.text);
                            } else if (index === 0 && list_index === 1) {

                                if(social_index===0){
                                    tkMention.push(item.text);
                                }else{
                                    igMention.push(item.text)
                                }

                            } else if (index === 1 && list_index === 0) {
                                tags.push(item.text);
                            }
                        });
                    });
                });
            });

            hashtags.forEach((item, index) => {
                item = item.replace('#', '');
            });

            const data = {
                hashtags: hashtags,
                tags: tags,
                instagram_mentions:igMention,
                tiktok_mentions:tkMention,
            };
            console.log('data',data)

            this.$ajax('/creator/update-social-post-custom-filter', {
                method: 'post',
                withCredentials: true,
                data: data,
            }).then(res => {
                // this.$emit('update', data);
                const tags = res.data.data.social_post_tag_texts;
                // console.log(tags)
                this.EventBus.emit('updated-all-post-tag', tags);
                this.is_updateing = false;
            }).catch(err => {
                if (err && err.response && err.response.data.data) {
                    if (tags.length > 0) {
                        this.tags[1].error_text = err.response.data.data.message;
                        this.tags[1].error = true;
                    } else {
                        this.tags[0].error_text = err.response.data.data.message;
                        this.tags[0].error = true;
                    }

                }

            })
        },

        remove(tagIndex, listIndex, socialInedex, index) {
            if (typeof index === 'number') {
                this.is_show_remove_modal = true;
                this.remove_item.tag = tagIndex;
                this.remove_item.list = listIndex;
                this.remove_item.social = socialInedex;
                this.remove_item.index = index;

            } else if (tagIndex === 'remove') {
                this.is_show_remove_modal = false;
                this.tags[this.remove_item.tag].hashtags[this.remove_item.list].socials[this.remove_item.social].value.splice(this.remove_item.index, 1);
                console.log('this.tags[this.remove_item.tag].hashtags[this.remove_item.list].socials[this.remove_item.social].value',this.tags[this.remove_item.tag].hashtags[this.remove_item.list].socials[this.remove_item.social].value)
                
                let allValuesAreEmpty = this.tags[this.remove_item.tag].hashtags[this.remove_item.list].socials.every(social => social.value.length === 0);
                if(allValuesAreEmpty){
                    this.is_updateing = true;
                    this.tags[this.remove_item.tag].hashtags[this.remove_item.list].is_edit=false;
                }
                this.update();

            } else {
                this.is_show_remove_modal = false;
                this.remove_item.tag = 0;
                this.remove_item.list = 0;
                this.remove_item.social = 0;
                this.remove_item.index = 0;
            }
        },
    },

    mounted() {
        if (this.$store.state.user_data) {
            this.$ajax('/creator/get-social-post-filter', {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.is_loading = false;
                const data = res.data.data;
                if (!data) return;

                this.tags[0].hashtags[0].socials[0].value = data.social_post_hashtag_texts;
                this.tags[0].hashtags[1].socials[0].value = data.social_post_tiktok_mention_texts;
                this.tags[0].hashtags[1].socials[1].value = data.social_post_instagram_mention_texts;
                this.tags[1].hashtags[0].socials[0].value = data.social_post_tag_texts;
            });
        }
    },
}
</script>
<style lang="scss">
.ui-hashtag {
    position: relative;

    .tag-list {
        margin: 24px 0 32px;
        max-width: 780px;

        &.hide {
            display: none;
        }
    }

    .left-side {
        padding-right: 12px;

        h2 {
            font: var(--font-med-16);
            color: var(--black-90);
            margin: 0 0 8px;
        }

        .desc {
            color: var(--black-75);
        }
    }

    .input {
        position: relative;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        flex: 1;

        input {
            width: 100%;
            padding: 6px 8px;
            font-size: 14px;
            color: var(--black-90);
            outline: none;
            height: 36px;
            border: 1px solid var(--black-20);

            &:focus {
                border: 1px solid var(--dark-blue);
            }
        }

        label {
            height: 36px;
            width: 36px;
            background: #CBD4E1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: var(--black-90);

            &.label-2{
                background-color: unset;
                border-top: solid 1px #CBD4E1;
                border-bottom: solid 1px #CBD4E1;
            }

            .svg-icon{
                width: 12px;
                height: 12px;
            }
        }
    }

    .error-text {
        color: var(--red) !important;
        font-size: 12px;
    }

    .hashtags {
        width: 100%;

        .list {
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid var(--border);
            margin: 16px 0 0;
            width: 100%;

            &.col-1 {
                width: 100%;
            }

            &.col-2 {}
        }

        .socials-box{
            margin-bottom: 8px;

            &:last-child{
                margin-bottom: 0;
            }
        }

        .label {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            h3 {
                flex: 1;
                font: var(--font-med-12);
                color: var(--black-50);

                span {
                    padding-right: 8px;
                }
            }

            .btn {
                width: 60px;

                svg {
                    margin-right: 4px;
                    height: 12px;
                    width: 12px;
                }
            }
        }

        .btn,
        i {
            height: 24px;
            width: 24px;
            border-radius: 4px;
            border: 1px solid #CBD4E1;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 14px;
                width: 14px;
                fill: var(--black-90);
            }

            &:hover {
                border-color: var(--dark-blue);

                svg {
                    fill: var(--dark-blue);
                }
            }
        }

        .add-btn {
            margin: 0 0 0 10px;
            display: flex;
            align-items: center;

            .svg-icon{
                width: 16px;
                height: 16px;
                margin-right: 4px;
                fill:var(--black-100);
            }
        }

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ul-box {
                display: flex;
                flex-wrap: wrap;
                gap: 4px 8px;
                align-items: center;

                li {
                    display: inline-flex;
                    align-items: center;

                    i {
                        height: 14px;
                        width: 14px;
                        margin-left: 4px;
                        cursor: pointer;
                        border: none;

                        svg {
                            height: 100%;
                            width: 100%;
                            margin: 0;
                            fill: var(--red);
                        }
                    }

                    .social-icon-label{
                        width: 10px;
                        height: 10px;
                        fill:var(--black-90);
                        margin-right: 4px;
                    }

                    span {
                        font: var(--font-med-12);
                        display: flex;
                        align-items: center;
                    }

                }
            }



            p {
                color: var(--black-50);
                font-size: 12px;
                margin: 0;
            }
        }


    }
}

.alert-modal{

    p{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .remove-svg-icon{
        width: 12px;
        height: 12px;
        fill: var(--black-60);
        margin: 0 4px;
    }
}
</style>