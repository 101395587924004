<template>
  <div class="ico sort" :class="[
    { 'asc': sort_value.sort_key === name && sort_value.sort_val === 'asc' },
    { 'desc': sort_value.sort_key === name && sort_value.sort_val === 'desc' },
  ]" @click="sort(name)" v-tippy="{ content: content, delay: [500, 20] }">
    <span>{{ title }}</span>
    <i><svg-icon name="sort" /></i>
  </div>
</template>

<script>
export default {
  name: "Collections-Product-th",

  props: {
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    sort_value: {
      type: Object,
      default: {
        sort_key: '',
        sort_val: 'asc',
      },
    },
    content:{
      type: String,
      default: '',
    }
  },

  methods: {
    sort(name) {
      this.$emit('sort', name);
    }
  }
};
</script>

<style lang="scss"></style>