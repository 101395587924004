<template>
    <Content class="collections-list-content">
        <div class="collections">
            <div class="header" v-show="!is_loading">
                <h3>All Collections&ensp;({{ data_total }}) </h3>
                <div class="right">
                    <DatePicker :key="date_key" @update-date="updateDate" :date_range="date_range" ref="date_picker" />
                    <Dropdown v-if="!is_loading" :items="sort_select" :direction_type="'right'" :icon="'filter'"
                        :default_value="sort_index" @update-dropdown="updateSort" :key="drop_key" />
                </div>
            </div>
            <!-- list -->
            <div class="inner">
                <div :class="['data-wrap', data.length === 0 && 'data-wrap-no-border']">
                    <Scrollbar class="data-list" :show_bar="true">
                        <NoData v-if="data.length === 0 && !isFilter && !is_loading" :image="require('@/assets/images/data.png')"
                            :title="''" :text="'This creator hasn’t created any Collections yet.'" />
                        <Table v-else>
                            <template #head>
                                <tr>
                                    <th class="title"><span>Collections</span></th>
                                    <th><span>Create Date</span></th>
                                    <th><span>Last Update</span></th>
                                    <th>
                                        <CollectionTh :name="'views'" :title="'Collection Views'" :sort_value="sort"
                                            @sort="sortFun"
                                            :content="'The number of views of a Collection cover during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'visit'" :title="'Visit'" :sort_value="sort"
                                            @sort="sortFun"
                                            :content="'Total number of clicks to visit a Collection during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'product_click'" :title="'Product Clicks'"
                                            :sort_value="sort" @sort="sortFun"
                                            :content="'Cumulative link clicks on products in the Collection during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'sales'" :title="'Sales'" :sort_value="sort"
                                            @sort="sortFun"
                                            :content="'Total number of sales driven by the Collection during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'sales_value'" :title="'Sales Value'" :sort_value="sort"
                                            :content="'Gross transaction value driven by the Collection during the time period selected.'"
                                            @sort="sortFun" />
                                    </th>
                                </tr>
                            </template>

                            <template #body>
                                <template v-if="!is_loading && data.length === 0 && this.isFilter">
                                    <tr>
                                        <td colspan="13">
                                            <NoData />
                                        </td>
                                    </tr>
                                </template>

                                <tr v-else v-for="(row, index) in data" :key="index"
                                    :class="{ 'selected': row.is_selected }" @click="open(row)">

                                    <td class="title">
                                        <div class="title-content">

                                            <figure class="img-box img-box-white" v-if="row.cover_image_url">
                                                <img class="likeboard-layout-wrap" :src="row.cover_image_url"
                                                    :alt="row.name" @error="imageError($event)" />
                                            </figure>

                                            <figure class="img-box" v-else>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[0]"
                                                        :src="row.first_4_product_thumbnails[0]" :alt="row.name" /></i>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[1]"
                                                        :src="row.first_4_product_thumbnails[1]" :alt="row.name" /></i>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[2]"
                                                        :src="row.first_4_product_thumbnails[2]" :alt="row.name" /></i>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[3]"
                                                        :src="row.first_4_product_thumbnails[3]" :alt="row.name" /></i>
                                            </figure>

                                            <div class="middle">
                                                <div class="collect-name-box">
                                                    <div class="collect-name"
                                                        v-tippy="{ content: row.name, delay: [500, 20] }">
                                                        {{ row.name }}</div>
                                                </div>
                                                <div class="shop">
                                                    <SvgIcon name="bag" />{{ row.product_count ? row.product_count : 0
                                                    }}
                                                </div>
                                                <div class="badage-box">
                                                    <Badage v-for="(v, k) in row.badage" :key="k" :value="v.name"
                                                        :type="v.color" />
                                                </div>
                                            </div>
                                            <div class="right" @click.stop="goOutside(row)">
                                                <SvgIcon name="go-out" v-if="row.is_publish && !row.deleted_at" />
                                            </div>
                                        </div>
                                    </td>

                                    <td><span v-if="row.created_at">{{ row.created_at ? row.created_at : '---' }}</span>
                                    </td>
                                    <td><span v-if="row.updated_at">{{ row.updated_at ? row.updated_at : '---' }}</span>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.views }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.visit }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.product_click }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.sales }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.sales_value }}</span>
                                        </div>
                                    </td>
                                </tr>

                                <td colspan="13" v-if="is_next_loading || is_loading" >
                                    <Loading class="static" />
                                </td>
                            </template>
                        </Table>
                    </Scrollbar>
                </div>
            </div>
        </div>
    </Content>
</template>

<script>

import Content from '@/components/ui/Content.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import Table from '@/components/ui/Table.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';
import moment from 'moment';
import Layout from '@/components/creator/CollectionImage.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import CollectionProduct from '@/components/creator/CollectionProduct.vue';
import Badage from '@/components/ui/Badage.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import CollectionTh from '@/components/creator/CollectionProductTh.vue';
import { Sort_select_coll_list, SwitchSort, ImageError, HandleNum, HandleName, PickSortIndex } from '@/utils/SwitchFun';

export default {
    name: 'Collections-List',

    data() {
        return {
            is_loading: true,
            date_key: 0,
            start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
            end_date: moment().add(-1, 'days').format('YYYY-MM-DD'),
            isFilter: false,
            data: [],
            data_total: 0,
            sort: {
                sort_key: 'views',
                sort_val: 'desc',
                page: 1,
                per_page: 50
            },
            sort_select: Sort_select_coll_list,
            sort_index: 0,
            drop_key: 0,
            is_end: false,
            is_next_loading: false,
        }
    },

    props: {
        date_range: {
            type: Object,
            default: null,
        },
        data_info: {
            type: Object,
            default: null,
        },
        is_show_hint: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
    },

    components: {
        Content,
        Scrollbar,
        Table,
        NoData,
        Loading,
        SvgIcon,
        Layout,
        DatePicker,
        CollectionProduct,
        Badage,
        Dropdown,
        CollectionTh,
    },

    watch: {
        '$route.params': {
            handler() {
                this.data = [];
                this.is_loading = true;
            },
            immediate: true,
        }
    },

    methods: {

        open(row) {
            this.EventBus.emit('open-collect-product-modal', { data: row, data_info: this.data_info, date_range: this.date_range, is_show_hint: this.is_show_hint });
        },

        imageError: ImageError,
        handleNum: HandleNum,
        handleName: HandleName,

        fetchData() {
            this.is_loading = true;
            let vi = this;
            let req_par = {
                anchor_creator_id: this.data_info.id,
                start_date: this.date_range.start_date,
                end_date: this.date_range.end_date,
            }
            let params_data = {
                ...req_par,
                sort_key: this.sort.sort_key,
                sort_val: this.sort.sort_val,
                page: this.sort.page,
                per_page: this.sort.per_page
            };

            //call table api
            this.$ajax(`creator/get-collection-list`, {
                params: params_data,
                method: 'get',
            }).then(res => {

                res.data.data.data.forEach(item => {
                    item.created_at = vi.$filters.momentFormat(item.created_at, 'll');
                    item.updated_at = vi.$filters.momentFormat(item.updated_at, 'll');
                    item.views = vi.handleNum(item.views);
                    item.visit = vi.handleNum(item.visit);
                    item.product_click = vi.handleNum(item.product_click);
                    item.sales = vi.handleNum(item.sales);
                    item.sales_value = vi.handleNum(item.sales_value);
                    item.cover_image_url = item.cover_image_url ? item.cover_image_url : !item.cover_image_url && item.first_4_product_thumbnails && item.first_4_product_thumbnails.length === 0 ? item.default_cover_image_url : null;
                    item.badage = [];

                    if (item.is_feature) {
                        item.badage.push({ name: 'Featuring', color: 'yellow' })
                    }

                    if (item.deleted_at) {
                        item.badage.push({ name: `Deleted: ${vi.$filters.momentFormat(item.deleted_at, 'll')}`, color: 'red' })
                    } else {
                        if (item.is_publish) {
                            item.badage.push({ name: 'Active', color: 'green' })
                        } else {
                            item.badage.push({ name: `Inactive: ${vi.$filters.momentFormat(item.updated_at, 'll')}`, color: 'normal' })
                        }
                    }

                });


                if (res.data.data.current_page === 1) {
                    this.data = res.data.data.data;
                } else {
                    this.data = this.data.concat(res.data.data.data);
                }
                this.data_total = res.data.data.total;

                this.is_end = res.data.data.current_page === res.data.data.last_page;
                
                if (!this.is_end) {
                    this.is_next_loading = true;
                    this.sort.page += 1;
                    this.fetchData();
                }

                this.is_loading = false;
                this.is_next_loading = false;
            });

            //call total api(not useing now)
            // this.$ajax(`/creator/get-collection-list-statistic`, {
            //     params: req_par,
            //     method: 'get',
            // }).then(res => {
            //     let data = res.data.data[0] ? res.data.data[0] : null;
            // });
        },

        updateSort(id) {
            let getVal = SwitchSort(id.id);
            this.sort.sort_key = getVal.key;
            this.sort.sort_val = getVal.val;
            this.sort.page =1;

            this.sort_index = PickSortIndex(this.sort_select, this.sort.sort_key, this.sort.sort_val);
            this.drop_key += 1;
            this.fetchData();
        },

        sortFun(key) {
            if (key === this.sort.sort_key) {
                this.sort.sort_val = this.sort.sort_val === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort.sort_key = key;
                this.sort.sort_val = 'asc';
            }

            //(sort the table can chnage dropdown)
            this.sort.page =1;
            this.sort_index = PickSortIndex(this.sort_select, this.sort.sort_key, this.sort.sort_val);
            this.drop_key += 1;
            this.fetchData();
        },

        updateDate(obj) {
            this.$emit('update-creator-modal-date', obj);
        },

        goOutside(row) {
            if (row.is_publish && !row.deleted_at) {
                window.open(row.anchor_collection_share_url, '_blank');
            }
        },

    },

    mounted() {
        if (this.date_range) {
            this.date_key += 1;
            this.sort.page =1;
            this.fetchData();
            console.log('collectionList-call-api')
        }
    },

}
</script>

<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

.collections-list-content {

    .header {
        font: var(--font-demi-16);
        line-height: 32px;
        color: var(--black-90);
        display: flex;
        align-items: center;
        margin: 16px 0;

        h3 {
            flex: 1;
        }

        .right {
            display: flex;
            align-items: center;

            .ui-date-picker {
                margin-right: 10px;
            }
        }

    }

    &>.inner {
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 100%;
        overflow: hidden;
    }

    .loading.static {
        margin-top: 48px;
        width: 100%;
    }

    .data-wrap {
        margin: 16px 0;
        border-top: 1px solid var(--border);
        // min-height: 600px;
        height: calc(100% - 16px);
        overflow: hidden;
        display: grid;
        grid-template-rows: auto auto 1fr;
        grid-template-columns: 100%;

        &.data-wrap-no-border {
            border: none;
        }

        .ui-table {
            white-space: nowrap;

            .svg-icon {
                width: 14px;
                height: 14px;
                cursor: pointer;
            }

            th {
                color: var(--black-75);
                background-color: unset;

                .title-content {
                    max-width: 380px;
                    min-width: 380px;
                }

                .ico {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            td {
                color: var(--black-75);
                cursor: pointer;


                .title-content {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    max-width: 380px;
                    overflow: hidden;

                    .img-box {
                        position: relative;
                        width: 160px;
                        height: 160px;
                        min-width: 160px;
                        background-color: var(--bg-03);
                        border-radius: 2px;

                        &.img-box-white {
                            background-color: var(--bg-01);
                        }

                        .likeboard-layout-wrap {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            margin-right: 11px;
                        }

                        i {
                            width: 50%;
                            height: 50%;
                            display: block;
                            position: absolute;
                            box-sizing: border-box;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                display: block;
                            }

                            &:nth-child(1) {
                                top: 0;
                                left: 0;
                                border-bottom: 1px solid var(--bg-01);
                                border-right: 1px solid var(--bg-01);
                            }

                            &:nth-child(2) {
                                top: 0;
                                right: 0;
                                border-bottom: 1px solid var(--bg-01);
                            }

                            &:nth-child(3) {
                                bottom: 0;
                                left: 0;
                                border-right: 1px solid var(--bg-01);
                            }

                            &:nth-child(4) {
                                bottom: 0;
                                right: 0;
                            }
                        }
                    }


                    .badage-box {
                        display: flex;
                        flex-wrap: wrap;

                        .my-badges-iden {
                            margin-right: 8px;
                            margin-bottom: 4px;
                        }
                    }


                    .middle {
                        overflow: hidden;
                        width: calc(100% - 198px);
                        font-weight: 400;
                        color: var(--black-90);

                        .collect-name-box {
                            width: 100%;
                            overflow: hidden;
                        }

                        .collect-name {
                            width: 100%;
                            font: var(--font-demi-14);
                            margin-right: 11px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            white-space: break-spaces;
                        }

                        .shop {
                            display: flex;
                            align-items: center;
                            margin: 8px 0px;
                            font: var(--font-med-12);
                        }
                    }

                    .right {
                        width: 14px;

                        .svg-icon {
                            color: var(--blue);
                        }
                    }
                }

                &.number {
                    .value {
                        display: flex;
                        align-items: center;
                    }

                    .svg-icon {
                        margin-left: 10px;
                        fill: var(--green);

                        &.down {
                            fill: var(--red);
                            transform: rotate(90deg)
                        }
                    }
                }
            }
        }

    }

}
</style>