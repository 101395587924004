import moment from 'moment';

export default (date) => {
    const now = new Date();
    const past = new Date(date);
    const diffTime = Math.abs(now - past);
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60)); 
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));

    if (diffHours < 24) {
        return diffHours + ' hours ago';
    } else if (diffDays <= 7) {
        return diffDays + ' days ago';
    } else if (diffMonths < 3) {
        return diffMonths + ' months ago';
    } else {
        if(moment(date).isValid()){
            return moment(date).format('MMM DD, YYYY');
        }else{
            return'---'
        }
    }
}