let motom_url=`https://www.qa.motom.me`;
if (process.env.NODE_ENV === 'development') {
    motom_url='https://www.qa.motom.me';
} else {
    motom_url=`https://www.motom.me`
}

const goMedia=(type,val)=>{

    if(val){
        if (type =='tk')
            window.open(`https://www.tiktok.com/${val}`, '_blank');
        else if((type =='ig'))
            window.open(`https://www.instagram.com/${val}`, '_blank');
        else if((type =='mo'))
            window.open(`${motom_url}/user/${val}/profile`, '_blank');
        else 
            window.open(`${motom_url}/@${val}/profile`, '_blank');
    }

}

export { goMedia };