<template>
    <div class="line-box" v-for="(v,k) in data" :key="k">
        <div class="title-box">
            <div class="title-name">{{ v.name }}</div>
            <div class="value">{{ v.value }}</div>  
        </div>
        <div class="line-range">
            <div class="range" :style="`width:${ v.style }%`"></div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import * as echarts from 'echarts';
import NoData from '@/components/creator/NoData.vue';

export default {
    name: 'Doughnut',

    components: {
        SvgIcon,
        NoData,
        echarts,
    },

    data() {
        return {
            chart: null,
            chart_type: 'view',
            option: {},
            is_show_cart: true,
        }
    },

    props: {
        data:{
            type:Array,
            default: []
        }
    },

    methods: {
        resizeChart() {
            this.$nextTick(() => {
                if (this.$refs.chart) {

                    let myChart = echarts.init(this.$refs.chart);
                    myChart.setOption(this.option);
                    myChart.resize();
                }
            })
        },

        update(data) {
            let type = data.type;
            let obj = data.data;

            let i = 0;
            if (obj) {
                obj.forEach((o, key) => {
                    i += o.value;
                });


                //OTHERS TURN LAST
                let othersIndex = obj.findIndex(e => { return e.name == 'others' });

                if (othersIndex !== -1) {
                    let othersObj = obj[othersIndex];
                    obj = obj.filter(e => { return e.name !== 'others' });
                    obj.push(othersObj);
                }


                // const makeUpperCase = (str) => {
                //     let newStr = str.toUpperCase();
                //     return newStr.replace(/^./, newStr[0].toLowerCase());
                // }

                //fixed color
                obj = obj.map(e => {
                    // console.log(e);
                    let colorIndex = this.colorList.findIndex(ee => { return ee.name == e.name })
                    let name = e.name.replace(/^./, e.name[0].toUpperCase());
                    // if(e.name == 'ios')  makeUpperCase(e.name);
                    // if(e.name == 'ios') name = 'iOS';
                    // if(e.name == 'others') name = 'Other';
                    // if(e.name == 'tiktok') name = 'TikTok';

                    switch (e.name) {
                        case 'ios':
                            name = 'iOS';
                            break;
                        case 'others':
                            name = 'Other';
                            break;
                        case 'tiktok':
                            name = 'TikTok';
                            break;
                    }


                    return {
                        ...e,
                        name: name,
                        itemStyle: {
                            color: colorIndex !== -1 ? this.colorList[colorIndex].color : ''
                        }
                    }
                })


                this.is_show_cart = (i !== 0);

                this.option = {
                    backgroundColor: '#FFFFFF',
                    color: this.color,
                    tooltip: {
                        trigger: 'item',
                        formatter: function (params, ticket, callback) {
                            if (type === 'sales_value') {
                                return `<div class="poe-tooltip">${params.marker} <strong>${params.data.name}</strong><span>${'$' + Number(params.data.value).toFixed(2)}</span></div>`;
                            } else {
                                return `<div class="poe-tooltip">${params.marker} <strong>${params.data.name}</strong><span>${params.data.value}</span></div>`;
                            }
                        }
                    },
                    legend: {
                        padding: 16,
                        backgroundColor: '#F2F2F2',
                        borderRadius: 8,
                        bottom: '0%',
                        left: 'center'
                    },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            bottom: '20%',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: obj
                        }
                    ]
                };
            }

            if (!this.chart) this.chart = echarts.init(this.$refs.chart);
            this.chart.setOption(this.option);
        },
    },

    created() {
        window.addEventListener('resize', this.resizeChart);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeChart);
    },
}
</script>
<style lang="scss" scoped>
.line-box {
   margin-bottom: 4px;
   
   .title-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        .title-name{
            font-size: 14px;
        }

        .value{
            font:var(--font-med-16);
        }
   }

   .line-range{
        background-color: var(--bg-03);
        position: relative;
        height: 8px;

        .range{
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--dark-blue);
            height: 8px;
        }
   }
 
}
</style>