<template>
	<div class="report-box" v-if="show">
		<div class="report-item" v-for="(v, k) in view_value" :key="k">
			<div class="value">{{ v.value }}</div>
			<div class="text">{{ v.name }}
				<tippy :id="v.tip" :delay="[0, 20]" placement="right" v-if="v.content" :content="v.content"
					trigger="mouseenter click">
					<svg-icon name="question-3" />
				</tippy>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'SocialValueBox',
	data() {
		return {
		};
	},

	props: {
		show: {
			type: Boolean,
			default: true,
		},

		view_value: {
			type: Array,
			default: [],
		},
	},

	methods: {},
	watch: {},
};
</script>

<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.report-box {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 4px;

	.report-item {
		padding: 24px 16px;
		background: white;
		border-radius: 8px;


		.value {
			font: var(--font-med-20);
			color: var(--dark-blue);
			display: flex;
			align-items: center;
			

			svg {
				margin-left: 10px;
				width: 10px;
				height: 10px;
				fill: var(--green);

				&.down {
					fill: var(--red);
					transform: rotate(90deg)
				}
			}
		}

		.text {
			margin-top: 4px;
			font: var(--font-med-14);
			color: var(--black-60);
			@include flexCenter();

			.svg-icon {
				cursor: pointer;
				width: 13px;
				height: 13px;
				fill: var(--black-40);
				margin-left: 4px;
			}
		}
	}
}
</style>