<template>
    <Scrollbar :class="['right audience', is_one_line && 'audience-one-line']" v-if="show">
        <div class="title big-title">
            <span>{{ title }}
                <!-- <tippy :delay="[0, 20]" placement="right" v-if="title" :content="title">
                    <svg-icon name="question-3" />
                </tippy> -->
            </span>
            <time v-if="!is_one_line">Last 30 days</time>
        </div>
        <div class="audience-contnet">
            <div class="doughnut-box">
                <div class="title">
                    <span>Gender
                        <tippy :delay="[0, 20]" placement="right" trigger="mouseenter click" 
                            :content="`Gender breakdown is based on self-reported data from the creator's followers.`">
                            <svg-icon name="question-3" />
                        </tippy>
                    </span>
                </div>
                <Doughnut v-if="gender_do.data.length > 0" :color="gender_do.color" ref="gender"
                    :key="gender_do.loading" />
                <NoData v-else :image="err_data.img" text="" :title="err_data.gender" />
            </div>

            <div class="doughnut-box">
                <div class="title">
                    <span>Age Range
                        <tippy :delay="[0, 20]" placement="right"  trigger="mouseenter click" 
                            :content="`Age information is based on self-reported data from the creator's followers.`">
                            <svg-icon name="question-3" />
                        </tippy>
                    </span>

                </div>
                <div class="small-content" v-if="age_range.data.length > 0">
                    <LineValue :key="gender_do.loading" :data="age_range.data" />
                </div>
                <NoData v-else :image="err_data.img" text="" :title="err_data.age" />
            </div>

            <div class="doughnut-box">
                <div class="title">
                    <span>Countries</span>
                </div>
                <LineValue v-if="countries_range.data.length > 0" :data="countries_range.data" />
                <NoData v-else :image="err_data.img" text="" :title="err_data.countries" />
            </div>
        </div>
    </Scrollbar>

</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import Button from '@/components/ui/Button.vue';
import Doughnut from '@/components/summary/Doughnut.vue';
import LineValue from '@/components/summary/LineValue.vue';
import moment from 'moment';
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';

export default {
    name: 'audience-box',

    data() {
        return {
            start_date: moment().add(-30, 'days').toDate(),
            end_date: moment().add(-1, 'days').toDate(),
            gender_do: {
                color: ['#1DB954', '#1DA1F2', '#8E8E93'],
                list: ['Women', 'Men', 'Other'],
                hadData: false,
                data: [],
            },
            age_range: {
                button: [
                    { name: 'All', index: 0 },
                    { name: 'Women', index: 1 },
                    { name: 'Men', index: 2 },
                ],
                index: 0,
                data: [],
                hadData: false,
            },
            countries_range: {
                data: [],
                hadData: false,
            },
            show: true
        };
    },

    components: {
        SvgIcon,
        Scrollbar,
        Button,
        Doughnut,
        LineValue,
        Loading,
        NoData,
    },

    computed: {
        err_data() {
            if (this.is_one_audience) {
                return {
                    gender: 'No data available.',
                    age: 'No data available.',
                    countries: 'No data available.',
                    img: require('@/assets/images/pen.png'),
                }
            } else {
                return {
                    gender: 'Not enough creator data.',
                    age: 'Not enough creator data.',
                    countries: 'Not enough creator data.',
                    img: require('@/assets/images/pen.png'),
                }
            }
        }
    },

    props: {
        is_one_line: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: 'Audience'
        },

        is_one_audience: {
            type: Boolean,
            default: false
        },

        data: {
            type: Object,
            default: {}
        }
    },
    methods: {

        getDoughnutGender(obj) {
            this.gender_do.data = this.data.gender;
            this.$nextTick(() => {
                if (this.$refs.gender) this.$refs.gender.update({
                    data: this.data.gender,
                    type: 'gender_value',
                });
            });

        },

        getAgeRange(obj) {
            this.age_range.data = this.data.age.map(e => {
                return {
                    ...e,
                    value: `${(e.value * 100).toFixed(2)}%`,
                    style: e.value * 100
                }
            })
            //this.age_range.hadData = false;
        },

        ageMenuClick(v) {
            this.age_range.index = v.index;
        },

        getCountriesRange(obj) {
            this.countries_range.data = this.data.countries.map(e => {
                return {
                    ...e,
                    value: `${(e.value * 100).toFixed(2)}%`,
                    style: e.value * 100
                }
            })
        },

        startFun() {

            this.getDoughnutGender();
            this.getAgeRange();
            this.getCountriesRange();
        },

        updateDate(obj) {
            this.start_date = obj.start_date;
            this.end_date = obj.end_date;
            setTimeout(() => {
                this.startFun()
            }, 10);
        }
    },

    mounted() {

        this.EventBus.off('update-date-at-analytics');
        this.EventBus.on('update-date-at-analytics', (obj) => {
            console.log('update-date-at-analytics')
            this.updateDate(obj);
        });

        this.startFun();
    }
};
</script>

<style lang="scss" scoped>
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.right {
    min-width: 320px;
    padding-left: 16px;

    .big-title {
        @include flexCenter();
        justify-content: space-between;
    }

    .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--black-90);

        time {
            font-family: var(--font);
            font-size: 14px;
            color: var(--black-75);
        }

        span {
            @include flexCenter();
        }

        .svg-icon {
            width: 13px;
            height: 13px;
            margin-left: 4px;
            fill: var(--black-40);
            cursor: pointer;
        }
    }

    &.audience-one-line {
        padding-left: 0;

        .audience-contnet {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;

        }

    }

    .doughnut-box {
        margin-top: 8px;
        padding: 12px;
        border: 1px solid var(--border);
        border-radius: 10px;
        background-color: var(--bg-01);

        .small-content {

            .line-menu {
                @include flexCenter();
                margin: 14px 0;

                .ui-button {
                    font: var(--font-fg-14);
                    margin-right: 4px;
                    padding: 0 12px;
                }
            }
        }
    }
}
</style>
