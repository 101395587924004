<template>
    <aside class="ui-product-list">
        <div class="list-header">
            <!-- <div class="title">Best performing (250)</div> -->
            <div class="title">{{ title }}
                <span v-if="last_update">Last Update: {{ last_update }}</span>
            </div>
            <div v-if="is_swiper" class="col">
                <span class="arrow swiper-button-prev">
                    <svg-icon name="arrow" />
                </span>
                <span class="arrow swiper-button-next">
                    <svg-icon name="arrow" />
                </span>
            </div>
            <div class="btn-box" v-else>
                <DatePicker v-if="show_date_picker && !is_loading" :date_range="date_range" @update-date="updateDate" />
                <Dropdown  v-if="!is_loading" :items="sort_select" :direction_type="'right'" :icon="'filter'" :default_value="sort_index" @update-dropdown="updateSort" />
            </div>
        </div>
        <swiper v-if="is_swiper" :modules="modules" :slides-per-view="'auto'" :slides-per-group="3" :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }">

            <swiper-slide v-for="(item, index)  in data" :key="'product-' + item.product.id">
                <ProductItem @click="open(item)" :data="item" />
            </swiper-slide>
        </swiper>
        <div :class="['list', is_loading && 'list-loading']" v-else-if="data.length !== 0">
            <Loading v-if="is_loading && !is_loading_page" class="static"/>
            <!-- if is_loading_page=true,mean need to loading in bottom, product need to show -->
            <ProductItem v-for="(item, index)  in data" @click="open(item)" :data="item"
                :key="'product-' + item.product.id" v-show="!is_loading || is_loading_page"/>
        </div>
        <div class="list" v-if="!is_loading && data.length === 0">
            <NoData :image="require('@/assets/images/pc.png')" :title="no_data_context.title" :text="no_data_context.content" />
        </div>
        <Loading v-if="is_loading && is_loading_page && !is_swiper" class="static" />
    </aside>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import ProductItem from '@/components/product/ProductItem.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';

export default {
    name: 'ui-product-list',

    components: {
        SvgIcon,
        Button,
        Dropdown,
        DatePicker,
        ProductItem,
        ModalSide,
        Loading,
        NoData,
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            sort_select: [
                { id: 1, text: 'Sort: Product Views (Descending)' },
                { id: 2, text: 'Sort: Product Views (Ascending)' },
                { id: 3, text: 'Sort: Clicks (Descending)' },
                { id: 4, text: 'Sort: Clicks (Ascending)' },
                { id: 5, text: 'Sort: Sales (Descending)' },
                { id: 6, text: 'Sort: Sales (Ascending)' },
                { id: 7, text: 'Sort: Sales Value (Descending)' },
                { id: 8, text: 'Sort: Sales Value (Ascending)' },
            ],
            sort_type: 1,
            listScroll: null,
            date: {
                start: '',
                end: ''
            },
            no_data: {

            },
        }
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        is_swiper: {
            type: Boolean,
            default: false,
        },
        show_date_picker: {
            type: Boolean,
            default: false,
        },
        date_range: {
            type: Object,
            default: null,
        },
        sort_index: {
            type: Number,
            default: 0,
        },
        last_update: {
            default: '',
            type: String,
        },
        data: {
            type: Array,
            default: [],
        },
        is_loading: {
            type: Boolean,
            default: false,
        },
        is_loading_page:{
            type: Boolean,
            default: false,
        },
        no_data_context: {
            type: [Object, String],
            default: '',
        },
        data_info:{
            type: Object,
            default: null
        },
        // creator_id: {
        //     type: Number,
        //     default: 0,
        // },
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },

    methods: {
        updateDate(obj) {
            this.date.start = moment(obj.start_date).format('YYYY-MM-DD');
            this.date.end = moment(obj.end_date).format('YYYY-MM-DD');
            let newobj = {
                type: this.sort_type,
                date: {
                    start: this.date.start,
                    end: this.date.end
                }
            }
            this.$emit('update-sort', newobj);
            // this.fetchData();
        },

        updateSort(id) {
            this.sort_type = id;
            this.date.start= moment(this.date_range.start_date).format('YYYY-MM-DD');
            this.date.end= moment(this.date_range.end_date).format('YYYY-MM-DD')
            let obj = {
                type: this.sort_type,
                date: {
                    start: this.date.start ,
                    end: this.date.end,
                }
            }
            this.$emit('update-sort', obj);
            // this.fetchData();
        },

        open(data) {
            this.date.start= moment(this.date_range.start_date).format('YYYY-MM-DD');
            this.date.end= moment(this.date_range.end_date).format('YYYY-MM-DD')
            let d = {
                "product_id": data.id,
                "start_date": this.date.start,
                "end_date": this.date.end,
            };
            if(this.data_info) d.data_info=this.data_info;
            this.EventBus.emit('open-product-modal', d);
            
        },

    },
}
</script>
<style lang="scss">
.ui-product-list {
    position: relative;
    overflow: hidden;
    width: 100%;

    .list-header {
        margin: 0 4px 16px;
        display: flex;

        .title {
            font: var(--font-demi-16);
            line-height: 32px;
            color: var(--black-90);
            flex: 1;

            span {
                margin-left: 16px;
                font: var(--font-fg-12);
            }
        }

        .col {
            display: flex;
            align-items: center;

            span {
                height: 24px;
                width: 24px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-left: 10px;
                border-radius: 2px;
                transition: all .2s ease-in-out;
                color: var(--black-90);

                &:hover {
                    background-color: var(--bg-03);
                }

                svg {
                    height: 10px;
                    width: 10px;
                    position: relative;
                    transform: rotate(90deg);
                }

                &.swiper-button-next {
                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &.swiper-button-disabled {
                    cursor: not-allowed;
                    color: var(--black-40);
                    pointer-events: none;
                }
            }
        }

        .btn-box {
            display: flex;

            .ui-dropdown {
                margin-left: 10px;
            }

            // .ui-button {
            //     margin-left: 10px;

            //     span {
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;

            //         svg {
            //             margin-right: 6px;
            //             width: 12px;
            //             height: 8px;
            //         }
            //     }
            // }
        }


    }

    .loading {
        margin-top: 20px;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;

        .product-item {
            width: calc(25% - 8px);
            display: block;
            margin: 4px;
        }
    }

    .swiper-wrapper {
        display: flex;

        .swiper-slide {
            width: 236px;
            padding: 0 8px;
            box-sizing: content-box;

            &:last-child {
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }
}
</style>