<template>
	<div class="filter-results" v-if="params.profile_status !== 0 || params.storefront_status !== 0 || params.recently_added !== 0 ||
            (((params.metric_type === 0 || params.metric_type === 1) && params.metric_amount !== '') || 
            (params.metric_type === 2 && params.metric_min_amount !== '' && params.metric_max_amount !== ''))||(params.social && params.social.metric_min_amount_input !=='' && params.social.metric_max_amount_input !=='')">
        <div v-if="params.profile_status !== 0" @click="clearFilter('profile_status')" class="item">
            Profile: {{ profile_status[params.profile_status - 1] }}<SvgIcon name="close" />
        </div>

        <div v-if="params.storefront_status !== 0" @click="clearFilter('storefront_status')" class="item">
            Storefront: {{  storefront_status[params.storefront_status-1] }}<SvgIcon name="close" />
        </div>
       
        <div v-if="params.recently_added !== 0" @click="clearFilter('recently_added')" class="item">
            {{ (params.recently_added === 1) ? 'Recently Added' : 'Earliest added'}}<SvgIcon name="close" />
        </div>

        <div v-if="(((params.metric_type === 0 || params.metric_type === 1) && params.metric_amount !== '') || 
            (params.metric_type === 2 && params.metric_min_amount !== '' && params.metric_max_amount !== ''))" @click="clearFilter('metric')" class="item">
            {{ (params.metric === 3) ? 'Clicks: ' : (params.metric === 2) ? 'Sales: ' : (params.metric === 1) ? 'Sales Value: ' : 'Views: ' }}
            {{ (params.metric_type === 0) ? 'Greater than ' : (params.metric_type === 1) ? 'Less than ' : 'Custom ' }}
            {{ (params.metric_type === 0 || params.metric_type === 1) ? params.metric_amount : params.metric_min_amount + ' - ' + params.metric_max_amount }}
            <SvgIcon name="close" />
        </div>

        <div v-if="params.social.metric_min_amount_input !=='' && params.social.metric_max_amount_input !=='' " @click="clearFilter('social')" class="item">
            {{ (params.social.metric === 1) ? 'TikTok: ' : 'Instagram: '  }}Custom 
            {{  params.social.metric_min_amount_input + ' - ' + params.social.metric_max_amount_input }}
            <SvgIcon name="close" />
        </div>
	</div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'FilterResults',

    components: {
        SvgIcon,
    },

    data() {
        return {
            storefront_status: [
                'Active',
                'Unavailable',
                'Disabled',
                'Declined',
                'Invited',
            ],
            profile_status: [
                'Joined',
                'Pending',
                // 'Deleted',
            ]
        }
    },

    props: {
        params: {
            type: Array,
            default: [],
        },
    },

    methods: {
        clearFilter(type) {
            if (type === 'profile_status') this.params.profile_status = 0;
            else if (type === 'storefront_status') this.params.storefront_status = 0;
            else if (type === 'recently_added') this.params.recently_added = 0;
            else if (type === 'metric') {
                this.params.metric_type = 0;
                this.params.metric_amount = '';
                this.params.metric_min_amount = '';
                this.params.metric_max_amount = '';
            }
            else if (type === 'social') {
                this.params.social.metric = 1;
                this.params.social.metric_msg = '';
                this.params.social.metric_min_amount_input = '';
                this.params.social.metric_max_amount_input = '';
            }
            this.$emit('update-filter', this.params);
        },

    },

    mounted(){
        console.log('ok')
    }

};
</script>

<style lang="scss">
    .filter-results {
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;

        .item {
            margin-right: 8px;
            padding: 6px 10px;
            display: flex;
            align-items: center;
            background: var(--bg-02);
            border: 1px solid var(--neutral);
            border-radius: 100px;
            color: var(--black-90);
            font: var(--font-med-12);
            cursor: pointer;
            transition: all 0.2s ease;

            svg {
                width: 8px;
                height: 8px;
                margin-left: 5px;
            }

            &:hover {
                background: var(--light-neutral);
            }
        }
    }
</style>