<template>
    <section class="storefront-summary">
        <div class="head" v-if="last_update">
            <div class="top">
                <time>Updated: {{ last_update }}</time>
                <DatePicker v-if="!is_hide_date" v-show="!is_loading_data && show_date_picker" @update-date="updateDate"
                    :date_range="date_range" ref="date_picker" />
            </div>
            <div class="bot">
                <span>{{ title.title }}</span>
            </div>
        </div>
        <div class="title" v-else>
            <span>{{ title.title }}</span>
            <DatePicker v-if="!is_hide_date" v-show="!is_loading_data && show_date_picker" @update-date="updateDate"
                :date_range="date_range" ref="date_picker" />
        </div>
        <div class="chart-box" :class="{ 'is-loading': is_loading_data }">

            <div class="type-box" v-if="data">
                <div class="type" :class="{ 'selected': chart_type === 'view' }" @click="clickType('view')">
                    <div class="value" v-if="data.page_views_sum === 0">---</div>
                    <div class="value" v-else>{{ data.page_views_sum.toLocaleString('en-US') }}
                        <svg-icon name="arrow-up" v-if="data.page_views_trend !== 'same'"
                            :class="data.page_views_trend" />
                    </div>
                    <div class="text">{{ title.view }}</div>
                </div>
                <div class="type" :class="{ 'selected': chart_type === 'click' }" @click="clickType('click')">
                    <div class="value" v-if="data.click_sum === 0">---</div>
                    <div class="value" v-else>{{ data.click_sum.toLocaleString('en-US') }}
                        <svg-icon name="arrow-up" v-if="data.click_trend !== 'same'" :class="data.click_trend" />
                    </div>
                    <div class="text">{{ title.click }}</div>
                </div>
                <div class="type" :class="{ 'selected': chart_type === 'tran' }" @click="clickType('tran')">
                    <div class="value" v-if="data.sales_sum === 0">---</div>
                    <div class="value" v-else>{{ data.sales_sum.toLocaleString('en-US') }}
                        <svg-icon name="arrow-up" v-if="data.sales_sum !== 'same'" :class="data.sales_trend" />
                    </div>
                    <div class="text">{{ title.sale }}</div>
                </div>
                <div class="type" :class="{ 'selected': chart_type === 'sales' }" @click="clickType('sales')">
                    <div class="value" v-if="data.sales_value_sum === 0">---</div>
                    <div class="value" v-else>${{ formatMoney(data.sales_value_sum) }}
                        <svg-icon name="arrow-up" v-if="data.sales_value_trend !== 'same'"
                            :class="data.sales_value_trend" />
                    </div>
                    <div class="text">{{ title.sale_value }}</div>
                </div>
            </div>

            <Loading v-if="is_loading_data" class="static" />

            <div id="chart_summary" class="chart" ref="chart" v-show="(chart_type === 'view' && data.page_views_sum !== 0) ||
            (chart_type === 'click' && data.click_sum !== 0) ||
            (chart_type === 'tran' && data.sales_sum !== 0) ||
            (chart_type === 'sales' && data.sales_value_sum !== 0)
            "></div>

            <NoData v-if="!is_loading_data
            && ((chart_type === 'view' && data.page_views_sum === 0) ||
                (chart_type === 'click' && data.click_sum === 0) ||
                (chart_type === 'tran' && data.sales_sum === 0) ||
                (chart_type === 'sales' && data.sales_value_sum === 0))
            " :image="require('@/assets/images/data.png')" title="No data available" />

        </div>
    </section>

    <EngagementPosts ref="posts" :is_show_crt="is_show_crt"/>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import * as echarts from 'echarts';
import NoData from '@/components/creator/NoData.vue';
import Loading from '../ui/Loading.vue';
import EngagementPosts from '@/components/EngagementPosts.vue';


export default {
    name: 'StorefrontSummary',
    emits: ['update-data','update-creator-modal-date'],

    components: {
        DatePicker,
        NoData,
        Loading,
        EngagementPosts,
    },

    data() {
        return {
            option: null,
            chart: null,
            is_loading_data: false,
            is_checked_organic: true,
            is_checked_paid: true,
            data: false,
            data_clicks: [],
            data_sales: [],
            data_transactions: [],
            data_views: [],
            chart_type: 'view',
            is_change: false,
            start_date: moment().add(-30, 'days').toDate(),
            end_date: moment().add(-1, 'days').toDate(),
        }
    },

    props: {
        is_hide_date: {
            type: Boolean,
            default: false,
        },

        is_show_posts: {
            type: Boolean,
            default: true,
        },

        last_update: {
            type: String,
            default: '',
        },

        user_id: {
            type: [String, Number],
            default: '',
        },

        show_date_picker: {
            type: Boolean,
            default: false,
        },

        date_range: {
            type: Object,
            default: null,
        },
        title: {
            type: Object,
            default: {
                title: 'Storefront Summary',
                view: 'Page Views',
                click: 'Clicks',
                sale: 'Sales',
                sale_value: 'Sales Value'
            },
        },
        api_url: {
            type: String,
            default: '/analytics/merchant-account-statistic',
        },
        product_id: {
            type: [String, Number],
            default: '',
        },
        products_num: {
            type: [Object, Array],
            default: [],
        },
        is_show_crt:{
            type:Boolean,
            default:false
        }
    },


    methods: {
        formatMoney: formatMoney,

        setOption() {
            this.option = {};
        },

        getData(type = 'view') {
            let s = moment(this.start_date).format('YYYY-MM-DD');
            let e = moment(this.end_date).format('YYYY-MM-DD');

            let date_time = `?start_date=${s}&end_date=${e}`;
            let create_id = this.user_id ? `&creator_user_id=${this.user_id}` : '';
            let product_id = this.product_id ? `&product_id=${this.product_id}` : '';

            let api = `${this.api_url}${date_time}${create_id}${product_id}`;

            // let api = `/analytics/merchant-account-statistic?type=${type}`;
            // if (this.user_id) api = `${api}&user_id=${this.user_id}`;

            this.is_loading = true;
            this.is_loading_data = true;

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                let sv = res.data.data.sales_value_rows;

                for (let i in sv) {
                    sv[i].total_count = Number(sv[i].total_count).toFixed(2);
                }

                this.data = res.data.data;
                let data = [];
                let date = [];

                data = this.handleChartZone(this.data.page_views_rows).data;
                date = this.handleChartZone(this.data.page_views_rows).date;
                for(let i in date){
                    date[i]=moment(date[i]).format('MMM DD, YYYY');
                }
                this.option.xAxis[0].data = date;
                this.option.series[0].data = data;

                const chart = echarts.init(this.$refs.chart);
                chart.setOption(this.option);
                this.is_loading = false;
                this.is_loading_data = false;
                this.clickType(this.chart_type);

                if (this.is_show_posts && this.$store.state.user_data.is_motom_internal_user) {
                    chart.on('click', (params) => {
                        if (params.name) {
                            this.$refs.posts.show(params, this.user_id ? this.user_id : null, date, { start_date: moment(this.start_date).format('YYYY-MM-DD'), end_date: moment(this.end_date).format('YYYY-MM-DD') });
                        }
                    });
                }

                this.$emit('update-data', res.data);
            });
        },

        initOption() {
            let vi = this;
            this.option = {
                color: ['#000000'],
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); padding: 5px 10px; font-size: 12px; line-height: 20px; border-radius: 4px;',
                    axisPointer: {
                        type: 'line',
                        snap: true,
                        lineStyle: {
                            width: 1,
                            color: '#232A60',
                            type: 'solid',
                        },
                        label: {
                            componentType: 'series',
                            show: true,
                            backgroundColor: '#78CBFA'
                        },
                    },
                    formatter: function (params) {
                        let content = moment(params[0].axisValue).format('MMM DD, YYYY') + '<br>';
                        let title = '●';
                        if (vi.chart_type == 'sales')
                            title = '$';
                        params.forEach(function (item) {
                            content += `${title} ${item.value} <br>`;
                        });
                        return content;
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisLabel: {
                            formatter: (params) => {
                                return moment(params).format('MMM DD')
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        alignTicks: false,
                    }
                ],
                series: [
                    {
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 20,
                        itemStyle: {
                            color: '#232A60',
                            borderWidth: 6,
                            borderColor: 'rgba(176, 216, 246)',
                        },
                        triggerLineEvent: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            // opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#5789EB'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(120, 203, 250, 0.7)'//'#78CBFA'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            };
        },

        resizeChart() {
            this.$nextTick(() => {
                if (this.$refs.chart) {
                    const chart = echarts.init(this.$refs.chart);
                    chart.setOption(this.option);
                    chart.resize();
                }
            })

        },

        handleChartZone(arr) {
            let data = [];
            let date = [];
            let last = null;

            arr.forEach(item => {

                const pushData = () => {
                    data.push(item.total_count);
                    date.push(item.event_date);
                    last = item.event_date;
                }

                if (last !== item.event_date) {
                    pushData()
                } else {
                    data.splice(-1, 1);
                    date.splice(-1, 1);
                    pushData();
                }
            })
            return { data: data, date: date }
        },

        clickType(type) {
            this.chart_type = type;
            let data = [];
            let date = [];
            let o = {};

            switch (type) {
                case 'view':
                    o = this.handleChartZone(this.data.page_views_rows);
                    // data = this.handleChartZone(this.data.page_views_rows).data;
                    // date = this.handleChartZone(this.data.page_views_rows).date;
                    break;
                case 'click':
                    o = this.handleChartZone(this.data.clicks_rows);
                    // data = this.handleChartZone(this.data.clicks_rows).data;
                    // date = this.handleChartZone(this.data.clicks_rows).date;

                    break;
                case 'tran':
                    o = this.handleChartZone(this.data.sales_rows);
                    // data = this.handleChartZone(this.data.sales_rows).data;
                    // date = this.handleChartZone(this.data.sales_rows).date;

                    break;
                case 'sales':
                    o = this.handleChartZone(this.data.sales_value_rows);
                    // data = this.handleChartZone(this.data.sales_value_rows).data;
                    // date = this.handleChartZone(this.data.sales_value_rows).date;

                    break;

            }

            // console.log(this.data.page_views_rows)

            data = o.data;
            date = o.date;

            for(let i in date){
                date[i]=moment(date[i]).format('MMM DD, YYYY');
            }
            this.option.xAxis[0].data = date;
            this.option.series[0].data = data;


            const chart = echarts.init(this.$refs.chart);
            chart.setOption(this.option);
        },

        updateDate(obj) {
            this.start_date = obj.start_date;
            this.end_date = obj.end_date;
            this.getData();
            this.$emit('update-creator-modal-date', obj);
        },
    },

    mounted() {
        this.initOption();
        // console.log(this.date_range)
        if (this.date_range) {
            // this.$refs.date_picker.custom_date = [this.date_range.start_date, this.date_range.end_date];
            this.start_date = this.date_range.start_date;
            this.end_date = this.date_range.end_date;

            // setTimeout(() => {
            //     this.$refs.date_picker.setDate([this.date_range.start_date, this.date_range.end_date]);

            // }, 1000);
        }

        this.getData();

        this.EventBus.off('update-date-at-analytics');
        this.EventBus.on('update-date-at-analytics', (obj) => {
            this.updateDate(obj);
        });
    },

    created() {
        window.addEventListener('resize', this.resizeChart);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeChart);
    },
}
</script>
<style lang="scss">
.storefront-summary {
    margin-bottom: 24px;

    .head {
        display: block;

        .top {
            display: flex;
            align-items: center;
            margin: 16px 0;
            pointer-events: none;
            justify-content: flex-end;
            position: relative;
            margin-top: -56px;
            height: 32px;

            @media screen and (max-width: 1230px) {
                margin-top: 16px;
            }

            & * {
                pointer-events: auto;
            }

            time {
                font-size: 12px;
                color: var(--black-75);
                padding-right: 12px;
            }
        }

        .bot {
            margin: 32px 0 16px 0;
            display: flex;
            align-items: center;

            &>span {
                font: var(--font-demi-22);
                color: var(--black-90);
                flex: 1;
            }
        }
    }

    .title {
        font: var(--font-demi-22);
        color: var(--black-90);
        display: flex;
        align-items: center;
        min-height: 38px;

        span {
            flex: 1;
        }
    }

    .chart-box {
        background: var(--bg-02);
        margin-top: 20px;
        overflow: hidden;

        .creator-no-data img {
            width: 120px;
        }

        .type-box {
            display: flex;
            cursor: pointer;

            .type {
                width: 25%;
                padding: 24px 16px;
                background: white;
                border: 1px solid rgba(0, 0, 0, 0.08);

                &.selected {
                    background: var(--bg-02);
                    border: unset;
                }

                .text {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    color: var(--black-75);
                }

                .value {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    color: var(--black-90);
                    display: flex;
                    align-items: center;

                    svg {
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        fill: var(--green);

                        &.down {
                            fill: var(--red);
                            transform: rotate(90deg)
                        }
                    }
                }
            }
        }

        .chart {
            width: auto;
            height: 300px;
            padding-bottom: 60px;
        }

    }

}
</style>