const showStageText=(state,val)=>{
    let text = '';
    switch (state) {
        // case 'active':
        case 100:
            text = `This creator has accepted your request and their storefront is live as of ${val}`;
            break;
        //case 'declined':
        case -10:
            text = `This creator declined your storefront request on ${val}. If you believe this was an accident, click "Resend."`;
            break;
        case 20:
            // case 'Added':
            text = `A user on your account added this creator to the portal on ${val}. Click "Share Invite Link" to invite them to create a storefront.`;
            break;
        // case 'not-available':(now turn into invited)
        case 10:
            text = `This creator has deleted their Motom account, which has deleted their storefront.`;
            break;
        //case 'disabled':
        case 0:
            text = `A user on your account disabled this creator's storefront on ${val}. The link to their storefront is no longer live. You can re-activate this creator's storefront by clicking "Edit Status" above.`;
            break;
        case 'joined':
            text = 'This creator joined and valid social handle on Motom.';
            break;
        case 'pending':
            text = 'This creator pending to join and valid social handle on Motom.';
            break;
        case 'delete':
            text = 'This creator joined and deleted the account on Motom.';
            break;
    }

    return text;
}

const makeStatusFunction=(e)=>{
    let show_status_time='';
    switch (e.anchor_page_status) {
        case 100:
            show_status_time=e.accept_store_front_datetime;
            break;
        case 0:
            show_status_time=e.disabled_at;
            break;
        case -10:
            show_status_time=e.declined_at;
            break;
        case 10:
            show_status_time=e.unavailable_at;
            break;
        case 20:
            show_status_time=e.created_at;
            break;
    };

    return show_status_time;
}

const makeStatusTime=(arr)=>{
    return arr.map(e=>{
        return{
            ...e,
            show_status_time:makeStatusFunction(e)
        }
    });
}

export { showStageText , makeStatusTime , makeStatusFunction };