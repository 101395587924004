<template>
    <Modal class="edit-creator-modal" :is_show="is_show_edit_modal" @click-bg="close" :class="{ 'is-loading': is_loading }">
        <header class="edit-header">
            <h2>Edit Creator Info</h2>
            <i @click="close"><svg-icon name="close" /></i>
        </header>
        <div class="user-info">

            <figure><img :src="values.userProfile.avatar_full_url" @error="imageError($event)" /></figure>
            <div :class="['class-' + values.status]">
                <span
                    v-if="values.status === -10 && values.motom_user_id !== null && values.motom_user_unique_key !== null">Joined</span>
                <span
                    v-else-if="values.status === -10 && values.motom_user_id === null && values.motom_user_unique_key === null">Pending</span>
                <span v-else>{{ values.status_text }}</span>
                <!-- {{ values.status_text }} -->
            </div>

            <div class="toggle">
                <p :class="['class-' + values.anchor_page_status]">
                    <span>
                        {{ values.anchor_page_status_text }}
                    </span>
                    <Switch
                        v-if="(values.status === 100 && values.anchor_page_status === 100) || (values.status === 100 && values.anchor_page_status === 0)"
                        :checked="(values.anchor_page_status === 100)" @click.native="is_show_alert_modal = true" />
                </p>
            </div>
            <div class="link" v-if="values.status === 100 && values.anchor_page_status === 100 && values.share_link"><a
                    :href="values.share_link" target="_blank">View Storefront <svg-icon name="share" /></a></div>
        </div>

        <Form :schema="schema" @submit="submit" :values="values" v-slot="{ errors, success }" class="user-form-wrap"
            :class="{ 'is-error': is_error }" ref="form">
            <div class="name-box">
                <div class="name-item">
                    <label class="label">First name</label>
                    <Field type="text" k="first_name" placeholder="First name" :default_value="values.first_name"
                        :error_msg="errors.first_name" :is_success="success.first_name" @update="update"
                        :ref="(el) => { els.push(el) }" />
                </div>
                <div class="name-item">
                    <label class="label">Last name</label>
                    <Field type="text" k="last_name" placeholder="Last name" :default_value="values.last_name"
                        :error_msg="errors.last_name" :is_success="success.last_name" @update="update"
                        :ref="(el) => { els.push(el) }" />
                </div>
            </div>
            <br>
            <label class="label">Social handle(s)</label>
            <p class="desc">We use this to authenticate Motom creator profiles. Once they have connected their social accounts, you will not be able to edit this section.</p>
            <!-- <p v-if="errors.instagram_handle || errors.tiktok_handle" class="alert-text">We are unable to create a
                storefront due to username may contain incorrect format: such as extra space, unsupported characters and
                special characters. </p> -->

            <span v-if="values.status === 100 && values.tiktok_handle && !edit_tiktok_handle" class="ico-social">
                <svg-icon name="tiktok" /> www.TikTok.com/{{ values.tiktok_handle }}
                <tippy v-if="values.edit_tiktok_handle" @click="edit_tiktok_handle = true"
                    content="Click to edit TikTok handle">
                    <svg-icon name="pen" />
                </tippy>
            </span>
            <Field v-show="values.status !== 100 || edit_tiktok_handle" type="icon" k="tiktok_handle" befter_icon="tiktok"
                befter_text="www.TikTok.com/" placeholder="@username" :default_value="values.tiktok_handle"
                :error_msg="errors.tiktok_handle" :is_success="success.tiktok_handle" @update="update"
                :ref="(el) => { els.push(el) }" />
            <span v-if="values.status === 100 && values.instagram_handle && !edit_instagram_handle" class="ico-social">
                <svg-icon name="instagram" /> www.Instagram.com/{{ values.instagram_handle }}
                <tippy v-if="values.edit_instagram_handle" @click="edit_instagram_handle = true"
                    content="Click to edit Instagram handle">
                    <svg-icon name="pen" />
                </tippy>
            </span>
            <Field v-show="values.status !== 100 || edit_instagram_handle" type="icon" k="instagram_handle"
                befter_icon="instagram" befter_text="www.Instagram.com/" placeholder="username"
                :default_value="values.instagram_handle" :error_msg="errors.instagram_handle"
                :is_success="success.instagram_handle" @update="update" :ref="(el) => { els.push(el) }" />
            <p v-if="is_error" v-html="error_msg" class="error-msg"></p>

            <div class="coll-chec" v-if="$store.state.user_data.merchant_account.is_enable_ambassador_program && values.ambassador_application">
                <label class="label">Application</label>
                <dl class="tag-line">
                    <dt>{{ values.ambassador_application.ambassador_application_name }}</dt>
                    <dd><span>{{ values.ambassador_application.ambassador_application_tag }}</span>
                        <!-- <a :href="values.ambassador_application.ambassador_application_url" target="_blank"><svg-icon name="share" /></a> -->
                    </dd>
                </dl>
            </div>

            <div class="coll-chec" v-if="$store.state.user_data.merchant_account.is_allow_customize_collection_cover">
                <label class="label">Collections</label>
                <Checkbox
                    class="w-100-p-check"
                    label_text="Enable this creator to upload custom cover images on their storefront Collections."
                    :checked="values.is_allow_customize_collection_cover"
                    @click.native="values.is_allow_customize_collection_cover = !values.is_allow_customize_collection_cover"
                    :align_start="true" />
            </div>


            <br>
            <div class="btn-wrap">
                <Button v-if="is_can_delete" type="button" color="no-color" size="32"
                    @click.native="deleteCreator">Delete</Button>
                <Button type="submit" color="dark-blue" size="32">Save</Button>
            </div>
        </Form>
    </Modal>

    <Modal :is_show="is_show_alert_modal" class="alert-modal" @click-bg="is_show_alert_modal = false">
        <div class="box">
            <header class="delete-header" v-if="values.status === 100 && values.anchor_page_status === 100">Deactivate
                storefront(s)?</header>
            <header class="delete-header" v-else-if="values.status === 100 && values.anchor_page_status === 0">Enable
                storefront(s)?</header>
            <p v-if="values.status === 100 && values.anchor_page_status === 100">The storefront link(s) will no longer be
                active, but all of the data will remain in the Anchor portal.</p>
            <p v-else-if="values.status === 100 && values.anchor_page_status === 0">1 selected creator ready to enable
                storefront</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.native="toggle(1)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.native="toggle(2)">Yes</Button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Field from '@/components/ui/Field.vue';
import Form from '@/components/ui/Form.vue';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import Switch from '@/components/ui/Switch.vue';
import Checkbox from '@/components/ui/Checkbox.vue';

import * as yup from 'yup';
import { Tippy } from 'vue-tippy';

export default {
    name: 'edit-creator',
    emits: ['save'],

    components: {
        Field,
        Button,
        Form,
        Modal,
        Switch,
        Tippy,
        Checkbox
    },

    data() {
        return {
            is_loading: false,
            is_show_edit_modal: false,
            is_show_alert_modal: false,
            is_can_delete: false,
            is_error: false,
            edit_tiktok_handle: false,
            edit_instagram_handle: false,
            error_msg: '',

            els: [],

            values: {},

            schema: yup.object().shape({
                first_name: yup.string().required('Cannot leave blank.'),
                last_name: yup.string().required('Cannot leave blank.'),
                instagram_handle: yup.string()
                    .max(30, 'Your handle can\'t exceed 30 characters')
                    .matches(
                        /(^$)|^[aA-zZ0-9_.]+$/,
                        'Invalid social handle'
                    )
                    // .when('tiktok_handle', {
                    //     is: '',
                    //     then: (schema) => schema.required('The Instagram name can’t leave as blank')
                    // })
                    ,
                tiktok_handle: yup.string()
                    .max(30, 'Your handle can\'t exceed 30 characters')
                    .matches(
                        /(^$)|^[@][aA-zZ0-9_.]+$|^[aA-zZ0-9_.]+$/,
                        'Invalid social handle'
                    )
                    // .when('instagram_handle', {
                    //     is: '',
                    //     then: (schema) => schema
                    //         .required('The TikTok name can’t leave as blank'),
                    // })
                    ,

            }
            , ['instagram_handle', 'tiktok_handle']),

        }
    },

    methods: {

        update(obj) {
            this.is_error = false;
            this.error_msg = '';
            Object.keys(this.values).forEach(key => {
                if (key === obj.key) this.values[key] = obj.val;
            });
        },

        toggle(type) {
            this.is_show_alert_modal = false;


            if (type === 2) {
                if (this.values.anchor_page_status === 100) {
                    this.values.anchor_page_status = 0;
                    this.values.anchor_page_status_text = 'Disabled'
                } else if (this.values.anchor_page_status === 0) {
                    this.values.anchor_page_status = 100;
                    this.values.anchor_page_status_text = 'Active'
                }
            }
        },

        deleteCreator() {
            this.EventBus.emit('delete-creator', this.values.id);

        },

        submit() {
            this.is_error = false;
            this.is_loading = true;

            if (this.values.tiktok_handle) {
                this.values.tiktok_handle = this.values.tiktok_handle.replace(/@/gi, '')
            }

            this.values.is_allow_customize_collection_cover = this.values.is_allow_customize_collection_cover ? 1 : 0;

            // values.anchor_page_status === 100

            this.$ajax('/creator/edit', {
                method: 'post',
                data: this.values,
            }).then(res => {
                this.close();

                this.$emit('save', res.data.data);
                this.is_loading = false;

            }).catch(err => {
                this.is_loading = false;

                if (err && err.response && err.response.data.data) {
                    // console.log(err.response.data)
                    this.is_error = true;
                    this.error_msg = err.response.data.data.message;
                    this.$refs.form.init();
                }

            });
        },

        open(data) {
            this.els = [];
            const clone_obj = JSON.parse(JSON.stringify(data));
            // clone_obj.edit_instagram_handle = true;
            // clone_obj.edit_tiktok_handle = true;
            // console.log(clone_obj)
            this.is_error = false;
            this.error_msg = '';
            this.values = clone_obj;
            this.values.anchor_creator_id = clone_obj.id;
            if (!this.values.userProfile) {
                this.values.userProfile = {
                    avatar_full_url: require('@/assets/images/user.png')
                }
            }
            this.is_show_edit_modal = true;
            this.edit_tiktok_handle = false;
            this.edit_instagram_handle = false;
            this.is_can_delete = (clone_obj.status === 10 || ((clone_obj.status === 100 && clone_obj.anchor_page_status === 10) || (clone_obj.status === 100 && clone_obj.anchor_page_status === 20) || (clone_obj.status === 100 && clone_obj.anchor_page_status === -10)));
            // console.log('values', this.values)

            setTimeout(() => {
                // this.is_init = true;
                this.els.forEach(el => {
                    el.sync();
                });

                setTimeout(() => {
                    this.$refs.form.init();
                }, 300);
            }, 100);

        },

        close() {
            this.is_show_edit_modal = false;
            this.is_error = false;
            this.error_msg = '';
        },

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },
    },

}
</script>
<style lang="scss">
.edit-creator-modal {
    .ui-modal--container {
        background-color: var(--bg-01);
        width: 620px;
        padding: 24px;
    }

    .edit-header {
        display: flex;
        align-items: center;

        h2 {
            font: var(--font-demi-20);
            color: var(--black-90);
            flex: 1;
            line-height: 1;
        }

        i {
            height: 12px;
            width: 12px;
            position: relative;
            display: block;
            cursor: pointer;

            svg {
                height: 12px;
                width: 12px;
                fill: var(--black-50);
            }
        }
    }

    .user-info {
        display: flex;
        margin: 40px 0;
        align-items: center;

        figure {
            height: 68px;
            width: 68px;

            img {
                height: 100%;
                width: 100%;
                display: block;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid var(--black-50);
            }

            &+div {
                padding: 0 24px;
                font: var(--font-fg-12);
                color: var(--black-90);
                min-width: 132px;

                &::before {
                    display: block;
                    content: 'Motom Status';
                    color: var(--black-60);
                    margin-bottom: 10px;
                    font-weight: 500;
                }

                &+div {
                    flex: 1;
                    font: var(--font-fg-12);


                    p {
                        display: flex;
                        align-items: center;

                        .ui-switch {
                            margin-left: 6px;
                        }

                        &.disabled {
                            color: var(--gray-blue);
                        }

                        &.active,
                        &.active {
                            color: var(--green);
                        }

                        &.not-available {
                            color: var(--black-40);
                            pointer-events: none;
                        }
                    }

                    &::before {
                        display: block;
                        content: 'Storefront';
                        color: var(--black-60);
                        margin-bottom: 10px;
                        font-weight: 500;
                    }
                }
            }


        }


        .link {
            a {
                display: flex;
                align-items: center;

                svg {
                    height: 18px;
                    width: 18px;
                    display: block;
                    margin-left: 10px;
                    fill: var(--blue);
                }

                &:hover svg {
                    fill: var(--dark-blue);

                }
            }
        }
    }

    .user-form-wrap {
        .name-box {
            display: flex;

            .name-item {
                width: calc((100% - 12px) / 2);

                &:first-child {
                    margin-right: 12px;
                }
            }
        }

        .w-100-p-check{
            width: 100%;
        }

        .label {
            font: var(--font-fg-16);
            color: var(--black-90);

            &:after {
                content: ' *';
                color: var(--alert);
            }
        }

        .desc {
            font-size: 14px;
            color: var(--black-75);
        }

        .ico-social {
            margin: 12px 0;
            display: flex;
            align-items: center;
            font: var(--fond-med-14);
            color: var(--black-75);

            &>span {
                margin-left: 8px;
                cursor: pointer;
            }

            svg {
                height: 14px;
                width: 14px;
                margin-right: 5px;
                display: block;
            }
        }

        .alert-text {
            margin: 12px 0 0;
            padding: 8px;
            font-size: 12px;
            color: var(--black-90);
            background-color: var(--bg-07);
        }

        .ui-input {
            margin: 12px 0;
        }

        .ui-input--icon-wrap .befter-icon {
            margin-left: 10px;
        }

        .ui-input--wrap input,
        .ui-input--icon-wrap {
            border-radius: 0;
        }

        .ui-input--icon-wrap input,
        .ui-input--wrap input {
            padding: 10px;
        }

        .ui-input--icon-wrap .befter-text+input {
            padding-left: 0;
        }

        .btn-wrap {
            display: flex;
            justify-content: flex-end;

            .ui-button {
                width: 110px;
                text-align: center;
            }
        }

        &.is-error {

            .ui-input .ui-input--wrap select,
            .ui-input .ui-input--wrap input,
            .ui-input .ui-input--select select,
            .ui-input .ui-input--select input,
            .ui-input .ui-input--icon-wrap {
                border-color: var(--alert) !important;
            }

            .error-msg {
                margin: 4px 0 0;
                font-size: 12px;
                color: var(--alert);

                &::before {
                    content: '*';
                }
            }
        }

        .coll-chec{
            margin-top: 16px;

            .ui-checkbox{
                margin-top: 8px;
            }
        }
        
        .tag-line {
            margin-top: 8px;
            display: flex;
            align-items: center;

            dt {
                font: var(--font-med-14);
                color: var(--black-90);
            }

            dd {
                display: flex;
                align-items: center;
                margin-left: 12px;


                span {
                    font-size: 12px;
                    color: var(--black-90);
                    background-color: var(--bg-10);
                    padding: 4px 8px;
                    border-radius: 4px;
                }

                svg {
                    height: 12px;
                    width: 12px;
                    fill: var(--blue);
                    margin-left: 6px;
                
                }
            }
        }
    }

    &.is-loading {

        .user-info,
        .user-form-wrap {
            opacity: .5;
            pointer-events: none;
        }
    }


}</style> 

