<template>
    <div :class="classnames">

        <div class="show-card">
            <div class="icon">
                <SvgIcon :name="data.social_platform === 'tikTok' ? 'tiktok-pure' : 'instagram'" />
            </div>
            <img :src="data.thumbnail_url" @error="imageError($event)" alt="avatar" />

            <div class="card">
                <div class="it date">
                    Posted {{ $filters.momentFormat(data.social_post_created_at, 'll') }}
                    <!-- <span v-if="data.is_public">Live</span> -->
                </div>
                <div :class="['it content', !show_conten && 'content-overflow']">
                    {{ data.content }}
                </div>

                <div class="it see-more" @click="show_conten ? false : true">{{ show_conten ? 'See more' : 'Hide' }}
                </div>

                <Button class="v-org-btn" :style="'border'" size="24" :color="'black-90'" @click="viewOrgPost(data)">
                    View original post
                </Button>


                <div class="it hr" v-if="hasText || mentionText"></div>

                <div class="it mention" v-if="hasText">
                    Keyword mention
                    <div class="men-box">
                        {{ hasText }}
                    </div>
                </div>

                <div class="it mention" v-if="mentionText">
                    @mention
                    <div class="men-box">
                        {{ mentionText }}
                    </div>
                </div>

                <div class="it hr"></div>
                <div class="it mention">
                    Content Tag

                    <div class="tagging-list-box ">

                        <TagDropdown class="tag-drop tag-drop-samll" v-if="!add_value.loading" :items="tag_select"
                            :icon_direction_type="'center'" :direction_type="'left'" @update-select="updateTag"
                            :icon="'plus'">

                            <div class="tag-button">
                                <Button :style="'border'" size="24" :color="'black-90'" @click="editTag"> Edit Tags
                                    <svg-icon name="edit" />
                                </Button>
                            </div>
                        </TagDropdown>

                        <swiper class="it swiper-box label-box" :modules="modules" :slides-per-view="'auto'"
                            :slides-per-group="3" :navigation="{}">
                            <swiper-slide v-for="(v, k) in data.hashtags" :key="k">
                                <Badage :value="v.text" :type="'gray'" />
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>

                <div class="it hr"></div>
                <div class="it post-status">
                    <div>Post status</div>
                    <span :class="['live', !data.is_public && 'live-red']">{{ data.is_public ? 'Live' : 'Not Live'
                        }}</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Badage from '@/components/ui/Badage.vue';
import Button from '@/components/ui/Button.vue';
import { ImageError } from '@/utils/SwitchFun';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import TagDropdown from '@/components/ui/TagDropdown.vue';

export default {
    name: 'creator-big-card',

    data() {
        return {
            add_value: {
                loading: false,
            },
            show_conten: false,
        };
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },

    components: {
        SvgIcon,
        Badage,
        Button,
        Swiper,
        SwiperSlide,
        TagDropdown,
    },

    computed: {
        classnames() {
            return `creator-small-card ${this.classname}`
        },
        icon_list() {
            let list = [
                { icon: 'card-play', value: this.data.views },
                { icon: 'card-like', value: this.data.likes },
                { icon: 'card-repeat', value: this.data.comments },
                { icon: 'card-share', value: this.data.shares },
                { icon: 'card-mark', value: this.data.saved },
            ]
            if (this.data.social_platform === 'tikTok') {
                return list.filter((e, k) => { return k !== 4 })
            } else {
                return list.filter((e, k) => { return k !== 3 })
            }
        },

        hasText() {
            let text = '';
            this.data.hashtags.forEach(e => {
                text += `#${e.text} `
            })
            return text;
        },

        mentionText() {
            let text = '';
            this.data.mentions.forEach(e => {
                text += `@${e.text} `
            })
            return text;
        }
    },

    props: {
        classname: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: {}
        },

        tag_select: {
            type: Array,
            default: {}
        }

    },
    methods: {
        imageError: ImageError,

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        updateTag(arr) {
            arr.forEach(tag => {
                // 检查是否存在相同 text 的 mention 对象
                const existingMentionIndex = this.data.mentions.findIndex(mention => mention.text === tag.text);

                if (existingMentionIndex !== -1) {
                    // 如果存在相同 text 的 mention 对象
                    if (!tag.click) {
                        this.data.mentions.splice(existingMentionIndex, 1);
                    }
                } else {
                    // 如果不存在相同 text 的 mention 对象
                    if (tag.click) {
                        // 如果 click 为 true，将该对象添加到 this.data.mentions
                        this.data.mentions.push({ text: tag.text });
                    }
                }
            });
        },

        editTag(obj) {
            this.$emit('edit-tag', obj);
        },

        viewOrgPost(post) {

        }
    },
    mounted() {

    }
};
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.creator-small-card {
    position: relative;
    border-radius: 4px;
    border: solid 1px var(--border);
    overflow: hidden;
    cursor: pointer;
    background-color: white;

    .icon {
        position: absolute;
        border-radius: 0 0 4px 0;
        background-color: var(--bg-01);
        width: 18px;
        height: 18px;
        @include flexCenter;
        justify-content: center;

        .svg-icon {
            width: 10px;
            height: 10px;
        }
    }

    img {
        width: 100%;
        height: 229px;
        object-fit: cover;
    }

    .card {
        padding: 6px;
        color: var(--black-90);

        .it {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: unset;
            }
        }
    }

    .name-box {
        @include flexCenter();
        font: var(--font-med-14);

        .avatar {
            margin-right: 4px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .content {
        font: var(--font-med-12);
        margin: 4px 0;

        &.content-overflow {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }


    .date,
    .mention,
    .post-status {
        color: var(--black-50);
        font-size: 10px;
        @include flexCenter();
        justify-content: space-between;

        span {
            color: var(--green);

            &.live-red {
                color: var(--red);
            }
        }

        .men-box {
            font: var(--font-med-14);
            color: var(--black-90);
            width: 100%;
        }
    }

    .mention{
        display: flex;
        flex-wrap: wrap;
    }

    .post-status {
        @include flexCenter();
        justify-content: space-between;
    }

    .see-more {
        color: var(--blue);
        font: var(--font-med-12);
    }

    .v-org-btn {
        border-radius: 6px !important;
        color: var(--dark-gray-blue) !important;
        border: solid 1px var(--dark-gray-blue);
        margin-bottom: 4px;
    }

    .brand {
        color: var(--black-50);
        font: var(--font-med-12);
    }

    .hr {
        background-color: var(--black-50);
        height: 1px;
        width: 100%;
        border: 0;
    }


    .icon-box {
        @include flexCenter();
        align-items: center;

        .icon-item {
            @include flexCenter();
            margin-right: 12px;
            font: var(--font-med-10);

            .svg-icon {
                width: 10px;
                height: 10px;
                margin-right: 2px;
                fill: var(--black-75);
            }
        }

    }

    .col {
        @include flexCenter();
        justify-content: flex-end;

        span {
            height: 24px;
            width: 24px;
            cursor: pointer;
            @include flexCenter();
            justify-content: center;
            position: relative;
            margin-left: 10px;
            border-radius: 2px;
            transition: all .2s ease-in-out;
            color: var(--black-90);

            &:hover {
                background-color: var(--bg-03);
            }

            svg {
                height: 10px;
                width: 10px;
                position: relative;
                transform: rotate(90deg);
            }

            &.swiper-button-next {
                svg {
                    transform: rotate(-90deg);
                }
            }

            &.swiper-button-disabled {
                cursor: not-allowed;
                color: var(--black-40);
                pointer-events: none;
            }
        }
    }

    .label-box {
        //height: 76px;

        .swiper-slide {

            width: auto !important;
            min-width: 26px;
            margin-right: 8px;
            overflow: hidden;
            box-sizing: content-box;
        }

        .my-badges-iden {
            margin-right: 4px;
            margin-bottom: 2px;
        }
    }

    .tag-drop-samll .ui-button {
        padding: 0 5px;
        height: 24px;
        border-radius: 6px;
        margin-right: 4px;
        border: solid 1px var(--border);
    }

    .ui-dropdown--inner .item-box {
        bottom: 26px;
    }

    .tagging-list-box {
        @include flexCenter();
    }

}
</style>
