<template>
    <div class="ui-pagenavi">
        <div class="bar" :class="{'disabled': disabled}" @mouseleave="is_show_menu = false">
            <i class="prev" :class="{'disabled': page_current === 1}" @click="gotoPre"><SvgIcon name="arrow" /></i>
            <input class="current" :value="page_current" @mouseover="is_show_menu = (disabled) ? false : true"/>
            <i class="next" :class="{'disabled': page_current === page_total}" @click="gotoNext"><SvgIcon name="arrow" /></i>
            <ul v-if="is_show_menu">
                <li v-for="i in page_total" :class="{'disabled': i === page_current}" @click="gotoPage(i)">{{ i }}</li>
            </ul>
        </div>

    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'ui-pagenavi',

    data() {
        return {
            is_show_menu: false,
        }
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        page_total: {
            type: Number,
            default: 0,
        },
        page_current: {
            type: Number,
            default: 0,
        },
    },

    setup(props, { slots }) {
        const hasSlot = name => !!slots[name]
        return { hasSlot }
    },

    components: {
        SvgIcon,
    },

    methods: {
        gotoPage(page) {
            if (page !== this.page_current)
                this.$emit('goto-page', page);
        },

        gotoPre() {
            if (this.page_current > 1)
                this.$emit('goto-page', this.page_current-1);
        },

        gotoNext() {
            if (this.page_current < this.page_total)
                this.$emit('goto-page', this.page_current+1);
        },
    },
};
</script>

<style lang="scss">
    .ui-pagenavi {
        .bar {
            display: flex;
            align-items: center;
            height: 32px;
            border: 1px solid var(--black-10);
            background-color: var(--black-10);
            border-radius: 6px;
            position: relative;

            &.disabled {
                border: 1px solid var(--black-10);
                cursor: not-allowed;

                i, .current {
                    color: var(--black-40);
                    cursor: not-allowed;

                    &:hover svg {
                        fill: var(--black-40);
                    }
                }
            }

            .current {
                text-align: center;
                width: 24px;
                height: 20px;
                border: none;
                background-color: transparent;

                &:focus {
                    outline: none;
                }
            }

            i {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    position: relative;
                }

                &.next {
                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &.prev {
                    svg {
                        transform: rotate(90deg);
                    }
                }

                &:hover svg {
                    fill: var(--blue);
                }

                &.disabled {
                    cursor: not-allowed;

                    svg {
                        fill: var(--black-40);
                    }

                    &:hover svg {
                        fill: var(--black-40);
                    }
                }
            }

            svg {
                height: 8px;
                width: 8px;
            }
        }

        ul {
            position: absolute;
            top: 100%;
            left: 16px;
            right: 16px;
            background-color: white;
            padding: 10px 0;
            border-radius: 6px;
            z-index: 9;
            overflow: hidden;
            box-shadow: var(--bot-2dp);

            li {
                text-align: center;
                padding: 1px 0;
                cursor: pointer;

                &:hover {
                    background-color: var(--bg-02);
                }

                &.disabled {
                    color: var(--black-40);
                    cursor: not-allowed;
                }
            }
        }
    }
</style>