<template>
	<form @submit.prevent="submit">
		<slot :errors="errors" :success="success"></slot>
	</form>
</template>

<script>
	import { isEqual } from 'lodash';

	export default {
        name: 'Form',
		data() {
			return {
				errors: {},
				success: {},
				oldValues: { ...this.values },
			};
		},

		emits: [
			'submit',
			'get-err'
		],
        
		props: {
			schema: {
				required: true,
				type: Object,
			},

			values: {
				required: true,
				type: Object,
			},

			validFieldOnChange: {
				required: false,
				type: Boolean,
				default: false,
			},

			need_error:{
				type:Boolean,
				default:false
			},

			is_disabled: {
				type: Boolean,
				default: false
			},
		},

		methods: {
			validate(field) {
				
				return this.schema
					.validateAt(field, this.values)
					.then(() => {
						this.errors[field] = '';
					})
					.catch((err) => {
						this.errors[field] = err.message;
					});
			},

			validateForm() {
				
				this.errors = {};
				return this.schema
					.validate(this.values, { abortEarly: false })
                        .then(() => {
							Object.keys(this.values).forEach(key => {
								this.success[key] = true;
							});

                            return true;
                        })
                        .catch((err) => {
							console.log(err.inner)
                            err.inner.forEach((error) => {
                                this.errors[error.path] = error.message;
                            });
							
							Object.keys(this.values).forEach(key => {
								this.success[key] = !(this.errors[key]);
							});

							this.$emit('get-err', this.errors);

                            return false;
                        });
			},

			init() {
				this.errors = {};
				Object.keys(this.values).forEach(key => {
					this.success[key] = false;
					this.errors[key] = false;
				});

				return this.schema
					.validate(this.values, { abortEarly: false })
                        .then(() => {

                            return true;
                        })
                        .catch((err) => {
							console.log(err.inner)
                            err.inner.forEach((error) => {
                                this.errors[error.path] = error.message;
                            });

                            return false;
                        });
			},

			async submit() {
				if (this.is_disabled) return;

				else if (await this.validateForm()) {
					this.$emit('submit', this.values);
				} else if (this.need_error){
					this.$emit('submit', false);
				}
			},
		},

		watch: {
			values: {
				handler() {
					if (!this.validFieldOnChange) {
						return;
					}

					for (const propriety of Object.keys(this.values)) {
						if (!isEqual(this.oldValues[propriety], this.values[propriety])) {
							this.validate(propriety);
						}
					}
                    
					this.oldValues = { ...this.values };
				},
				deep: true,
			},
		},
	};
</script>

<style lang="scss">
    .form-wrap {
        margin-left: auto;
		margin-right: auto;
		width: 422px;
    }
</style>