<template>
    <section class="storefront-summary-line">
        <slot name="head"></slot>
        <div class="chart-box">
            <div class="type-box">
                <div class="type" v-for="(title, k) in chart_head" :key="k" v-show="title.show">
                    <div class="value">{{ is_empty ? '---' : title.value }}</div>
                    <div class="text">{{ title.name }}
                        <tippy :delay="[0, 20]" placement="right" trigger="mouseenter click" v-if="title.content">
                            <template v-slot:content>
                                <div v-html="title.content"></div>
                            </template>
                            <svg-icon name="question-3" />
                        </tippy>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import * as echarts from 'echarts';
import NoData from '@/components/creator/NoData.vue';
import Loading from '../ui/Loading.vue';

export default {
    name: 'StorefrontSummary',

    components: {
        SvgIcon,
        NoData,
        echarts,
        Loading,
    },

    data() {
        return {
            option: null,
            chart: null,
            is_loading_data: false,
            data: false,
            chart_type: 'view',
            start_date: moment().add(-30, 'days').toDate(),
            end_date: moment().add(-1, 'days').toDate(),
            chart_title_name: [
                { icon: 'instagram' },
                { icon: 'tiktok-pure' }
            ]
        }
    },

    props: {
        chart_head: {
            type: Array,
            default: [],
        },
        is_empty: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
    },

    mounted() {

    },

    created() {

    },

    destroyed() {

    },
}
</script>
<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.storefront-summary-line {
    margin-bottom: 24px;

    header {
        display: block !important;
        margin-bottom: 8px;

        h3 {
            display: flex;
            align-items: center;
            font: var(--font-med-20);
            color: var(--black-90);

            svg {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }

        p {
            margin: 2px 0;

            strong {
                font: var(--font-med-16);
                color: var(--black-90);
            }

            span {
                font-size: 12px;
                color: var(--black-75);
            }
        }
    }

    .chart-box {
        background: var(--bg-02);
        overflow: hidden;

        .creator-no-data img {
            width: 120px;
        }

        .type-box {
            display: flex;
            cursor: pointer;

            .type {
                width: 33.4%;
                padding: 16px 12px;
                background: white;
                border: 1px solid var(--border);
                border-left: none;

                &:first-child {
                    border-left: 1px solid var(--border);
                }

                &.selected {
                    background: var(--bg-02);
                    border: unset;
                }

                .text {
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--black-75);
                    @include flexCenter();

                    .svg-icon {
                        cursor: pointer;
                        width: 13px;
                        height: 13px;
                        fill: var(--black-40);
                        margin-left: 4px;
                    }
                }

                .value {
                    font: var(--font-med-20);
                    line-height: 32px;
                    color: var(--primary);
                    display: flex;
                    align-items: center;

                    svg {
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        fill: var(--green);

                        &.down {
                            fill: var(--red);
                            transform: rotate(90deg)
                        }
                    }
                }
            }
        }

        .chart {
            width: auto;
            height: 300px;
            padding-bottom: 60px;
        }

        .type-title-name {
            @include flexCenter;
            margin-left: 32px;
            margin-top: -50px;
            margin-bottom: 12px;

            .name {
                @include flexCenter;
                margin-right: 16px;

                .svg-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;
                    color: var(--black-90);
                }

                .box {
                    width: 32px;
                    height: 4px;
                    background-color: #C32AA3;

                    &.box-tiktok-pure {
                        background-color: var(--black-90);
                    }
                }
            }
        }

    }

}
</style>