<template>
    <div class="creator-no-data">
        <img :src="image" />
        <h3 v-html="title" @click="$emit('text-function')"></h3>
        <p v-if="text !== ''" v-html="text" @click="$emit('title-function')"></p>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'NoData',
    props: {
        title: {
            type: String,
            default: 'No data available',//'No new data available',
        },
        text: {
            type: String,
            default: '',//'Looks like all creators already added and created a storefront',
        },
        image: {
            type: String,
            default: require('@/assets/images/no-data.png'),
        },
    },
    computed: {
    }
}
</script>
<style lang="scss">
.creator-no-data {
    margin: auto;
    text-align: center;
    padding: 57px 0;

    img {
        width: 130px;
        display: block;
        margin: 0 auto 16px;
    }

    h3 {
        color: var(--black-90);
        margin: 16px 0;
        font: var(--font-demi-14);

    }

    p {
        font: var(--font-fg-12);
        color: var(--black-75);
    }

    .ui-button {
        margin-top: 16px;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 8px 55px;
        margin: auto;

        svg {
            height: 16px;
            width: 16px;
            margin-right: 4px;
            top: 3px;
        }
    }


    .link {
        color: var(--blue);
        cursor: pointer;
    }

    .have-problem{
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
