import { createStore } from 'vuex';

export default createStore({
    state: {
        user_data: false,
        //$store.state.user_data.is_motom_internal_user(內部人員才看的到的值)
        disabled_scroll: false,
        upload_data: false,
        is_show_share: false,
        import_count: 0,
        department_select: [],
        role_select: [],    
        // role_id === 1 => admin 
        // role_id === 2 => manager 
        // role_id === 3 => analyst
        role_id: null,
        current_creator_count: 0,
        creator_max_count: 0,
        current_portal_user_count: 0,
        portal_user_max_count: 0,
        is_loading: false,
        mail_to:'anchor.support@motom.me',
        pick_creator_user_id:'',
        creator_data:null,
        brand_posts_key:0,
        program_data:{
            form_ForCreatorList:[],
            create_data:null,
            is_preview:false,
        },
    },
    // getters: {
    // },
    mutations: {
        async check_limit(state, self) {
            state.is_loading = true;
            const res = await self.$ajax.get(`/settings/merchant-account-current-use-rate?t=${new Date().getTime()}`);
            const res_data = res.data.data;
            state.creator_max_count = res_data.creator_max_count;
            state.current_creator_count = res_data.current_creator_count;
            state.portal_user_max_count = res_data.portal_user_max_count;
            state.current_portal_user_count = res_data.current_portal_user_count;
            state.is_loading = false;
           
        },
    },
    // actions: {
    // },
    // modules: {
    // }
})
