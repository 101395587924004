<template>
    <div class="ui-input"
        :class="['ui-input--' + type, { 'is-error': error_msg }, { 'is-error-important': handle_err }, { 'is-success': is_success || !handle_success }, { 'is-ready-only': ready_only }, { 'is-disabled': is_disabled }]" :key="all_key">
        <label v-if="label" class="label-text" v-html="label" :for="k"></label>

        <div class="ui-input--select the-input" v-if="type === 'select'">
            <select :name="k" @change="onChange();" ref="select">
                <option v-if="placeholder" selected disabled>{{ placeholder }}</option>
                <template v-for="(item, key) in select_data" :value="item" :key="key">
                    <option v-if="(typeof item === 'string')" :selected="selected === key" :vlaue="item">{{ item }}
                    </option>
                    <option v-else :selected="selected === key" :vlaue="item.id">{{ item.name }}</option>
                </template>
            </select>
            <SvgIcon name="arrow" />
        </div>
        <!-- dropdown (Ui比較好看一點)-->
        <div class="ui-input--select the-input" v-if="type === 'dropdown'">
            <Dropdown :items="select_data" :direction_type="'right'" :icon_direction_type="'right'"
                :default_value="dropdow_defalut.default_value" :default_id="dropdow_defalut.default_id"
                :default_text="'---Select---'" @update-dropdown="updateDropdown" :disabled="is_disabled" />
        </div>

        <label :for="k" class="ui-input--icon-wrap the-input" :class="{ 'focus': is_focus }" v-else-if="type === 'icon'"
            @click="focus">
            <span v-if="befter_icon" class="befter-icon">
                <SvgIcon :name="befter_icon" />
            </span>
            <span v-if="befter_text" class="befter-text" v-html="befter_text"></span>
            <input :type="input_type" :name="k" v-model="value" :placeholder="placeholder" @input="onChange();"
                @change="onChange();" @focus="onChange('focus')" @blur="onChange('blur')" ref="input" />
            <span v-if="after_icon" class="after-icon">
                <SvgIcon :name="after_icon" />
            </span>
            <span v-if="after_text" class="after-text" v-html="after_text"></span>

        </label>

        <!-- date -->
        <div class="ui-input--date the-input" v-else-if="type === 'date'">
            <label :class="[!date_value.value && 'plac-text']">
                <time>
                    {{ date_value.show_value ? date_value.show_value : date_format }}
                    <DatePickerNext :key="date_value.key" :value="date_value.value" type="date" :format="date_format"
                        :default-value="default_date" :disabled="is_disabled" :disabled-date="cantPickDate"
                        @change="calendarChange" :popup-class="'mx-datepicker-main-spec'" />
                </time>
            </label>
        </div>

        <!-- multi_select -->
        <div class="ui-input--wrap cursor the-input" v-else-if="type === 'multi_select'">
            <div v-for="(option, index) in select_data" :key="index" class="checkbox-option"
                @click="updateMultipleSelect(option)">
                <Checkbox :checked="option.is_checked" :value="option.id" :disabled="is_disabled" />{{ option.name }}
            </div>
        </div>

        <!-- single_select -->
        <div class="ui-input--wrap cursor the-input" v-else-if="type === 'single_select'">
            <div v-for="(option, index) in select_data" :key="index" class="radio-option"
                @click="updateSingleSelect(option)">
                <Checkbox :checked="value === option.name" :value="option.name" type="radio" :disabled="is_disabled" />{{
            option.name }}
            </div>
        </div>

        <!-- textarea -->
        <div class="ui-input--wrap the-input" v-else-if="type === 'textarea'">
            <textarea rows="5" cols="33" v-model="value" :readonly="is_disabled" :name="k" :placeholder="placeholder"
                @input="onChange();" @change="onChange();" @focus="onChange('focus')" @blur="onChange('blur')"
                ref="input" />
            <div class="char-count" v-if="max_value" :key="key_ref">{{ value.length }}/{{ max_value }}</div>
        </div>

        <!-- input -->
        <div class="ui-input--wrap the-input" v-else>
            <input :type="input_type" :readonly="is_disabled" :name="k" v-model="value" :placeholder="placeholder"
                @input="onChange();" @change="onChange();" @focus="onChange('focus')" @blur="onChange('blur')"
                ref="input" />
            <i v-if="type === 'password'" @click="switchType">
                <SvgIcon v-if="!is_show_password" name="eye-hidden" />
                <SvgIcon v-else name="eye" />
            </i>
            <i v-if="is_clear" class="clear" @click="onChange(false, true)">
                <SvgIcon name="close-circle-2" />
            </i>
        </div>

        <div class="err-msg-box" v-if="error_msg && !no_show_err_msg">
            <p class="err-msg" v-html="error_msg">
            </p>
            <i>
                <SvgIcon name="alert" class="icon-left" />
            </i>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import DatePickerNext from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import moment from 'moment';
import Checkbox from '@/components/ui/Checkbox.vue';
import Dropdown from '@/components/ui/Dropdown.vue';

export default {
    name: 'ui-checkbox',
    emits: ['update', 'blur', 'focus'],

    props: {
        type: {
            type: String,
            default: 'text',
        },

        k: {
            type: [String, Number],
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        default_value: {
            type: String,
            default: '',
        },

        error_msg: {
            type: [String, Boolean],
            default: '',
        },

        no_show_err_msg: {
            type: Boolean,
            default: false,
        },

        handle_err: {
            type: Boolean,
            default: false,
        },

        handle_success: {
            type: Boolean,
            default: true,
        },

        ready_only: {
            type: Boolean,
            default: false,
        },

        is_success: {
            type: Boolean,
            default: false,
        },

        select_data: {
            type: Array,
            default: [],
        },

        date_format: {
            type: String,
            default: "MM/DD/YYYY",
        },

        max_date: {
            type: [String, Date],
            default: "",
        },
        min_date: {
            type: String,
            default: "",
        },
        default_date: {
            type: [Date, String],
            default: ''
        },

        befter_text: {
            type: String,
            default: '',
        },

        befter_icon: {
            type: String,
            default: '',
        },

        after_icon: {
            type: String,
            default: '',
        },

        after_text: {
            type: String,
            default: '',
        },

        is_clear: {
            type: Boolean,
            default: false,
        },

        is_disabled: {
            type: Boolean,
            default: false,
        },

        spec: {
            type: [String, Number],
            default: '',
        },
        had_value: {
            type: [String, Number],
            default: '',
        },
        max_value: {
            type: [String, Number],
            default: 0
        },
        dropdown_use: {
            type: String,
            default: 'index',//dropdown預設進來的值看是哪一種,分index/id/text
        }
    },

    components: {
        SvgIcon,
        DatePickerNext,
        Checkbox,
        Dropdown,
    },

    computed: {

    },

    watch:{
        is_success(val){
            console.log(val)
        }
    },

    data() {
        return {
            input_type: 'text',
            is_show_password: false,
            value: '',
            is_focus: false,
            date_value: {
                key: 0,
                value: '',
                show_value: '',
                maxDate: null,
                minDate: null,
            },
            dropdow_defalut: {
                default_id: null,
                default_value: this.value
            },
            key_ref: 0,
            all_key:0,
        }
    },

    methods: {
        switchType() {
            this.is_show_password = !this.is_show_password;

            if (this.is_show_password) {
                this.input_type = 'text';
            } else {
                this.input_type = 'password';
            }
        },

        onChange(type = false, clear) {
            const obj = {};
            obj.key = this.k;
            if (this.max_value && this.$refs.input && this.$refs.input.value.length > this.max_value) {
                this.$refs.input.value = this.$refs.input.value.slice(0, this.max_value);
                this.value = this.$refs.input.value.slice(0, this.max_value);
                this.key_ref += 1;
            }
            if (this.$refs.input) obj.el = this.$refs.input;


            if (this.type === 'select') {
                obj.val = this.$refs.select.value;

            } else if (this.type === 'single_select') {
                obj.val = this.value;

            } else if (this.type === 'multi_select') {
                obj.val = this.select_data.filter(e=>{return e.is_checked}).map(e=>{return e.name});

            } else if (clear) {
                this.value = '';
                obj.val = '';

            } else {
                obj.val = this.value;
            }

            if (type === 'focus') {
                this.is_focus = true;
                this.$emit('focus', obj);
            } else if (type === 'blur') {
                this.is_focus = false;
                this.$emit('blur', obj);
            }

            if (this.spec) {
                obj.spec = this.spec;
            }

            this.$emit('update', obj);
        },

        updateDropdown(val) {
            const obj = {};
            obj.key = this.k;
            obj.val = val.text;
            obj.all_vlaue = val;
            if (this.spec) {
                obj.spec = this.spec;
            }

            this.$emit('update', obj);
        },

        focus() {
            this.$refs.input.focus();
        },

        sync() {
            this.value = this.default_value;
        },

        //+++++++++++++++++++++++
        //Single MultipleSelect Handle
        //++++++++++++++++++++++
        updateSingleSelect(val) {
            if (!this.is_disabled) {
                this.value = this.value === val.name ? '': val.name;
                this.onChange();
            }
        },
        updateMultipleSelect(option) {
            if (!this.is_disabled) {
                option.is_checked = !option.is_checked;
                this.onChange();
            }
        },

        //++++++++++++++++++++++++++++++++++++++
        //for pickdate
        //++++++++++++++++++++++++++++++++++++++
        cantPickDate(date) {
            if (this.max_date) {
                this.date_value.maxDate = this.max_date;
            }
            if (this.min_date) {
                this.date_value.minDate = this.min_date;
            }
            let maxDate = this.date_value.maxDate ? new Date(this.date_value.maxDate) : null;
            let minDate = this.date_value.minDate ? new Date(new Date(this.date_value.minDate).getTime() - 86400000) : null;

            if (maxDate && minDate) {
                // 同時檢查最大時間和最小時間
                return date < minDate || date > maxDate;
            } else if (maxDate) {
                // 只有最大時間限制
                return date > maxDate;
            } else if (minDate) {
                // 只有最小時間限制
                return date < minDate;
            } else {
                // 沒有時間限制
                return false;
            }
        },

        calendarChange(date) {
            const obj = {};
            this.date_value.value = date;
            this.date_value.show_value = moment(this.date_value.value).format(this.date_format);
            obj.key = this.k;
            obj.val = this.date_value.show_value

            if (this.spec) {
                obj.spec = this.spec;
            }
            this.$emit('update', obj);
        },

        //handle dropdown
        handleDropDefault() {
            if (this.dropdown_use === 'text') {
                this.dropdow_defalut.default_id = null;
                this.dropdow_defalut.default_value = this.value ? this.select_data.findIndex(item => item.text === this.value) : -100;
            } else if (this.dropdown_use === 'id') {
                this.dropdow_defalut.default_id = this.value;
                this.dropdow_defalut.default_value = 0;

            } else {
                this.dropdow_defalut.default_id = null;
                this.dropdow_defalut.default_value = this.value;
            };
            this.all_key+=1;
        }
    },

    mounted() {
        this.input_type = this.type;
        if (this.had_value) {
            this.value = this.had_value;
            if (this.type === 'date') {
                this.date_value.value = moment(this.had_value).toDate();
                this.date_value.show_value= moment(this.had_value).format(this.date_format);
                this.all_key+=1;
            }
        }
        if(this.type==='dropdown'){
            this.handleDropDefault();
        }
    },
}

</script>

<style lang="scss">
.ui-input {
    margin: 16px 0;

    &>.label-text {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        color: var(--black-75);
    }

    &--wrap {
        position: relative;

        i {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -10px;
            cursor: pointer;

            svg {
                height: 100%;
                width: 100%;
                display: block;
                fill: var(--black-50);
            }

            // &:hover svg {
            //     fill: var(--black-50);
            // }
        }


        input,
        textarea {
            padding: 6px 8px;
            height: 36px;
            border: 1px solid var(--black-20);
            background-color: white;
            width: 100%;
            border-radius: 6px;
            color: var(--black-90);
            transition: .15s border-color ease;
            font-weight: 500;
            font-family: var(--font);

            &:hover {
                border-color: var(--black-50);
            }

            &:focus {
                outline: none;
                border-color: var(--dark-blue);
            }

            &::placeholder {
                color: var(--black-50);
                font-weight: 500;
            }
        }

        textarea {
            height: 120px;
            resize: none;
            overflow: auto;
        }

        .char-count {
            position: absolute;
            right: 10px;
            bottom: 10px;
            padding: 2px 5px;
            border-radius: 3px;
            color: var(--black-50);
            font-weight: 500;
        }

    }

    &--icon-wrap {
        position: relative;
        border: 1px solid var(--black-20);
        background-color: white;
        width: 100%;
        border-radius: 6px;
        color: var(--black-90);
        transition: .15s border-color ease;
        display: flex;
        align-items: center;
        overflow: hidden;

        &:hover {
            border-color: var(--black-50);
        }

        &.focus {
            border-color: var(--dark-blue);
        }

        span {
            cursor: text;
        }

        .after-icon,
        .befter-icon {
            position: relative;
            width: 18px;
            margin: 0 0 0 15px;

            svg {
                position: absolute;
                left: 0;
                top: 50%;
                height: 18px;
                width: 18px;
                margin-top: -9px;
            }
        }

        .after-icon {
            margin: 0 15px 0 0;
        }

        .befter-text {
            padding-left: 6px;

            &+input {
                padding-left: 0px;
            }
        }

        input {
            padding: 15px 12px;
            flex: 1;
            border: none;
            background-color: transparent;
            font-weight: 500;
            font-family: var(--font);

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: var(--black-50);
                font-weight: 500;
            }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"] {
            -moz-appearance: textfield;
            /* Firefox 隐藏上下键选择控件 */
        }

    }

    &--select {
        position: relative;

        select {
            padding: 15px 12px;
            border-radius: 6px;
            border: 1px solid var(--black-20);
            color: var(--black-90);
            transition: .15s border-color ease;
            font-weight: 500;
            font-family: var(--font);
            cursor: pointer;
            background: white;
            width: 100%;
            appearance: none;

            option {
                font-weight: 500;
                cursor: pointer;
                color: var(--black-90);
                background: white;
                padding: 9px 10px;
                cursor: pointer;

                &:disabled {
                    color: var(--black-50);
                    display: none;
                }
            }

            &:hover {
                border-color: var(--black-50);
            }

            &:focus {
                outline: none;
                border-color: var(--dark-blue);
            }
        }

        &>svg {
            position: absolute;
            right: 15px;
            height: 12px;
            width: 12px;
            top: 50%;
            margin: -6px 0 0;
            pointer-events: none;
            z-index: 9;
            fill: var(--black-50);
        }

        .ui-button.type-border-square.color-black-60 {
            border-color: var(--border);
            justify-content: space-between;
        }

        ul.item-box {
            width: 100%;

            .scrollbar-content {
                max-height: 40vh;
            }
        }
    }

    //--date
    &--date {
        &>label {
            display: flex;
            align-items: center;
            border: 1px solid var(--border);
            height: 32px;
            border-radius: 6px;
            cursor: pointer;
            background-color: #fff;
            // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

            &:hover {
                border-color: var(--blue);
            }

            &>i {
                height: 14px;
                width: 14px;
                margin: 0 10px;

                svg {
                    height: 100%;
                    width: 100%;
                    fill: var(--black-90);
                }
            }

            .mx-datepicker .mx-icon-calendar {
                display: none !important;
            }

            &>time {
                position: relative;
                color: var(--black-90);
                font-family: var(--font);
                padding: 10px;
            }

            &.plac-text {
                &>time {
                    color: var(--black-50);
                    font-weight: 500;
                }
            }

            .mx-datepicker .mx-input {
                color: var(--black-60);
                font-family: var(--font);
                //position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 0;
                padding: 0;
                opacity: 0;
            }

            .mx-datepicker {
                width: auto;
            }

        }

    }

    //----
    .err-msg-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        i {
            width: 14px;
            height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        svg {
            width: 10.5px;
            height: 10.5px;
            fill: var(--alert);
        }
    }

    .cursor {
        cursor: pointer;
    }

    .err-msg {
        margin: 4px 0;
        font-size: 12px;
        font-weight: 500;
        color: var(--alert);
        text-align: right;
    }

    &.is-error &--wrap,
    &.is-error &--select {

        .ui-button,
        label,
        textarea,
        select,
        input {
            border-color: var(--alert);
        }
    }

    &.is-success &--wrap,
    &.is-success &--select {

        .ui-button,
        label,
        textarea,
        select,
        input {
            border-color: var(--green);
        }
    }

    &.is-error-important {

        label,
        textarea,
        select,
        input {
            border-color: var(--alert) !important;
        }
    }

    &.is-error &--icon-wrap {
        border-color: var(--alert);
    }

    &.is-success &--icon-wrap {
        border-color: var(--green);
    }

    &.is-disabled {

        //cursor: not-allowed;
        .cursor,
        label,
        input,
        textarea,
        select {
            pointer-events: none;
            opacity: .65;
        }

    }

    &.is-ready-only &--icon-wrap,
    &.is-ready-only input {
        border: none !important;
        padding: 0;
        background-color: transparent !important;
    }



    .clear {
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -8px;
        cursor: pointer;

        svg {
            height: 80%;
            width: 80%;
            display: block;
            fill: var(--black-75);
        }
    }

    &.is-error {
        label {
            border-color: var(--alert);
        }
    }

    &.is-success {
        label {
            border-color: var(--green);
        }
    }

}
</style>