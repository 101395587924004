import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
// import { setupCache, buildKeyGenerator } from 'axios-cache-interceptor';
import Notifications from '@kyvg/vue3-notification';
import { plugin as VueTippy } from 'vue-tippy';
import mitt from 'mitt';
import moment from 'moment';
import SlideUpDown from 'vue3-slide-up-down';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';
import JsDownload from 'js-file-download';
import GetValidImageUrl from './utils/GetValidImageUrl';
// import './utils/mockData';
const emitter = mitt();

const VueUploadComponent = require('vue-upload-component');
import 'tippy.js/dist/tippy.css'; // optional for styling
// import 'tippy.js/themes/light.css'; // optional for styling

let ajax_url = '';
let download_url='https://www.portal-demo.motom.me';

if (process.env.VUE_APP_API && process.env.VUE_APP_API === 'local') {
    ajax_url = 'https://dev.brand-portal.motom.com:8443/api/v1';
    download_url = 'https://dev.brand-portal.motom.com:8443';
} else if (process.env.NODE_ENV === 'development') {
    // ajax_url = 'https://www.anchor.dev.motom.me/api/v1';
    ajax_url = 'https://www.anchor.qa.motom.me/api/v1';
    download_url='https://www.portal-demo.motom.me';
} else {
    ajax_url = '/api/v1';
    download_url=`https://${location.host}`;
}


const headers = {};
const access_token = window.localStorage.getItem('access_token');
if (access_token !== null) {
    headers.Authorization = 'Bearer ' + access_token;
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
}

// headers['User-Agent'] = window.navigator.userAgent;
// const ajax = setupCache(Axios, {
//     keyGenerator: buildKeyGenerator((request ) => ({
//         withCredentials: true
//     }))
// });
// ajax.defaults.headers = headers;
// ajax.defaults.withCredentials = true;
// ajax.defaults.baseURL = ajax_url;


const ajax = Axios.create({
    baseURL: ajax_url,
    headers: headers,
    withCredentials: true,
});

ajax.interceptors.response.use((response) => response, (error) => {
    if (error && error.response && error.response.data && (error.response.data.message === 'System Error User is not logged in.' || error.response.data.message === 'AuthenticationException with message Unauthenticated.')) {
        window.localStorage.removeItem('access_token');
        window.location.replace('/login');
    }

    throw error;
});
// const ajax = setup({
//     baseURL: ajax_url,
//     cache: {
//         maxAge: 20 * 60 * 1000, // 20min
//         exclude: {
//             query: false
//         }
//     },

//     withCredentials: true,
//     headers: headers,
// });



const app = createApp(App);
app.use(store);
app.use(router);
app.use(Notifications);
app.use(
    VueTippy,
);

app.component('file-upload', VueUploadComponent);
app.component('slide-up-down', SlideUpDown);
app.component('svg-icon', SvgIcon);
app.component('ui-button', Button);

// app.provide('$ajax', ajax);
app.config.globalProperties.$ajax = ajax;
app.config.globalProperties.EventBus = emitter;
app.config.globalProperties.$filters = {
    momentFormat(date, format) {
        if (date)
            return moment(date).format(format);
        else return '---';
    }
};
app.config.globalProperties.$DownloasUrl = download_url;
app.config.globalProperties.$JsDownload =JsDownload;
app.config.globalProperties.$Get_Image = GetValidImageUrl;
app.mount('#app');

