<template>
    <div class="admin-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Content',

};
</script>

<style lang="scss">
.admin-content {
    flex: 1;
    border-radius: 16px 0 0 0;
    background: white;
    margin-top: 16px;
}
</style>