

<template>
    <div class="report-item-2" v-if="data">
        <div class="info">
            <div class="text">
                <SvgIcon name="top-rank-flag-2" />{{ data.title }}
            </div>
        </div>
        <div class="top">
            <figure class="avatar">
                <img v-if="data.user" :src="data.user.userProfile.avatar_full_url"
                    @error="imageError($event)" />
                <img v-else src="@/assets/images/user.png" />
            </figure>
            <div class="value-box">
                <div class="value">
                    <SvgIcon :name="data.icon" />{{ show_text }}
                </div>
                <div class="name">{{ (data.user) ? data.user.first_name + ' ' +
                    data.user.last_name : '--' }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'TopBox',

    components: {
        SvgIcon
    },

    data() {
        return {
            show_text: '--',
        }
    },

    props:{
        data:{
            type:Object
        }
    },

    methods:{
        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },
    },

    mounted() {
        if (this.data.user && this.data.user.total_count !== 0) {
            if (this.data.icon === 'shopping-cart') {
                this.show_text = '$' + Number(this.data.user.total_count).toFixed(2);
            } else {
                this.show_text = this.data.user.total_count;
            }
        }

    },
}
</script>
<style lang="scss">

</style>