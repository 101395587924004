

<template>
    <Transition name="modal">
        <div v-if="is_show" class="ui-modal--mask">
            <div class="ui-modal--container">
                <slot />
            </div>
            <div class="bg" @click="$emit('click-bg')"></div>
        </div>
    </Transition>
</template>
<script>
export default {
    name: 'Modal',

    props: {
        is_show: {
            default: false,
            type: Boolean,
        }
    }
}
</script>
<style lang="scss">
    .ui-modal--mask {
        position: fixed;
        z-index: 9990;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        transition: opacity 0.3s ease;

        .bg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }

    .ui-modal--container {
        width: 520px;
        margin: auto;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
        transition: all 0.3s ease;
        position: relative;
        z-index: 3;
    }

</style>