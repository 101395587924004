<template>
    <Transition name="modal">
        <div v-if="is_show" class="engagement-posts">
            <div class="engagement-posts--container" :style="{
            left: `${left}px`,
            top: `${top}px`,

        }">
                <header class="head-info">
                    <h3>Posted {{ moment(date).format('MMM DD, YYYY') }}</h3>
                    <i class="prev" :class="{ 'disabled': dates[0] === date }" @click="update('prev')">
                        <svg-icon name="arrow" />
                    </i>
                    <i class="next" :class="{ 'disabled': dates[dates.length - 1] === date }" @click="update('next')">
                        <svg-icon name="arrow" />
                    </i>
                </header>
                <Loading class="static" v-if="is_loading" />
                <div class="list" v-else>
                    <ul class="metadata" v-if="posts.length !== 0">
                        <li>
                            <strong>{{ views ? views : '---' }}</strong>
                            <span class="text">Views</span>
                            <tippy content="Views" class="question">
                                <svg-icon name="question-3" />
                            </tippy>
                        </li>
                        <li class="engagement">
                            <strong>{{ handleNumSpec(engagement) === '---' ? '---' :
            `${handleNumSpec(engagement)}%` }}</strong>
                            <span class="text">Engagement</span>
                            <tippy content="Engagement" class="question">
                                <svg-icon name="question-3" />
                            </tippy>
                        </li>
                    </ul>

                    <article class="item" v-for="post in posts" :key="post.id" @click="openPost(post)">
                        <div class="img">
                            <i><svg-icon :name="post.social_platform === 'tikTok' ? 'tiktok-pure' : 'instagram'" /></i>
                            <img :src="post.thumbnail_url" alt="post image" />
                        </div>
                        <div class="info">
                            <div class="creator-box" v-if="is_show_crt">
                                <figure class="avatar">
                                    <img :src="post.social_avatar" @error="imageError($event)" />
                                </figure>
                                <div class="info-value">
                                    <tippy :delay="[0, 20]" class="text" :content="post.first_name + ' ' + post.last_name" trigger="mouseenter click">
                                        <span>{{ post.first_name + ' ' + post.last_name }}</span>
                                    </tippy>
                                    <tippy :delay="[0, 20]" class="sub" :content="getSocial(post)" trigger="mouseenter click">
                                        <span>{{ getSocial(post) }}</span>
                                    </tippy>
                                </div>
                            </div>
                            <h3 class="title" v-html="post.content"></h3>
                            <ul class="tags">
                                <li v-for="tag in post.hashtags" :key="tag.id">#{{ tag.text }}</li>
                                <li v-for="tag in post.mentions" :key="tag.id">@{{ tag.text }}</li>
                            </ul>
                            <ul class="data">
                                <li>
                                    <i><svg-icon name="eye" /></i>
                                    <span>Views {{ post.views }}</span>
                                </li>
                                <li>
                                    <i><svg-icon name="engagement" /></i>
                                    <span>Engagement {{ handleNumSpec(post.engagement) === '---' ? '---' :
            `${handleNumSpec(post.engagement)}%` }}</span>
                                </li>
                                <li>
                                    <i><svg-icon name="likes" /></i>
                                    <span>Likes {{ post.likes }}</span>
                                </li>
                                <li>
                                    <i><svg-icon name="comments" /></i>
                                    <span>Comments {{ post.comments }}</span>
                                </li>
                            </ul>
                        </div>
                    </article>

                    <div v-if="posts.length === 0" class="no-posts">No posts</div>
                </div>
            </div>
            <div class="bg" @click="is_show = false"></div>
        </div>
    </Transition>
</template>
<script>
import moment from 'moment';
import Loading from './ui/Loading.vue';
import { HandleNumSpec } from '@/utils/SwitchFun';
import { ImageError } from '@/utils/SwitchFun';

export default {
    name: 'EngagementPosts',

    props: {
        is_show_crt: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            is_show: false,
            posts: [],
            is_loading: false,
            left: 100,
            top: 100,
            date: '',
            dates: [],
            engagement: '',
            views: '',
            anchor_creator_id: 0,
            date_range: {}
        };
    },

    components: {
        Loading,
    },

    computed: {


    },

    methods: {
        moment,
        handleNumSpec: HandleNumSpec,
        imageError: ImageError,

        getSocial(post){
            if (post.motom_handle) {
                return `@${post.motom_handle}`
            } else if (post.tiktok_handle) {
                return `${post.tiktok_handle}`
            } else if (post.instagram_handle) {
                return `@${post.instagram_handle}`
            } else {
                return ''
            }
        },

        getPosts() {
            let params_data = {
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.date,
                end_date: this.date,
                page: 1,
                per_page: 30,
                sort_key: 'engagement',
                sort_val: 'desc',
                with_extra_count: 1,
                brand_post_only: 1,
            }

            if (this.anchor_creator_id) params_data.anchor_creator_id = this.anchor_creator_id;

            this.$ajax(`/creator/get-social-account-posts`, {
                params: params_data,
                method: 'get',
            }).then(res => {

                this.posts = res.data.data.social_posts.data;
                this.engagement = res.data.data.social_post_stats.engagement;
                this.views = res.data.data.social_post_stats.views;
                this.is_loading = false;

                if (!this.posts || this.posts.length < 1) {
                    this.is_show = false;
                } else {
                    this.is_show = true;
                }
            }).catch(err => {
                this.is_show = false;
            })
        },

        show(params, anchor_creator_id, dates, date_range) {
            // console.log(params)
            this.posts = [];
            this.dates = dates;
            this.date_range = date_range;
            this.is_loading = true;
            const left = params.event.event.pageX;
            const top = params.event.event.pageY;

            if ((left + 320) > window.innerWidth) {
                this.left = left - 320;
            } else {
                this.left = left;
            }

            this.top = top;
            this.date = params.name;
            this.anchor_creator_id = anchor_creator_id ? anchor_creator_id : 0;

            this.getPosts();
        },

        openPost(post) {
            this.is_show = false;
            this.EventBus.emit('open-post-modal', { post: post, date_range: this.date_range });
        },

        update(type) {

            // if (this.dates.length === 0 || this.dates[0] === this.date || this.dates[this.dates.length - 1] === this.date) return;

            const index = this.dates.findIndex(date => date === this.date);
            if (type === 'prev') {
                this.date = this.dates[index - 1];
            } else {
                this.date = this.dates[index + 1];
            }
            this.posts = [];
            this.is_loading = true;
            this.getPosts();
        },
    },
}
</script>
<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

.engagement-posts {
    position: fixed;
    z-index: 9990;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: opacity 0.3s ease;

    .bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    &--container {
        width: 320px;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
        transition: all 0.3s ease;
        position: absolute;
        z-index: 3;
        transform: translateY(-50%);

        .head-info {
            height: 40px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: relative;

            h3 {
                font: var(--font-med-10);
                color: var(--black-75);
                margin: 0;
            }

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 22px;
                height: 22px;
                display: flex;
                border-radius: 2px;
                align-items: center;
                justify-content: center;

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                svg {
                    width: 10px;
                    height: 10px;
                    fill: var(--black-75);
                    transform: rotate(90deg);
                }

                &.prev {
                    left: 8px;
                }

                &.next {
                    right: 8px;

                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &:hover {
                    background-color: var(--bg-02);

                    svg {
                        fill: var(--dark-blue);
                    }
                }
            }
        }

        .list {
            padding: 0 12px;
            max-height: 400px;
            overflow: auto;

            .metadata {
                display: flex;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font: var(--font-med-12);
                    color: var(--black-75);
                    background-color: var(--bg-02);
                    height: 32px;
                    padding: 5px 10px;
                    box-sizing: content-box;
                    flex: 1;

                    &:nth-child(1) {
                        margin-right: 5px;
                    }

                    &:nth-child(2) {
                        margin-left: 5px;
                    }

                    strong {
                        font: var(--font-demi-18);
                        color: var(--black-90);
                    }

                    .text {
                        margin: 0 5px;
                        font: var(--font-med-14);
                        color: #475569;
                    }

                    .question {
                        cursor: pointer;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: var(--black-50);
                        }
                    }
                }
            }

            .item {
                margin: 10px 0;
                display: flex;
                border-radius: 6px;
                overflow: hidden;
                border: 1px solid var(--border);
                cursor: pointer;

                .img {
                    width: calc(55% - 4px);
                    margin-right: 4px;
                    background-color: var(--bg-02);
                    position: relative;

                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 22px;
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--bg-01);
                        border-radius: 0 0 10px 0;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: var(--black-90);
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                    }
                }

                .info {
                    width: calc(45% - 4px);
                    margin-left: 4px;
                    padding-top: 8px;
                    padding-right: 10px;

                    .creator-box {
                        padding: 4px 0 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: solid 1px var(--border);
                        margin-bottom: 12px;

                        .avatar {
                            margin-right: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                border: 1px solid var(--black-40);
                            }
                        }

                        .info-value {
                            flex: 1;
                            width: 100%;
                            overflow: hidden;

                            .text {
                                font: var(--font-fg-12);
                                color: var(--black-90);
                                @include textOver();
                            }

                            .sub {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 14px;
                                color: var(--black-50);
                                @include textOver();
                            }
                        }
                    }

                    .title {
                        font: var(--font-demi-12);
                        color: var(--black-90);
                        margin: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .tags {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 4px 0 0;
                        padding-bottom: 5px;
                        border-bottom: 1px solid var(--border);

                        li {
                            margin-bottom: 5px;
                            margin-right: 5px;
                            font-size: 12px;
                            color: var(--black-50);
                            @include textOver();
                        }
                    }

                    .data {
                        margin: 8px 0;

                        li {
                            display: flex;
                            align-items: center;
                            color: var(--black-75);
                            margin: 4px 0;

                            span {
                                font: var(--font-demi-12);
                            }

                            i {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;

                                &:nth-child(1) {
                                    svg {
                                        width: 85%;
                                        height: 85%;
                                    }
                                }
                            }

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .no-posts {
            text-align: center;
            padding: 10px 20px 20px;
            font: var(--font-med-12);
            color: var(--black-75);
        }
    }
}
</style>