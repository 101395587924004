<template>
    <router-link :to="to" :class="[computed_class]" v-if="to && !is_external">
        <slot />
    </router-link>
    <a :href="to" target="_blank" :class="[computed_class]" v-else-if="to && is_external">
        <slot />
    </a>
    <button :type="type" :class="[computed_class]" v-else-if="type === 'submit' || type === 'button'">
        <slot />
    </button>
    <span :class="[computed_class]" v-else>
        <slot />
    </span>
</template>

<script>
export default {
    name: 'UiButton',

    props: {
        type: {
            type: String,
            default: 'bg'
        },

        style: {
            type: String,
            default: 'bg'
        },

        color: {
            type: String,
            default: ''
        },

        size: {
            type: String,
            default: '32'
        },

        to: {
            type: String,
            default: ''
        },

        is_external: {
            type: Boolean,
            default: false
        },

        is_disabled: {
            type: Boolean,
            default: false
        },
        border_r:{
            type: [String,Number],
            default: ''
        },
        flex:{
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            classnames: ['ui-button'],
        }
    },

    computed:{
        computed_class(){
            return ['ui-button ',`type-${this.style} `, `size-${this.size} `, `color-${this.color} `, `border-r-${this.border_r} `, this.is_disabled?'disabled ':' ' , this.flex?'display-flex ':' ']
        }
    },

    watch: {
    },

    mounted() {

    }
}
</script>

<style lang="scss">
.ui-button {
    display: inline-block;
    font: var(--font-fg-14);

    &.type-bg-square,
    &.type-bg {
        color: white;
        cursor: pointer;
        border: none;

        &.size-40 {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 20px;
            font-size: 16px;
        }

        &.size-32 {
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
            border-radius: 16px;

            svg {
                height: 10px;
                width: 10px;
                margin-right: 4px;
                top: 3px;
            }
        }

        &.size-24 {
            height: 22px;
            font-size: 12px;
            line-height: 22px;
            padding: 3px 8px;

            svg {
                height: 10px;
                width: 10px;
                margin-right: 1px;
            }
        }

        &.color-dark-blue {
            background-color: var(--dark-blue);
            color: white;

            &:hover {
                color: white;
            }
        }

        &.color-white {
            background-color: white;
            color: var(--dark-blue);

            &:hover {
                color: var(--dark-blue);
                background-color: var(--bg-02);
            }
        }

        &.color-black-10 {
            background-color: var(--black-10);
            color: var(--black-90);
        }

        &.color-light-neutral {
            background-color: var(--light-neutral);
            color: var(--black-90);
        }

        &.color-transparent {
            background-color: transparent;
            color: var(--dark-red);

            &:hover {
                color: var(--red);
            }
        }

        &.disabled {
            background-color: var(--black-10);
            color: var(--black-40);

            &:hover {
                color: var(--black-40);
            }
        }
    }

    &.type-bg-square {
        border-radius: 6px !important;
        display: inline-flex;
        align-items: center;
    }

    &.type-border {
        background: white;
        cursor: pointer;
        clear: both;
        border: 1px solid black;
        box-sizing: content-box;

        &.size-40 {
            height: 38px;
            line-height: 38px;
            padding: 0 20px;
            border-radius: 20px;
        }

        &.size-24 {
            height: 24px;
            font-size: 12px;
            padding: 3px 8px;
            border-radius: 100px;

            svg {
                height: 10px;
                width: 10px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-32 {
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 16px;
            border-radius: 16px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-56 {
            height: 54px;
            font-size: 16px;
            line-height: 54px;
            padding: 0 20px;
            border-radius: 100px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.color-black-90 {
            border-color: var(--black-90);
            color: var(--black-90);
        }

        &.color-black-60 {
            border-color: var(--black-60);
            color: var(--black-60);
        }

        &.color-dark-blue {
            border-color: var(--dark-blue);
            color: var(--dark-blue);
        }

        &.color-light-neutral {
            border-color: var(--light-neutral);
            color: var(--black-90);
        }


        &.color-alert {
            border-color: var(--alert);
            color: var(--alert);
        }

        &.disabled {
            border-color: var(--black-20);
            color: var(--black-20);
        }
    }

    &.type-border-square {
        background: white;
        color: white;
        cursor: pointer;
        clear: both;
        border: 1px solid black;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        transition: .3s;

        &.size-24 {
            height: 24px;
            font-size: 12px;
            padding: 3px 8px;
            border-radius: 6px;

            svg {
                height: 10px;
                width: 10px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-32 {
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 16px;
            border-radius: 6px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-40 {
            height: 40px;
            font-size: 16px;
            line-height: 54px;
            padding: 0 20px;
            border-radius: 6px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 3px;
                top: 3px;
            }
        }

        &.color-black-90 {
            border-color: var(--black-90);
            color: var(--black-90);
        }

        &.color-black-60 {
            border-color: var(--black-60);
            color: var(--black-60);
        }

        &.color-black-20 {
            border-color: var(--black-20);
            color: var(--black-20);
        }

        &.color-dark-blue {
            border-color: var(--dark-blue);
            color: var(--dark-blue);
        }

        &.disabled {
            border-color: var(--black-20);
            color: var(--black-20);
        }
    }

    &.type-no-border-square {
        background: white;
        cursor: pointer;
        clear: both;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        transition: .3s;

        &:hover{
            background-color: var(--bg-02);
        }


        &.size-32 {
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 16px;
            border-radius: 6px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-40 {
            height: 40px;
            font-size: 16px;
            line-height: 54px;
            padding: 0 16px;
            border: none;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &.color-black-90 {
            border-color: var(--black-90);
            color: var(--black-90);
        }

    }

    &.color-no-color {
        background-color: transparent;
        color: var(--black-50);
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
    }

    &.border-r-4{
        border-radius: 4px !important;
    }

    &.border-r-6{
        border-radius: 6px !important;
    }

    &.border-r-8{
        border-radius: 8px !important;
    }

    &.display-flex{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
