import { first } from "lodash";

const Creators = [
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "tiktok_follower_count": 30000,
        "instagram_follower_count": '',
        "joined_date": '',
        "avatar": 'Female/Image-600-15.png',
        "products": [],
        "email": 'abdou.thorson@moongit.com',
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "tiktok_follower_count": 145000,
        "instagram_follower_count": 95000,
        "joined_date": '',
        "avatar": 'Male/Image-600-2.png',
        "products": [],
        "email": 'thorson@moongit.com',
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "tiktok_follower_count": 95000,
        "instagram_follower_count": '',
        "joined_date": '',
        "avatar": 'Female/Image-600-14.png',
        "products": [],
        "email": 'kweli.jackston@moongit.com',
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "tiktok_follower_count": 12000,
        "instagram_follower_count": 47000,
        "joined_date": '',
        "avatar": 'Female/Image-600-8.png',
        "products": [],
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "tiktok_follower_count": 0,
        "instagram_follower_count": 567000,
        "joined_date": '',
        "avatar": 'Female/Image-600-3.png',
        "products": [],
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "tiktok_follower_count": 72392,
        "tiktok_views": 95434,
        "tiktok_post_count": 10,
        "tiktok_engagements": 14822,
        "tiktok_engagement_rate": 10.3,
        "instagram_follower_count": 142592,
        "instagram_post_count": 10,
        "instagram_in_feed_views": 16,
        "instagram_in_feed_count": 2,
        "instagram_engagements": 6550,
        "instagram_impressions": 70130,
        "instagram_engagement_rate": 6.4,
        "instagram_story_count": 0,
        "instagram_story_views": 0,
        "instagram_story_engagements": 0,
        "instagram_story_impressions": 0,
        "instagram_story_engagement_rate": 0,
        "joined_date": '',
        "avatar": 'Female/Image-600-10.png',
        "products": [],
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "tiktok_follower_count": 24000,
        "instagram_follower_count": 45000,
        "joined_date": '',
        "avatar": 'Female/Image-600-4.png',
        "products": [],
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "tiktok_follower_count": 1100000,
        "instagram_follower_count": 1800000,
        "joined_date": '',
        "avatar": 'Male/Image-600-14.png',
        "products": [],
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "tiktok_follower_count": 14000,
        "instagram_follower_count": 58000,
        "joined_date": '',
        "avatar": 'Female/Image-600.png',
        "products": [],
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "tiktok_follower_count": '',
        "instagram_follower_count": 243000,
        "joined_date": '',
        "avatar": 'Female/Image-600-2.png',
        "products": [],
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "tiktok_follower_count": 45000,
        "instagram_follower_count": 74000,
        "joined_date": '',
        "avatar": 'Female/Image-600-11.png',
        "products": [],
    }
];

const Products = [
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID1",
        "name": "Bow linen napkin, mint, set of two",
        "url": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "image_url": "JBTTDN036_1080x.webp",
        "price": 80
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID2",
        "name": "Bow linen napkin, berry, set of two",
        "url": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "image_url": "JBTTDN033-1_1296x.webp",
        "price": 80
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID3",
        "name": "Moon porcelain ring dish",
        "url": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "image_url": "JB_GF_RT_001_1_1080x.webp",
        "price": 98
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID4",
        "name": "Stripey bee charm",
        "url": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "image_url": "FABBJWCH208_01_1080x.webp",
        "price": 68
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID5",
        "name": "Vintage bug pot",
        "url": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "image_url": "JBGIPP052_02_1296x.webp",
        "price": 128
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID6",
        "name": "Mini bug clip set",
        "url": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "image_url": "HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.webp",
        "price": 78
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID7",
        "name": "Dragonfly napkin rings, amethyst, set of two",
        "url": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "image_url": "021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.webp",
        "price": 68
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID8",
        "name": "Heart swizzle sticks",
        "url": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "image_url": "JBBBTTSS047_02_1296x.webp",
        "price": 98
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID9",
        "name": "Stripey bee linen dinner napkins, flax, set of two",
        "url": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "image_url": "HOL16TTDN002_01_1080x.webp",
        "price": 70
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID10",
        "name": "Capricorn hanging ornament",
        "url": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "image_url": "2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.webp",
        "price": 78
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID11",
        "name": "Libra hanging ornament",
        "url": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "image_url": "JBAAHO082_01_1080x.webp",
        "price": 78
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID12",
        "name": "Painterly butterfly napkin rings, yellow, set of four",
        "url": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "image_url": "JBBBTTNR100_1080x.webp",
        "price": 188
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID13",
        "name": "Embroidered star pillow, damson cotton velvet",
        "url": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "image_url": "caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.webp",
        "price": 186
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID14",
        "name": "Palm tree jewelry box",
        "url": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "image_url": "FABBGHJB555_01_1080x.webp",
        "price": 128
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID15",
        "name": "Feather paint large glass balls, tortoiseshell, set of two",
        "url": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "image_url": "0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.webp",
        "price": 98
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID16",
        "name": "Multi pearl ball ornament, mauve grey",
        "url": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "image_url": "7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.webp",
        "price": 40
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID17",
        "name": "Heart coasters",
        "url": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "image_url": "JBBBTTCO089_1080x.webp",
        "price": 88
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID18",
        "name": "Pineapple coasters",
        "url": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "image_url": "JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.webp",
        "price": 84
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID19",
        "name": "Painterly butterfly mini clips, blue, set of five",
        "url": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "image_url": "JBAAWC210_1080x.webp",
        "price": 98
    },
    {
        "brand_name": "Joanna Buchanan",
        "id": "DemoPID20",
        "name": "Pedestal candlestick, rose quartz",
        "url": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "image_url": "55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.webp",
        "price": 198
    }
];

const Sample = [
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 8768,
        "Creator_Product_Clicks": 220,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 40480,
        "Creator_Product_Clicks": 1336,
        "Creator_Product_Transactions": 20,
        "Creator_Product_Sales": 5586.880000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 14968,
        "Creator_Product_Clicks": 375,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 492.96000000000004
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 26396,
        "Creator_Product_Clicks": 792,
        "Creator_Product_Transactions": 11,
        "Creator_Product_Sales": 2530.5280000000002
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 26710,
        "Creator_Product_Clicks": 535,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 1018.7840000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 15489,
        "Creator_Product_Clicks": 357,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 219.77800000000002
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 35008,
        "Creator_Product_Clicks": 911,
        "Creator_Product_Transactions": 9,
        "Creator_Product_Sales": 1534.3380000000004
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 39539,
        "Creator_Product_Clicks": 1108,
        "Creator_Product_Transactions": 11,
        "Creator_Product_Sales": 3208.348
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 23862,
        "Creator_Product_Clicks": 525,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 677.82
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 49791,
        "Creator_Product_Views": 32623,
        "Creator_Product_Clicks": 914,
        "Creator_Product_Transactions": 6,
        "Creator_Product_Sales": 1330.9920000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 17228,
        "Creator_Product_Clicks": 466,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 1010.5680000000001
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 22130,
        "Creator_Product_Clicks": 620,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 1571.3100000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 3308,
        "Creator_Product_Clicks": 90,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 9445,
        "Creator_Product_Clicks": 199,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 17416,
        "Creator_Product_Clicks": 436,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 184.86
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 37797,
        "Creator_Product_Clicks": 794,
        "Creator_Product_Transactions": 8,
        "Creator_Product_Sales": 969.4880000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 44883,
        "Creator_Product_Clicks": 1212,
        "Creator_Product_Transactions": 18,
        "Creator_Product_Sales": 2809.8720000000008
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 42226,
        "Creator_Product_Clicks": 1436,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 1006.4600000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 32482,
        "Creator_Product_Clicks": 780,
        "Creator_Product_Transactions": 11,
        "Creator_Product_Sales": 3117.972
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 55207,
        "Creator_Product_Views": 19767,
        "Creator_Product_Clicks": 613,
        "Creator_Product_Transactions": 9,
        "Creator_Product_Sales": 6617.988000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 8139,
        "Creator_Product_Clicks": 188,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 211.56200000000004
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 41086,
        "Creator_Product_Clicks": 1356,
        "Creator_Product_Transactions": 20,
        "Creator_Product_Sales": 6285.240000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 26931,
        "Creator_Product_Clicks": 781,
        "Creator_Product_Transactions": 11,
        "Creator_Product_Sales": 3569.8520000000003
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 19773,
        "Creator_Product_Clicks": 515,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 616.2
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 4935,
        "Creator_Product_Clicks": 149,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 476.5280000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 2431,
        "Creator_Product_Clicks": 52,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 13544,
        "Creator_Product_Clicks": 393,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 990.0280000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 40690,
        "Creator_Product_Clicks": 1181,
        "Creator_Product_Transactions": 14,
        "Creator_Product_Sales": 5894.9800000000005
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 10102,
        "Creator_Product_Clicks": 253,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 942.7860000000002
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 46426,
        "Creator_Product_Clicks": 1208,
        "Creator_Product_Transactions": 15,
        "Creator_Product_Sales": 1047.54
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 45740,
        "Creator_Product_Clicks": 1601,
        "Creator_Product_Transactions": 17,
        "Creator_Product_Sales": 3177.5380000000005
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 19009,
        "Creator_Product_Clicks": 571,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 1337.1540000000002
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 42303,
        "Creator_Product_Clicks": 889,
        "Creator_Product_Transactions": 9,
        "Creator_Product_Sales": 3142.620000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 57104,
        "Creator_Product_Views": 27848,
        "Creator_Product_Clicks": 947,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 3091.2700000000004
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 39261,
        "Creator_Product_Clicks": 1257,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1160.5100000000002
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 6716,
        "Creator_Product_Clicks": 148,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 172.53600000000003
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 27825,
        "Creator_Product_Clicks": 891,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1663.7400000000002
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 41096,
        "Creator_Product_Clicks": 1192,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 739.44
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 39582,
        "Creator_Product_Clicks": 911,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 718.9000000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 16764,
        "Creator_Product_Clicks": 520,
        "Creator_Product_Transactions": 6,
        "Creator_Product_Sales": 1552.8240000000003
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 47997,
        "Creator_Product_Clicks": 960,
        "Creator_Product_Transactions": 10,
        "Creator_Product_Sales": 2464.8
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 14985,
        "Creator_Product_Clicks": 525,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 681.9280000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 48894,
        "Creator_Product_Clicks": 1467,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 2012.9200000000003
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 43057,
        "Creator_Product_Clicks": 1378,
        "Creator_Product_Transactions": 19,
        "Creator_Product_Sales": 6634.420000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 47140,
        "Creator_Product_Clicks": 1462,
        "Creator_Product_Transactions": 20,
        "Creator_Product_Sales": 5463.64
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 8708,
        "Creator_Product_Clicks": 175,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 365.612
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 7335,
        "Creator_Product_Clicks": 155,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 665.4960000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 60140,
        "Creator_Product_Views": 9432,
        "Creator_Product_Clicks": 265,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 953.0560000000002
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 1709,
        "Creator_Product_Clicks": 60,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 29185,
        "Creator_Product_Clicks": 613,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 881.1660000000002
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 33488,
        "Creator_Product_Clicks": 871,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 1193.3740000000003
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 49850,
        "Creator_Product_Clicks": 1546,
        "Creator_Product_Transactions": 20,
        "Creator_Product_Sales": 4025.8400000000006
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 29866,
        "Creator_Product_Clicks": 628,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 299.884
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 13993,
        "Creator_Product_Clicks": 280,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 587.4440000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 17530,
        "Creator_Product_Clicks": 439,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 427.2320000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 8459,
        "Creator_Product_Clicks": 246,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 373.82800000000003
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 16235,
        "Creator_Product_Clicks": 374,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 131.45600000000002
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 8439,
        "Creator_Product_Clicks": 169,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 511.4460000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 11098,
        "Creator_Product_Clicks": 267,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 328.64000000000004
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 32576,
        "Creator_Product_Clicks": 782,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 852.4100000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 48419,
        "Creator_Product_Clicks": 1017,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1817.7900000000002
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 61316,
        "Creator_Product_Views": 13265,
        "Creator_Product_Clicks": 398,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 1277.5880000000002
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 27332,
        "Creator_Product_Clicks": 547,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 1413.1520000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 34432,
        "Creator_Product_Clicks": 1137,
        "Creator_Product_Transactions": 9,
        "Creator_Product_Sales": 1183.1040000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 21418,
        "Creator_Product_Clicks": 429,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 404.63800000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 14352,
        "Creator_Product_Clicks": 417,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 872.9500000000002
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 28177,
        "Creator_Product_Clicks": 761,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 1409.044
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 42105,
        "Creator_Product_Clicks": 885,
        "Creator_Product_Transactions": 13,
        "Creator_Product_Sales": 2456.5840000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 35834,
        "Creator_Product_Clicks": 1040,
        "Creator_Product_Transactions": 11,
        "Creator_Product_Sales": 6055.192
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 41067,
        "Creator_Product_Clicks": 1233,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 4644.094000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 20342,
        "Creator_Product_Clicks": 407,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 458.0420000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 49170,
        "Creator_Product_Clicks": 1574,
        "Creator_Product_Transactions": 12,
        "Creator_Product_Sales": 3869.7360000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 36462,
        "Creator_Product_Clicks": 803,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1304.2900000000002
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 31500,
        "Creator_Product_Clicks": 1008,
        "Creator_Product_Transactions": 13,
        "Creator_Product_Sales": 2723.6040000000007
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 60480,
        "Creator_Product_Views": 45907,
        "Creator_Product_Clicks": 1424,
        "Creator_Product_Transactions": 15,
        "Creator_Product_Sales": 6839.820000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 3916,
        "Creator_Product_Clicks": 110,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 201.29200000000003
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 6343,
        "Creator_Product_Clicks": 127,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 195.13000000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 28886,
        "Creator_Product_Clicks": 780,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1786.9800000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 6685,
        "Creator_Product_Clicks": 214,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 182.806
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 4887,
        "Creator_Product_Clicks": 162,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 182.806
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 1255,
        "Creator_Product_Clicks": 44,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 26896,
        "Creator_Product_Clicks": 727,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 776.4120000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 46289,
        "Creator_Product_Clicks": 1435,
        "Creator_Product_Transactions": 10,
        "Creator_Product_Sales": 5360.9400000000005
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 10517,
        "Creator_Product_Clicks": 263,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 1330.9920000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 15182,
        "Creator_Product_Clicks": 350,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 195.13000000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 20163,
        "Creator_Product_Clicks": 464,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 542.2560000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 45693,
        "Creator_Product_Clicks": 960,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 944.8400000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 24031,
        "Creator_Product_Clicks": 649,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 708.6300000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 25400,
        "Creator_Product_Clicks": 508,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 178.69800000000004
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 56936,
        "Creator_Product_Views": 30135,
        "Creator_Product_Clicks": 663,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 2711.28
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 43373,
        "Creator_Product_Clicks": 1172,
        "Creator_Product_Transactions": 8,
        "Creator_Product_Sales": 2218.32
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 8259,
        "Creator_Product_Clicks": 174,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 308.1
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 35568,
        "Creator_Product_Clicks": 854,
        "Creator_Product_Transactions": 8,
        "Creator_Product_Sales": 3467.1520000000005
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 46736,
        "Creator_Product_Clicks": 1590,
        "Creator_Product_Transactions": 19,
        "Creator_Product_Sales": 3902.6000000000004
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 4580,
        "Creator_Product_Clicks": 129,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 349.18000000000006
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 46908,
        "Creator_Product_Clicks": 1408,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 624.416
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 22579,
        "Creator_Product_Clicks": 610,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 903.7600000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 44239,
        "Creator_Product_Clicks": 1195,
        "Creator_Product_Transactions": 17,
        "Creator_Product_Sales": 8310.484000000002
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 29497,
        "Creator_Product_Clicks": 1003,
        "Creator_Product_Transactions": 14,
        "Creator_Product_Sales": 5923.736000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 28835,
        "Creator_Product_Clicks": 606,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 377.93600000000004
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 19317,
        "Creator_Product_Clicks": 599,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1006.4600000000002
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 11106,
        "Creator_Product_Clicks": 278,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 920.1920000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 57697,
        "Creator_Product_Views": 16436,
        "Creator_Product_Clicks": 510,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 3039.9200000000005
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 20256,
        "Creator_Product_Clicks": 669,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 846.2480000000002
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 37089,
        "Creator_Product_Clicks": 1076,
        "Creator_Product_Transactions": 10,
        "Creator_Product_Sales": 1745.9000000000003
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 15223,
        "Creator_Product_Clicks": 442,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 893.4900000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 5489,
        "Creator_Product_Clicks": 149,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 437.50200000000007
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 1577,
        "Creator_Product_Clicks": 45,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 30387,
        "Creator_Product_Clicks": 791,
        "Creator_Product_Transactions": 6,
        "Creator_Product_Sales": 1232.4
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 20411,
        "Creator_Product_Clicks": 715,
        "Creator_Product_Transactions": 6,
        "Creator_Product_Sales": 985.9200000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 21067,
        "Creator_Product_Clicks": 527,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 829.8160000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 43142,
        "Creator_Product_Clicks": 1510,
        "Creator_Product_Transactions": 18,
        "Creator_Product_Sales": 5989.464000000002
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 48263,
        "Creator_Product_Clicks": 1497,
        "Creator_Product_Transactions": 16,
        "Creator_Product_Sales": 3187.8080000000004
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 6793,
        "Creator_Product_Clicks": 177,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 71.89000000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 17399,
        "Creator_Product_Clicks": 348,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 525.8240000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59364,
        "Creator_Product_Views": 31242,
        "Creator_Product_Clicks": 844,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1119.43
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 41991,
        "Creator_Product_Clicks": 840,
        "Creator_Product_Transactions": 6,
        "Creator_Product_Sales": 1466.5560000000003
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 8686,
        "Creator_Product_Clicks": 209,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 501.17600000000004
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 19340,
        "Creator_Product_Clicks": 484,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 911.9760000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 23664,
        "Creator_Product_Clicks": 592,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 1312.506
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 12268,
        "Creator_Product_Clicks": 369,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1037.2700000000002
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 7600,
        "Creator_Product_Clicks": 168,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 287.56000000000006
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 2164,
        "Creator_Product_Clicks": 46,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 10873,
        "Creator_Product_Clicks": 251,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 201.29200000000003
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 17501,
        "Creator_Product_Clicks": 438,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 2382.6400000000003
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 39802,
        "Creator_Product_Clicks": 1314,
        "Creator_Product_Transactions": 3,
        "Creator_Product_Sales": 1035.2160000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 3114,
        "Creator_Product_Clicks": 85,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 48511,
        "Creator_Product_Clicks": 1456,
        "Creator_Product_Transactions": 4,
        "Creator_Product_Sales": 985.9200000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 19077,
        "Creator_Product_Clicks": 611,
        "Creator_Product_Transactions": 9,
        "Creator_Product_Sales": 2994.732000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45004,
        "Creator_Storefront_Views": 59669,
        "Creator_Product_Views": 40506,
        "Creator_Product_Clicks": 1378,
        "Creator_Product_Transactions": 12,
        "Creator_Product_Sales": 5669.040000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 20914,
        "Creator_Product_Clicks": 482,
        "Creator_Product_Transactions": 5,
        "Creator_Product_Sales": 1119.43
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 18850,
        "Creator_Product_Clicks": 472,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 236.21000000000004
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 22444,
        "Creator_Product_Clicks": 539,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 484.7440000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 7142,
        "Creator_Product_Clicks": 208,
        "Creator_Product_Transactions": 2,
        "Creator_Product_Sales": 324.53200000000004
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 28847,
        "Creator_Product_Clicks": 808,
        "Creator_Product_Transactions": 8,
        "Creator_Product_Sales": 1561.0400000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 10600,
        "Creator_Product_Clicks": 276,
        "Creator_Product_Transactions": 0,
        "Creator_Product_Sales": 0
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 45023,
        "Creator_Product_Clicks": 1216,
        "Creator_Product_Transactions": 18,
        "Creator_Product_Sales": 11424.348000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 23258,
        "Creator_Product_Clicks": 582,
        "Creator_Product_Transactions": 1,
        "Creator_Product_Sales": 213.61600000000004
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 23926,
        "Creator_Product_Clicks": 503,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 2372.3700000000003
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45004,
        "Creator_Storefront_Views": 55379,
        "Creator_Product_Views": 44622,
        "Creator_Product_Clicks": 1250,
        "Creator_Product_Transactions": 7,
        "Creator_Product_Sales": 1869.1400000000003
    }
];

const Full = [
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 7246,
        "Product_Clicks": 145,
        "Product_Transactions": 4,
        "Product_Sales": 1100.9440000000002
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 95886,
        "Product_Clicks": 2398,
        "Product_Transactions": 56,
        "Product_Sales": 14953.120000000003
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 40585,
        "Product_Clicks": 569,
        "Product_Transactions": 12,
        "Product_Sales": 3524.6640000000007
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 76182,
        "Product_Clicks": 1677,
        "Product_Transactions": 24,
        "Product_Sales": 4633.8240000000005
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 43931,
        "Product_Clicks": 791,
        "Product_Transactions": 16,
        "Product_Sales": 6868.576000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 65774,
        "Product_Clicks": 1053,
        "Product_Transactions": 4,
        "Product_Sales": 1051.6480000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 15960,
        "Product_Clicks": 208,
        "Product_Transactions": 5,
        "Product_Sales": 1119.43
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 46771,
        "Product_Clicks": 796,
        "Product_Transactions": 12,
        "Product_Sales": 3056.3520000000003
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 41945,
        "Product_Clicks": 714,
        "Product_Transactions": 8,
        "Product_Sales": 1495.3120000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 5912,
        "Product_Clicks": 83,
        "Product_Transactions": 1,
        "Product_Sales": 158.15800000000002
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 8668,
        "Product_Clicks": 174,
        "Product_Transactions": 2,
        "Product_Sales": 439.55600000000004
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 36114,
        "Product_Clicks": 939,
        "Product_Transactions": 19,
        "Product_Sales": 9093.058
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 80064,
        "Product_Clicks": 2002,
        "Product_Transactions": 37,
        "Product_Sales": 13907.634
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 89536,
        "Product_Clicks": 1881,
        "Product_Transactions": 12,
        "Product_Sales": 4288.752
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 91829,
        "Product_Clicks": 1378,
        "Product_Transactions": 6,
        "Product_Sales": 2156.7000000000003
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 10576,
        "Product_Clicks": 265,
        "Product_Transactions": 6,
        "Product_Sales": 529.932
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 54269,
        "Product_Clicks": 1032,
        "Product_Transactions": 15,
        "Product_Sales": 2372.3700000000003
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 68350,
        "Product_Clicks": 1504,
        "Product_Transactions": 35,
        "Product_Sales": 8842.470000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 29731,
        "Product_Clicks": 774,
        "Product_Transactions": 4,
        "Product_Sales": 772.3040000000001
    },
    {
        "first_name": "Ashley",
        "last_name": "Jameson",
        "tiktok": "GlamGoddessGrace",
        "instagram": "GlamGoddessGrace",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 110269,
        "Product_Views": 12027,
        "Product_Clicks": 181,
        "Product_Transactions": 4,
        "Product_Sales": 2546.9600000000005
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 85625,
        "Product_Clicks": 1627,
        "Product_Transactions": 7,
        "Product_Sales": 992.0820000000001
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 81729,
        "Product_Clicks": 1063,
        "Product_Transactions": 7,
        "Product_Sales": 2012.9200000000003
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 24084,
        "Product_Clicks": 458,
        "Product_Transactions": 6,
        "Product_Sales": 1897.8960000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 94859,
        "Product_Clicks": 2087,
        "Product_Transactions": 53,
        "Product_Sales": 7838.064
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 45936,
        "Product_Clicks": 735,
        "Product_Transactions": 6,
        "Product_Sales": 2440.1520000000005
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 45337,
        "Product_Clicks": 1089,
        "Product_Transactions": 20,
        "Product_Sales": 5545.800000000001
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 89968,
        "Product_Clicks": 2250,
        "Product_Transactions": 43,
        "Product_Sales": 7242.404000000001
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 84814,
        "Product_Clicks": 1273,
        "Product_Transactions": 16,
        "Product_Sales": 5225.376000000001
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 55527,
        "Product_Clicks": 1167,
        "Product_Transactions": 5,
        "Product_Sales": 934.5700000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 51432,
        "Product_Clicks": 669,
        "Product_Transactions": 12,
        "Product_Sales": 3278.1840000000007
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 85001,
        "Product_Clicks": 1786,
        "Product_Transactions": 9,
        "Product_Sales": 2588.0400000000004
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 38506,
        "Product_Clicks": 501,
        "Product_Transactions": 12,
        "Product_Sales": 6137.352000000001
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 50088,
        "Product_Clicks": 1102,
        "Product_Transactions": 20,
        "Product_Sales": 11831.04
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 49207,
        "Product_Clicks": 935,
        "Product_Transactions": 6,
        "Product_Sales": 2661.9840000000004
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 82728,
        "Product_Clicks": 1903,
        "Product_Transactions": 42,
        "Product_Sales": 11042.304000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 97731,
        "Product_Clicks": 2346,
        "Product_Transactions": 19,
        "Product_Sales": 1287.8580000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 74465,
        "Product_Clicks": 1713,
        "Product_Transactions": 43,
        "Product_Sales": 12100.114000000003
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 37929,
        "Product_Clicks": 683,
        "Product_Transactions": 14,
        "Product_Sales": 3853.3040000000005
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 31331,
        "Product_Clicks": 596,
        "Product_Transactions": 5,
        "Product_Sales": 1006.4600000000002
    },
    {
        "first_name": "Cameron",
        "last_name": "Lee",
        "tiktok": "ChicAndCharming",
        "instagram": "ChicAndCharming",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 112391,
        "Product_Views": 59504,
        "Product_Clicks": 893,
        "Product_Transactions": 16,
        "Product_Sales": 6737.120000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 19379,
        "Product_Clicks": 272,
        "Product_Transactions": 7,
        "Product_Sales": 1265.2640000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 92335,
        "Product_Clicks": 2032,
        "Product_Transactions": 11,
        "Product_Sales": 3185.7540000000004
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 81014,
        "Product_Clicks": 1378,
        "Product_Transactions": 6,
        "Product_Sales": 1503.5280000000002
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 70421,
        "Product_Clicks": 1409,
        "Product_Transactions": 22,
        "Product_Sales": 4744.740000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 20706,
        "Product_Clicks": 290,
        "Product_Transactions": 4,
        "Product_Sales": 1330.9920000000002
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 49060,
        "Product_Clicks": 785,
        "Product_Transactions": 19,
        "Product_Sales": 4019.6780000000003
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 32453,
        "Product_Clicks": 520,
        "Product_Transactions": 10,
        "Product_Sales": 2464.8
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 31512,
        "Product_Clicks": 410,
        "Product_Transactions": 7,
        "Product_Sales": 1265.2640000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 44120,
        "Product_Clicks": 839,
        "Product_Transactions": 3,
        "Product_Sales": 597.714
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 41862,
        "Product_Clicks": 670,
        "Product_Transactions": 15,
        "Product_Sales": 2618.8500000000004
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 44586,
        "Product_Clicks": 1026,
        "Product_Transactions": 22,
        "Product_Sales": 3886.168
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 89094,
        "Product_Clicks": 1426,
        "Product_Transactions": 16,
        "Product_Sales": 7854.496000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 96027,
        "Product_Clicks": 1345,
        "Product_Transactions": 17,
        "Product_Sales": 11208.678000000002
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 70786,
        "Product_Clicks": 1204,
        "Product_Transactions": 17,
        "Product_Sales": 7437.5340000000015
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 2039,
        "Product_Clicks": 33,
        "Product_Transactions": 1,
        "Product_Sales": 176.64400000000003
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 60814,
        "Product_Clicks": 913,
        "Product_Transactions": 6,
        "Product_Sales": 690.1440000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 55761,
        "Product_Clicks": 1004,
        "Product_Transactions": 23,
        "Product_Sales": 4818.684
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 98974,
        "Product_Clicks": 2178,
        "Product_Transactions": 53,
        "Product_Sales": 7511.478000000001
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 59810,
        "Product_Clicks": 1436,
        "Product_Transactions": 34,
        "Product_Sales": 7402.616000000002
    },
    {
        "first_name": "Elizabeth",
        "last_name": "Martinez",
        "tiktok": "FashionableLizzie",
        "instagram": "FashionableLizzie",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 113821,
        "Product_Views": 44910,
        "Product_Clicks": 1123,
        "Product_Transactions": 20,
        "Product_Sales": 11214.840000000002
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 60263,
        "Product_Clicks": 1387,
        "Product_Transactions": 32,
        "Product_Sales": 7887.360000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 64305,
        "Product_Clicks": 1608,
        "Product_Transactions": 17,
        "Product_Sales": 2653.7680000000005
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 33445,
        "Product_Clicks": 569,
        "Product_Transactions": 7,
        "Product_Sales": 2314.858
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 91374,
        "Product_Clicks": 2193,
        "Product_Transactions": 47,
        "Product_Sales": 7240.35
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 58969,
        "Product_Clicks": 1180,
        "Product_Transactions": 5,
        "Product_Sales": 1437.8000000000002
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 57398,
        "Product_Clicks": 1435,
        "Product_Transactions": 23,
        "Product_Sales": 5054.894
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 69902,
        "Product_Clicks": 1189,
        "Product_Transactions": 6,
        "Product_Sales": 1146.132
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 14500,
        "Product_Clicks": 363,
        "Product_Transactions": 5,
        "Product_Sales": 1027.0000000000002
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 38237,
        "Product_Clicks": 803,
        "Product_Transactions": 5,
        "Product_Sales": 1150.2400000000002
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 33894,
        "Product_Clicks": 509,
        "Product_Transactions": 5,
        "Product_Sales": 852.4100000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 75285,
        "Product_Clicks": 1883,
        "Product_Transactions": 44,
        "Product_Sales": 9308.728000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 72434,
        "Product_Clicks": 1015,
        "Product_Transactions": 20,
        "Product_Sales": 7230.080000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 22464,
        "Product_Clicks": 472,
        "Product_Transactions": 9,
        "Product_Sales": 3678.714000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 49989,
        "Product_Clicks": 1150,
        "Product_Transactions": 7,
        "Product_Sales": 1639.0920000000003
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 9074,
        "Product_Clicks": 137,
        "Product_Transactions": 2,
        "Product_Sales": 340.96400000000006
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 55331,
        "Product_Clicks": 996,
        "Product_Transactions": 5,
        "Product_Sales": 369.72
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 13527,
        "Product_Clicks": 244,
        "Product_Transactions": 2,
        "Product_Sales": 587.4440000000001
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 60044,
        "Product_Clicks": 1141,
        "Product_Transactions": 12,
        "Product_Sales": 2440.1520000000005
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 36602,
        "Product_Clicks": 952,
        "Product_Transactions": 6,
        "Product_Sales": 1552.8240000000003
    },
    {
        "first_name": "Farrah",
        "last_name": "Patel",
        "tiktok": "MakeupMavenFarrahva",
        "instagram": "MakeupMavenFarrahva",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 105081,
        "Product_Views": 71849,
        "Product_Clicks": 1725,
        "Product_Transactions": 13,
        "Product_Sales": 8838.362000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 20311,
        "Product_Clicks": 346,
        "Product_Transactions": 2,
        "Product_Sales": 357.3960000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 8119,
        "Product_Clicks": 147,
        "Product_Transactions": 3,
        "Product_Sales": 505.28400000000005
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 66545,
        "Product_Clicks": 1265,
        "Product_Transactions": 19,
        "Product_Sales": 5346.562000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 41522,
        "Product_Clicks": 1039,
        "Product_Transactions": 23,
        "Product_Sales": 5810.7660000000005
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 50202,
        "Product_Clicks": 754,
        "Product_Transactions": 9,
        "Product_Sales": 2051.9460000000004
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 2191,
        "Product_Clicks": 53,
        "Product_Transactions": 1,
        "Product_Sales": 260.85800000000006
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 1509,
        "Product_Clicks": 37,
        "Product_Transactions": 1,
        "Product_Sales": 154.05
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 27604,
        "Product_Clicks": 553,
        "Product_Transactions": 5,
        "Product_Sales": 821.6000000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 9320,
        "Product_Clicks": 150,
        "Product_Transactions": 3,
        "Product_Sales": 616.2
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 77551,
        "Product_Clicks": 1164,
        "Product_Transactions": 26,
        "Product_Sales": 6942.520000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 88389,
        "Product_Clicks": 1768,
        "Product_Transactions": 16,
        "Product_Sales": 4272.320000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 19306,
        "Product_Clicks": 406,
        "Product_Transactions": 3,
        "Product_Sales": 1984.1640000000002
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 52171,
        "Product_Clicks": 992,
        "Product_Transactions": 13,
        "Product_Sales": 5073.380000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 48700,
        "Product_Clicks": 634,
        "Product_Transactions": 11,
        "Product_Sales": 3840.98
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 40005,
        "Product_Clicks": 1001,
        "Product_Transactions": 13,
        "Product_Sales": 3551.366000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 81695,
        "Product_Clicks": 1553,
        "Product_Transactions": 22,
        "Product_Sales": 2620.904
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 6814,
        "Product_Clicks": 110,
        "Product_Transactions": 2,
        "Product_Sales": 308.1
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 62087,
        "Product_Clicks": 1615,
        "Product_Transactions": 30,
        "Product_Sales": 7640.880000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 34700,
        "Product_Clicks": 833,
        "Product_Transactions": 3,
        "Product_Sales": 579.2280000000001
    },
    {
        "first_name": "Gabriella",
        "last_name": "Nguyen",
        "tiktok": "SassyStylist2001",
        "instagram": "SassyStylist2001",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 105188,
        "Product_Views": 91467,
        "Product_Clicks": 2379,
        "Product_Transactions": 55,
        "Product_Sales": 24740.430000000004
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 18471,
        "Product_Clicks": 351,
        "Product_Transactions": 8,
        "Product_Sales": 1248.832
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 88518,
        "Product_Clicks": 2125,
        "Product_Transactions": 49,
        "Product_Sales": 13083.980000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 30919,
        "Product_Clicks": 557,
        "Product_Transactions": 14,
        "Product_Sales": 4859.764000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 5521,
        "Product_Clicks": 144,
        "Product_Transactions": 2,
        "Product_Sales": 345.07200000000006
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 57371,
        "Product_Clicks": 918,
        "Product_Transactions": 3,
        "Product_Sales": 714.7920000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 83295,
        "Product_Clicks": 1083,
        "Product_Transactions": 16,
        "Product_Sales": 2760.5760000000005
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 52382,
        "Product_Clicks": 1258,
        "Product_Transactions": 12,
        "Product_Sales": 2809.8720000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 86464,
        "Product_Clicks": 1297,
        "Product_Transactions": 12,
        "Product_Sales": 3204.2400000000002
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 30256,
        "Product_Clicks": 515,
        "Product_Transactions": 4,
        "Product_Sales": 673.7120000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 73492,
        "Product_Clicks": 1250,
        "Product_Transactions": 28,
        "Product_Sales": 4370.912
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 47231,
        "Product_Clicks": 1181,
        "Product_Transactions": 29,
        "Product_Sales": 8339.240000000002
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 83049,
        "Product_Clicks": 1911,
        "Product_Transactions": 22,
        "Product_Sales": 11071.060000000001
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 62953,
        "Product_Clicks": 1574,
        "Product_Transactions": 32,
        "Product_Sales": 16694.912000000004
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 2907,
        "Product_Clicks": 53,
        "Product_Transactions": 2,
        "Product_Sales": 907.8680000000002
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 92793,
        "Product_Clicks": 1485,
        "Product_Transactions": 35,
        "Product_Sales": 12005.630000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 21531,
        "Product_Clicks": 410,
        "Product_Transactions": 9,
        "Product_Sales": 1275.5340000000003
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 99247,
        "Product_Clicks": 1390,
        "Product_Transactions": 27,
        "Product_Sales": 6987.7080000000005
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 30038,
        "Product_Clicks": 751,
        "Product_Transactions": 12,
        "Product_Sales": 2686.6320000000005
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 88958,
        "Product_Clicks": 1246,
        "Product_Transactions": 12,
        "Product_Sales": 2760.5760000000005
    },
    {
        "first_name": "Haley",
        "last_name": "Kim",
        "tiktok": "BeautyBossBelle<3",
        "instagram": "BeautyBossBell3<3",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 114135,
        "Product_Views": 39527,
        "Product_Clicks": 949,
        "Product_Transactions": 7,
        "Product_Sales": 2444.26
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 5792,
        "Product_Clicks": 116,
        "Product_Transactions": 3,
        "Product_Sales": 690.1440000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 71259,
        "Product_Clicks": 1568,
        "Product_Transactions": 11,
        "Product_Sales": 2372.3700000000003
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 67264,
        "Product_Clicks": 1615,
        "Product_Transactions": 12,
        "Product_Sales": 2563.3920000000003
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 12078,
        "Product_Clicks": 170,
        "Product_Transactions": 2,
        "Product_Sales": 267.02000000000004
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 55065,
        "Product_Clicks": 1157,
        "Product_Transactions": 12,
        "Product_Sales": 3426.0720000000006
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 40445,
        "Product_Clicks": 1052,
        "Product_Transactions": 24,
        "Product_Sales": 5570.448
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 34769,
        "Product_Clicks": 592,
        "Product_Transactions": 5,
        "Product_Sales": 657.2800000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 62529,
        "Product_Clicks": 1314,
        "Product_Transactions": 29,
        "Product_Sales": 7743.580000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 52441,
        "Product_Clicks": 735,
        "Product_Transactions": 14,
        "Product_Sales": 3191.9160000000006
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 82790,
        "Product_Clicks": 1987,
        "Product_Transactions": 34,
        "Product_Sales": 8939.008000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 28598,
        "Product_Clicks": 715,
        "Product_Transactions": 4,
        "Product_Sales": 1076.296
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 14144,
        "Product_Clicks": 326,
        "Product_Transactions": 6,
        "Product_Sales": 3487.6920000000005
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 23025,
        "Product_Clicks": 576,
        "Product_Transactions": 6,
        "Product_Sales": 2871.492
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 88211,
        "Product_Clicks": 2029,
        "Product_Transactions": 31,
        "Product_Sales": 12289.082000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 79181,
        "Product_Clicks": 1742,
        "Product_Transactions": 18,
        "Product_Sales": 4029.948000000001
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 87247,
        "Product_Clicks": 2182,
        "Product_Transactions": 29,
        "Product_Sales": 2620.9040000000005
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 3488,
        "Product_Clicks": 60,
        "Product_Transactions": 1,
        "Product_Sales": 193.07600000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 86106,
        "Product_Clicks": 1292,
        "Product_Transactions": 16,
        "Product_Sales": 3187.8080000000004
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 66726,
        "Product_Clicks": 1669,
        "Product_Transactions": 42,
        "Product_Sales": 12595.128000000002
    },
    {
        "first_name": "Isabella",
        "last_name": "Brown",
        "tiktok": "TrendyIzz",
        "instagram": "TrendyIzz",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 101443,
        "Product_Views": 51889,
        "Product_Clicks": 779,
        "Product_Transactions": 12,
        "Product_Sales": 5669.040000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 90784,
        "Product_Clicks": 1544,
        "Product_Transactions": 13,
        "Product_Sales": 3257.6440000000007
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 18359,
        "Product_Clicks": 404,
        "Product_Transactions": 2,
        "Product_Sales": 357.3960000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 64169,
        "Product_Clicks": 1348,
        "Product_Transactions": 19,
        "Product_Sales": 3980.6520000000005
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 25709,
        "Product_Clicks": 438,
        "Product_Transactions": 9,
        "Product_Sales": 1164.6180000000002
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 18922,
        "Product_Clicks": 455,
        "Product_Transactions": 7,
        "Product_Sales": 2300.4800000000005
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 45992,
        "Product_Clicks": 966,
        "Product_Transactions": 12,
        "Product_Sales": 3007.0560000000005
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 22610,
        "Product_Clicks": 294,
        "Product_Transactions": 5,
        "Product_Sales": 1252.94
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 80747,
        "Product_Clicks": 1938,
        "Product_Transactions": 16,
        "Product_Sales": 3417.8560000000007
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 49753,
        "Product_Clicks": 896,
        "Product_Transactions": 6,
        "Product_Sales": 985.9200000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 37324,
        "Product_Clicks": 635,
        "Product_Transactions": 9,
        "Product_Sales": 1275.5340000000003
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 56438,
        "Product_Clicks": 1186,
        "Product_Transactions": 28,
        "Product_Sales": 6441.344000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 28129,
        "Product_Clicks": 647,
        "Product_Transactions": 2,
        "Product_Sales": 1302.236
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 64762,
        "Product_Clicks": 1296,
        "Product_Transactions": 16,
        "Product_Sales": 7591.584000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 47024,
        "Product_Clicks": 1129,
        "Product_Transactions": 6,
        "Product_Sales": 2674.3080000000004
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 37561,
        "Product_Clicks": 789,
        "Product_Transactions": 3,
        "Product_Sales": 794.8980000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 91496,
        "Product_Clicks": 1922,
        "Product_Transactions": 24,
        "Product_Sales": 2366.2080000000005
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 14332,
        "Product_Clicks": 359,
        "Product_Transactions": 9,
        "Product_Sales": 2902.3020000000006
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 15744,
        "Product_Clicks": 331,
        "Product_Transactions": 2,
        "Product_Sales": 423.1240000000001
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 37479,
        "Product_Clicks": 750,
        "Product_Transactions": 12,
        "Product_Sales": 3992.9760000000006
    },
    {
        "first_name": "Jake",
        "last_name": "Wilson",
        "tiktok": "GorgeousGina",
        "instagram": "GorgeousGina",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 105221,
        "Product_Views": 42857,
        "Product_Clicks": 858,
        "Product_Transactions": 17,
        "Product_Sales": 7926.386000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 1668,
        "Product_Clicks": 24,
        "Product_Transactions": 1,
        "Product_Sales": 145.83400000000003
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 16291,
        "Product_Clicks": 391,
        "Product_Transactions": 9,
        "Product_Sales": 1257.0480000000002
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 3425,
        "Product_Clicks": 55,
        "Product_Transactions": 2,
        "Product_Sales": 423.1240000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 5110,
        "Product_Clicks": 123,
        "Product_Transactions": 1,
        "Product_Sales": 188.96800000000002
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 36841,
        "Product_Clicks": 848,
        "Product_Transactions": 12,
        "Product_Sales": 2809.8720000000003
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 73508,
        "Product_Clicks": 956,
        "Product_Transactions": 9,
        "Product_Sales": 2162.8620000000005
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 16013,
        "Product_Clicks": 241,
        "Product_Transactions": 3,
        "Product_Sales": 548.4180000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 68751,
        "Product_Clicks": 1032,
        "Product_Transactions": 11,
        "Product_Sales": 3569.8520000000003
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 62120,
        "Product_Clicks": 1181,
        "Product_Transactions": 4,
        "Product_Sales": 911.9760000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 19297,
        "Product_Clicks": 406,
        "Product_Transactions": 11,
        "Product_Sales": 2530.5280000000002
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 41056,
        "Product_Clicks": 740,
        "Product_Transactions": 9,
        "Product_Sales": 1978.0020000000004
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 28550,
        "Product_Clicks": 657,
        "Product_Transactions": 10,
        "Product_Sales": 3389.1000000000004
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 87932,
        "Product_Clicks": 1144,
        "Product_Transactions": 28,
        "Product_Sales": 18921.448000000004
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 65379,
        "Product_Clicks": 916,
        "Product_Transactions": 19,
        "Product_Sales": 4370.912
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 27223,
        "Product_Clicks": 599,
        "Product_Transactions": 3,
        "Product_Sales": 875.0040000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 76977,
        "Product_Clicks": 1771,
        "Product_Transactions": 18,
        "Product_Sales": 1848.6000000000004
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 71169,
        "Product_Clicks": 1424,
        "Product_Transactions": 28,
        "Product_Sales": 5176.080000000001
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 21131,
        "Product_Clicks": 444,
        "Product_Transactions": 7,
        "Product_Sales": 1006.4600000000002
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 1867,
        "Product_Clicks": 27,
        "Product_Transactions": 1,
        "Product_Sales": 178.69800000000004
    },
    {
        "first_name": "Hannah",
        "last_name": "Rodriguez",
        "tiktok": "HauteHannah",
        "instagram": "HauteHannah",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 101122,
        "Product_Views": 60422,
        "Product_Clicks": 1571,
        "Product_Transactions": 29,
        "Product_Sales": 20431.138000000003
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 11083,
        "Product_Clicks": 189,
        "Product_Transactions": 4,
        "Product_Sales": 575.1200000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 7784,
        "Product_Clicks": 102,
        "Product_Transactions": 1,
        "Product_Sales": 158.15800000000002
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 88652,
        "Product_Clicks": 1685,
        "Product_Transactions": 31,
        "Product_Sales": 9933.144
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 41533,
        "Product_Clicks": 665,
        "Product_Transactions": 12,
        "Product_Sales": 1404.9360000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 97862,
        "Product_Clicks": 1762,
        "Product_Transactions": 18,
        "Product_Sales": 6174.324000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 30728,
        "Product_Clicks": 646,
        "Product_Transactions": 7,
        "Product_Sales": 1624.7140000000002
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 11492,
        "Product_Clicks": 242,
        "Product_Transactions": 1,
        "Product_Sales": 223.88600000000002
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 14188,
        "Product_Clicks": 199,
        "Product_Transactions": 3,
        "Product_Sales": 690.1440000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 46624,
        "Product_Clicks": 840,
        "Product_Transactions": 19,
        "Product_Sales": 2263.5080000000003
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 44633,
        "Product_Clicks": 849,
        "Product_Transactions": 17,
        "Product_Sales": 4748.848000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 8815,
        "Product_Clicks": 124,
        "Product_Transactions": 2,
        "Product_Sales": 497.06800000000004
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 47479,
        "Product_Clicks": 618,
        "Product_Transactions": 10,
        "Product_Sales": 3348.0200000000004
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 52593,
        "Product_Clicks": 1263,
        "Product_Transactions": 8,
        "Product_Sales": 3269.9680000000003
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 5168,
        "Product_Clicks": 119,
        "Product_Transactions": 2,
        "Product_Sales": 854.4640000000002
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 10075,
        "Product_Clicks": 172,
        "Product_Transactions": 4,
        "Product_Sales": 1215.968
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 87013,
        "Product_Clicks": 1828,
        "Product_Transactions": 44,
        "Product_Sales": 3524.664
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 63537,
        "Product_Clicks": 826,
        "Product_Transactions": 12,
        "Product_Sales": 2711.28
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 37948,
        "Product_Clicks": 759,
        "Product_Transactions": 6,
        "Product_Sales": 1688.3880000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 64570,
        "Product_Clicks": 969,
        "Product_Transactions": 24,
        "Product_Sales": 6162.000000000001
    },
    {
        "first_name": "Faith",
        "last_name": "Thompson",
        "tiktok": "FashionistaFaith",
        "instagram": "FashionistaFaith",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 112542,
        "Product_Views": 17698,
        "Product_Clicks": 337,
        "Product_Transactions": 9,
        "Product_Sales": 3382.9380000000006
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID1",
        "Product_Name": "Bow linen napkin, mint, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-napkin-mint-set-of-two",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN036_1080x.jpg?v=1675366237",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 78434,
        "Product_Clicks": 2040,
        "Product_Transactions": 29,
        "Product_Sales": 7624.448000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID2",
        "Product_Name": "Bow linen napkin, berry, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/put-a-bow-on-it/products/bow-linen-napkin-berry",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBTTDN033-1_1296x.jpg?v=1671055527",
        "price": 80,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 10697,
        "Product_Clicks": 150,
        "Product_Transactions": 1,
        "Product_Sales": 223.88600000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID3",
        "Product_Name": "Moon porcelain ring dish",
        "Product_URL": "https://www.joannabuchanan.com/collections/accessories/products/navy-moon-ring-dish",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JB_GF_RT_001_1_1080x.jpg?v=1576870717",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 81013,
        "Product_Clicks": 1216,
        "Product_Transactions": 16,
        "Product_Sales": 5356.832
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID4",
        "Product_Name": "Stripey bee charm",
        "Product_URL": "https://www.joannabuchanan.com/collections/necklaces/products/stripey-bee-charm",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBJWCH208_01_1080x.jpg?v=1655772781",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 40218,
        "Product_Clicks": 1046,
        "Product_Transactions": 10,
        "Product_Sales": 1725.3600000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID5",
        "Product_Name": "Vintage bug pot",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pots/products/large-vintage-bug-pot",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBGIPP052_02_1296x.jpg?v=1661458462",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 40453,
        "Product_Clicks": 729,
        "Product_Transactions": 19,
        "Product_Sales": 5073.38
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID6",
        "Product_Name": "Mini bug clip set",
        "Product_URL": "https://www.joannabuchanan.com/collections/best-sellers/products/mini-bug-set-of-5",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL_16_WC_030_82a22054-ae60-4ffb-99e9-7a95622b1030_1296x.jpg?v=1632324516",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 36853,
        "Product_Clicks": 811,
        "Product_Transactions": 5,
        "Product_Sales": 842.1400000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID7",
        "Product_Name": "Dragonfly napkin rings, amethyst, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/napkin-rings/products/dragonfly-napkin-rings-2",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/021b5281-5b0f-45ae-b0c9-37ad29b6f804_1080x.png?v=1654029048",
        "price": 68,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 36037,
        "Product_Clicks": 829,
        "Product_Transactions": 15,
        "Product_Sales": 1971.8400000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID8",
        "Product_Name": "Heart swizzle sticks",
        "Product_URL": "https://www.joannabuchanan.com/collections/straws-swizzles/products/heart-swizzle-sticks",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTSS047_02_1296x.jpg?v=1673978160",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 95574,
        "Product_Clicks": 1721,
        "Product_Transactions": 37,
        "Product_Sales": 12615.668000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID9",
        "Product_Name": "Stripey bee linen dinner napkins, flax, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/melamine/products/stripey-bee-dinner-napkins",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/HOL16TTDN002_01_1080x.jpg?v=1598198199",
        "price": 70,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 49166,
        "Product_Clicks": 1230,
        "Product_Transactions": 10,
        "Product_Sales": 1232.4
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID10",
        "Product_Name": "Capricorn hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/birthday/products/capricorn-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/2021_02_04_JoannaBuchanan_Shot04_0025_a35a2a6e-4ae7-4cf5-905b-0ea434e2d5cb_1296x.jpg?v=1624984503",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 84693,
        "Product_Clicks": 1525,
        "Product_Transactions": 37,
        "Product_Sales": 9575.748000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID11",
        "Product_Name": "Libra hanging ornament",
        "Product_URL": "https://www.joannabuchanan.com/collections/zodiac/products/libra-hanging-ornament",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAHO082_01_1080x.jpg?v=1597207304",
        "price": 78,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 13779,
        "Product_Clicks": 290,
        "Product_Transactions": 1,
        "Product_Sales": 133.51000000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID12",
        "Product_Name": "Painterly butterfly napkin rings, yellow, set of four",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-napkin-rings-yellow-set-of-four",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTNR100_1080x.jpg?v=1673978120",
        "price": 188,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 39657,
        "Product_Clicks": 1032,
        "Product_Transactions": 16,
        "Product_Sales": 8216.000000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID13",
        "Product_Name": "Embroidered star pillow, damson cotton velvet",
        "Product_URL": "https://www.joannabuchanan.com/collections/decorative-pillows/products/embroidered-star-pillow-damson",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/caa74e8a-e26e-4d46-b0b4-f0855a86be3b_1080x.png?v=1640115694",
        "price": 186,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 2754,
        "Product_Clicks": 64,
        "Product_Transactions": 1,
        "Product_Sales": 312.208
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID14",
        "Product_Name": "Palm tree jewelry box",
        "Product_URL": "https://www.joannabuchanan.com/collections/treasure-boxes/products/palm-tree-jewelry-box",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/FABBGHJB555_01_1080x.jpg?v=1655772422",
        "price": 128,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 22783,
        "Product_Clicks": 388,
        "Product_Transactions": 3,
        "Product_Sales": 1084.5120000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID15",
        "Product_Name": "Feather paint large glass balls, tortoiseshell, set of two",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/feather-paint-glass-ball-set-of-two-brown",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/0d8d4803-eb44-4ca2-957c-913c740d831c_1080x.png?v=1665262152",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 49741,
        "Product_Clicks": 1145,
        "Product_Transactions": 20,
        "Product_Sales": 5545.800000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID16",
        "Product_Name": "Multi pearl ball ornament, mauve grey",
        "Product_URL": "https://www.joannabuchanan.com/collections/baubles-tassels/products/multi-pearl-ball-ornament-grey",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/7c734e0a-7e8c-4bde-8f2c-1be0bcf58f15_1080x.png?v=1642557742",
        "price": 40,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 65196,
        "Product_Clicks": 978,
        "Product_Transactions": 23,
        "Product_Sales": 1747.9540000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID17",
        "Product_Name": "Heart coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/mothers-day/products/heart-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO089_1080x.jpg?v=1673978214",
        "price": 88,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 10414,
        "Product_Clicks": 178,
        "Product_Transactions": 1,
        "Product_Sales": 293.72200000000004
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID18",
        "Product_Name": "Pineapple coasters",
        "Product_URL": "https://www.joannabuchanan.com/collections/tropical-cocktail-accessories/products/pineapple-coasters",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBBBTTCO028_01_1ba89700-3061-49ba-afd6-3e321532a47d_1296x.jpg?v=1625165838",
        "price": 84,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 91219,
        "Product_Clicks": 2007,
        "Product_Transactions": 33,
        "Product_Sales": 9421.698000000002
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID19",
        "Product_Name": "Painterly butterfly mini clips, blue, set of five",
        "Product_URL": "https://www.joannabuchanan.com/collections/spring-2023/products/painterly-butterfly-mini-clips-blue",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/JBAAWC210_1080x.jpg?v=1673978174",
        "price": 98,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 9078,
        "Product_Clicks": 128,
        "Product_Transactions": 3,
        "Product_Sales": 813.3840000000001
    },
    {
        "first_name": "Nicole",
        "last_name": "Taylor",
        "tiktok": "BeautyQueenBree",
        "instagram": "BeautyQueenBree",
        "brand_name": "Joanna Buchanan",
        "ProductID": "DemoPID20",
        "Product_Name": "Pedestal candlestick, rose quartz",
        "Product_URL": "https://www.joannabuchanan.com/collections/candles-accessories/products/pedestal-rose-quartz-candlestick",
        "Product_Image_URL": "https://cdn.shopify.com/s/files/1/1745/7527/products/55b87c44-a625-41cb-9a7f-a2ce8d9e3ffa_1296x.png?v=1654029001",
        "price": 198,
        "Added": 45005,
        "Creator_Storefront_Views": 109911,
        "Product_Views": 73829,
        "Product_Clicks": 1699,
        "Product_Transactions": 19,
        "Product_Sales": 7571.044000000001
    }
];

const Users = [
    {
        id: 1,
        email: 'tim@motom.me',
        name: 'Tim Trevathan',
        avatar: 'https://i.pravatar.cc/150?u=fake@4',
        last_name: 'Trevathan',
        first_name: 'Tim',
    },
    {
        id: 2,
        email: 'matt@motom.me',
        name: 'Matt Diamond',
        avatar: 'https://i.pravatar.cc/150?u=fake@8',
        last_name: 'Diamond',
        first_name: 'Matt',
    },
    {
        id: 3,
        email: 'wendy@motom.me',
        name: 'Wendy Wildfeuer',
        avatar: 'https://i.pravatar.cc/150?u=fake@6',
        last_name: 'Wildfeuer',
        first_name: 'Wendy',
    },
    {
        id: 4,
        email: 'geri@motom.me',
        name: 'Geri Newman',
        avatar: 'https://i.pravatar.cc/150?u=fake@9',
        last_name: 'Newman',
        first_name: 'Geri',
    },
    {
        id: 5,
        email: 'jenna@motom.me',
        name: 'Jenna Lichtman',
        avatar: 'https://i.pravatar.cc/150?u=fake@1',
        last_name: 'Lichtman',
        first_name: 'Jenna',
    },
];

const PostStatistic = {
    "tk": {
        "total": {
            "total_views": 260866,
            "total_engagement": 14822,
            "total_engagement_rate": 0.075,
        },
        "audience_gender": [
            {
                "name": "Women",
                "value": 0.588
            },
            {
                "name": "Men",
                "value": 0.408
            },
            {
                "name": "Other",
                "value": 0.004
            }
        ],
        "audience_age": [
            {
                "name": "18-24",
                "value": 0.279
            },
            {
                "name": "25-34",
                "value": 0.424
            },
            {
                "name": "35+",
                "value": 0.297
            },
        ],
        "audience_countries": [
            {
                "name": "United States",
                "value": 0.8845
            },
            {
                "name": "Canada",
                "value": 0.0216
            },
            {
                "name": "United Kingdom",
                "value": 0.0096
            },
            {
                "name": "Other",
                "value": 0.012
            },
            {
                "name": "South Africa",
                "value": 0.0012
            },
            {
                "name": "Australia",
                "value": 0.0041
            },
            {
                "name": "Taiwan",
                "value": 0.0021
            },
            {
                "name": "France",
                "value": 0.0049
            },
            {
                "name": "Brazil",
                "value": 0.0042
            },
            {
                "name": "Germany",
                "value": 0.0059
            },
        ]

    },
    "ig": {
        "feed": {
            "total_views": 173101,
            "total_engagement": 6550,
            "total_engagement_rate": 0.063,
        },
        "story": {
            "total_views": 0,
            "total_engagement": 0,
            "total_engagement_rate": 0,
        },
        "audience_gender": [
            {
                "name": "Women",
                "value": 0.627
            },
            {
                "name": "Men",
                "value": 0.369
            },
            {
                "name": "Other",
                "value": 0.004
            }
        ],
        "audience_age": [
            {
                "name": "18-24",
                "value": 0.212
            },
            {
                "name": "25-34",
                "value": 0.312
            },
            {
                "name": "35-44",
                "value": 0.258
            },
            {
                "name": "45-54",
                "value": 0.146
            },
            {
                "name": "55+",
                "value": 0.072
            }
        ],
        "audience_countries": [
            {
                "name": "United States",
                "value": 0.9345
            },
            {
                "name": "Canada",
                "value": 0.0241
            },
            {
                "name": "United Kingdom",
                "value": 0.0127
            },
            {
                "name": "Other",
                "value": 0.0147
            },
            {
                "name": "South Africa",
                "value": 0.0014
            },
            {
                "name": "Australia",
                "value": 0.0037
            },
            {
                "name": "Taiwan",
                "value": 0.0001
            },
            {
                "name": "France",
                "value": 0.0068
            },
            {
                "name": "Brazil",
                "value": 0
            },
            {
                "name": "Germany",
                "value": 0.002
            },
        ]
    }
};

const ContentPostStatistic = {
    "tk": {
        "total": {
            "total_views": 3350864,
            "total_engagement": 271419,
            "total_engagement_rate": 0.081, 
        },
        "totalnum":22
        
    },
    "ig": {
        "feed": {
            "total_views": 2916892,
            "total_engagement": 195431,
            "total_engagement_rate": 0.067,
            "total":44,
        },
        "totalnum":48,
        "story": {
            "total_views": 42683,
            "total_engagement": 1366,
            "total_engagement_rate": 0.032,
            "total":3,
        },
    }
};

//type=1=>image 2=>vedio 3=>tk
const PostData = [
    {
        "id": 1045,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_01.png",
        "media_link": "https://www.instagram.com/p/C7EIqaNBS6_/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 2578,
        "reach": 1592,
        "views": 2191,
        "engagement": 106,
        "engagement_rate": 0.067,
        "likes": 95,
        "comments": 3,
        "shares": 5,
        "saved": 3,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 123,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "One of my favorite scents is currently Springtime in a Park through the Memory Box 🌸🤍 @maisonmargielafragrances @sephora #giftedbymaisonmargielafragrances",
        "thumbnail_url": "post_ig_02.png",
        "media_link": "https://www.instagram.com/p/C6g2Fchu3I7/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 17822,
        "reach": 14851,
        "views": 15148,
        "engagement": 711,
        "engagement_rate": 0.048,
        "likes": 594,
        "comments": 29,
        "shares": 74,
        "saved": 14,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1044,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_03.png",
        "media_link": "https://www.instagram.com/p/C6MYZU9OfKp/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 24647,
        "reach": 17605,
        "views": 20949,
        "engagement": 1227,
        "engagement_rate": 0.07,
        "likes": 1056,
        "comments": 70,
        "shares": 66,
        "saved": 35,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 888,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_04.png",
        "media_link": "https://www.instagram.com/p/C3_CgP0vAop/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 3986,
        "reach": 2847,
        "views": 3388,
        "engagement": 139,
        "engagement_rate": 0.049,
        "likes": 113,
        "comments": 11,
        "shares": 10,
        "saved": 5,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 10455,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_05.png",
        "media_link": "https://www.instagram.com/p/CuPH27dOwoQ/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 16047,
        "reach": 12343,
        "views": 13639,
        "engagement": 849,
        "engagement_rate": 0.069,
        "likes": 740,
        "comments": 24,
        "shares": 61,
        "saved": 24,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [
            {
                "id": 3,
                "text": "promoted"
            },
        ],
        "mentions": [
            {
                "id": 2,
                "text": "jbhome"
            }
        ],
        "tags": [
            {
                "id": 2,
                "text": "Organic"
            }
        ],
        "is_brand_post":true,
    },
    {
        "id": 15,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_06.png",
        "media_link": "https://www.instagram.com/p/CuPDTvlOnUt/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 22490,
        "reach": 16064,
        "views": 19116,
        "engagement": 1047,
        "engagement_rate": 0.065,
        "likes": 963,
        "comments": 32,
        "shares": 20,
        "saved": 32,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 777,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_07.png",
        "media_link": "https://www.instagram.com/p/CuNsUhhttUG/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 5075,
        "reach": 4229,
        "views": 0,
        "engagement": 278,
        "engagement_rate": 0.066,
        "likes": 253,
        "comments": 16,
        "shares": 5,
        "saved": 4,
        "type": 1,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 10321,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_08.png",
        "media_link": "https://www.instagram.com/p/CpaoElJO2po/?img_index=1",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 13547,
        "reach": 11289,
        "views": 0,
        "engagement": 560,
        "engagement_rate": 0.05,
        "likes": 451,
        "comments": 45,
        "shares": 42,
        "saved": 22,
        "type": 1,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 401010,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_09.png",
        "media_link": "https://www.instagram.com/p/ClXF1v-PMRs/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 5257,
        "reach": 4380,
        "views": 0,
        "engagement": 212,
        "engagement_rate": 0.048,
        "likes": 175,
        "comments": 8,
        "shares": 21,
        "saved": 8,
        "type": 1,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    }
]

const ReelData =  [
    {
        "id": 1045,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_01.png",
        "media_link": "https://www.instagram.com/reel/C7EIqaNBS6_/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 2578,
        "reach": 1592,
        "views": 2191,
        "engagement": 106,
        "engagement_rate": 0.067,
        "likes": 95,
        "comments": 3,
        "shares": 5,
        "saved": 3,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 123,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "One of my favorite scents is currently Springtime in a Park through the Memory Box 🌸🤍 @maisonmargielafragrances @sephora #giftedbymaisonmargielafragrances",
        "thumbnail_url": "post_ig_02.png",
        "media_link": "https://www.instagram.com/reel/C6g2Fchu3I7/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 17822,
        "reach": 14851,
        "views": 15148,
        "engagement": 711,
        "engagement_rate": 0.048,
        "likes": 594,
        "comments": 29,
        "shares": 74,
        "saved": 14,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1044,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_03.png",
        "media_link": "https://www.instagram.com/reel/C6MYZU9OfKp/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 24647,
        "reach": 17605,
        "views": 20949,
        "engagement": 1227,
        "engagement_rate": 0.07,
        "likes": 1056,
        "comments": 70,
        "shares": 66,
        "saved": 35,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 888,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_04.png",
        "media_link": "https://www.instagram.com/reel/C3_CgP0vAop/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 3986,
        "reach": 2847,
        "views": 3388,
        "engagement": 139,
        "engagement_rate": 0.049,
        "likes": 113,
        "comments": 11,
        "shares": 10,
        "saved": 5,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 666666666,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_010.png",
        "media_link": "https://www.instagram.com/reel/Cu2w7loMigJ/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 7931,
        "reach": 6609,
        "views": 6741,
        "engagement": 330,
        "engagement_rate": 0.042,
        "likes": 264,
        "comments": 66,
        "shares": 39,
        "saved": 13,
        "type": 2,
        "is_post": false,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 10455,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_05.png",
        "media_link": "https://www.instagram.com/reel/CuPH27dOwoQ/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 16047,
        "reach": 12343,
        "views": 13639,
        "engagement": 849,
        "engagement_rate": 0.069,
        "likes": 740,
        "comments": 24,
        "shares": 61,
        "saved": 24,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [
        ],
        "mentions": [
            {
                "id": 2,
                "text": "jbhome"
            }
        ],
        "tags": [
            {
                "id": 3,
                "text": "Paid"
            },
        ],
        "is_brand_post":true,
    },
    {
        "id": 15,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_06.png",
        "media_link": "https://www.instagram.com/reel/CuPDTvlOnUt/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 22490,
        "reach": 16064,
        "views": 19116,
        "engagement": 1047,
        "engagement_rate": 0.065,
        "likes": 963,
        "comments": 32,
        "shares": 20,
        "saved": 32,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 987654321,
        "date": "2024-04-11",
        "social_platform": "instagram",
        "content": "",
        "thumbnail_url": "post_ig_11.png",
        "media_link": "https://www.instagram.com/reel/CjET_a8jmz6/",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 54083,
        "reach": 38630,
        "views": 45970,
        "engagement": 3662,
        "engagement_rate": 0.095,
        "likes": 3244,
        "comments": 108,
        "shares": 202,
        "saved": 108,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [
            {
                "id": 2,
                "text": "jbhome"
            },
        ],
        "tags": [
            {
                "id": 1,
                "text": "Sale"
            },
            {
                "id": 2,
                "text": "Organic"
            },
        ],
        "is_brand_post":true,
        "only_content":true,
    },
]

const TkData = [
    {
        "id": 1045,
        "date": "2024-04-11",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_01.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7370138241539869995",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 1183,
        "engagement": 71,
        "engagement_rate": 0.084,
        "likes": 67,
        "comments": 2,
        "shares": 2,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": [],
    },
    {
        "id": 1045,
        "date": "2024-04-09",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_02.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7369954068711738667",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 23183,
        "engagement": 1551,
        "engagement_rate": 0.087,
        "likes": 1426,
        "comments": 59,
        "shares": 66,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": [],
    },
    {
        "id": 1045,
        "date": "2024-04-07",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_03.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7364810121832058155",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 30962,
        "engagement": 2274,
        "engagement_rate": 0.103,
        "likes": 2211,
        "comments": 36,
        "shares": 27,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1046,
        "date": "2024-04-03",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_04.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7361865836807884078",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 12980,
        "engagement": 681,
        "engagement_rate": 0.068,
        "likes": 599,
        "comments": 33,
        "shares": 49,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [
            {
                "id": 1,
                "text": "jbseasonal"
            },
        ],
        "mentions": [],
        "tags": [
            {
                "id": 1,
                "text": "Sale"
            },
            {
                "id": 2,
                "text": "Organic"
            },
        ],
        "is_brand_post":true
    },
    {
        "id": 1047,
        "date": "2024-03-28",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_05.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7345336946496949547",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 20604,
        "engagement": 1486,
        "engagement_rate": 0.087,
        "likes": 1373,
        "comments": 28,
        "shares": 85,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1048,
        "date": "2024-03-27",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_06.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7341503302443617578",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 11324,
        "engagement": 632,
        "engagement_rate": 0.067,
        "likes": 566,
        "comments": 31,
        "shares": 35,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1049,
        "date": "2024-03-24",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_07.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7307364836671786282",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 27827,
        "engagement": 1755,
        "engagement_rate": 0.088,
        "likes": 1590,
        "comments": 66,
        "shares": 99,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [
            {
                "id": 2,
                "text": "sponsored"
            },
        ],
        "mentions": [
            {
                "id": 1,
                "text": "joannabuchanan"
            },
        ],
        "tags": [
            {
                "id": 3,
                "text": "Paid"
            },
        ],
        "is_brand_post":true
    },
    {
        "id": 1050,
        "date": "2024-03-21",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_08.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7292622235184155950",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 15890,
        "engagement": 1195,
        "engagement_rate": 0.105,
        "likes": 1135,
        "comments": 18,
        "shares": 42,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1051,
        "date": "2024-03-19",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_09.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7286662082366328107",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 15101,
        "engagement": 892,
        "engagement_rate": 0.083,
        "likes": 862,
        "comments": 17,
        "shares": 13,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 1052,
        "date": "2024-03-19",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_10.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7280288843062889771",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 20854,
        "engagement": 1815,
        "engagement_rate": 0.104,
        "likes": 1737,
        "comments": 57,
        "shares": 21,
        "saved": 0,
        "type": 2,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [],
        "mentions": [],
        "tags": []
    },
    {
        "id": 987654321,
        "date": "2024-04-11",
        "social_platform": "tikTok",
        "content": "",
        "thumbnail_url": "post_tk_11.jpg",
        "media_link": "https://www.tiktok.com/@hannageeun/video/7245710109568109870",
        "is_public": 1,
        "is_exist": 1,
        "impressions": 0,
        "reach": 0,
        "views": 54627,
        "engagement": 2818,
        "engagement_rate": 0.067,
        "likes": 2521,
        "comments": 140,
        "shares": 157,
        "saved": 0,
        "type": 3,
        "is_post": true,
        "first_name": "Andy",
        "last_name": "H",
        "hashtags": [
            {
                "id": 2,
                "text": "sponsored"
            },
        ],
        "mentions": [
            {
                "id": 1,
                "text": "joannabuchanan"
            },
        ],
        "tags": [
            {
                "id": 3,
                "text": "Paid"
            },
            {
                "id": 4,
                "text": "Seasonal"
            },
        ],
        "is_brand_post":true,
        "only_content":true,
    },

]

export { Creators, Products, Sample, Full, Users, PostStatistic,ContentPostStatistic, PostData, ReelData, TkData };